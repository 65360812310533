import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import $ from "jquery";
import EditIcon from '@mui/icons-material/Edit';
import useDrawer from "../../../../context/DrawerContext";
import BackButtonForProdctu from "./BackButtonForProdctu";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCommonFunction } from "../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import CommonDetailsPage from "./CommonDetailsPage";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { MenuPermissions, userPermissionType } from "../../../Comman/UserPermission";

window.jquery = window.$ = $;



export default function StockProductDetails() {
      const { productid, catagoryid, menuId, branchID } = useParams();

      // console.log("productid" ,productid)
      // console.log("catagoryid" ,catagoryid)
      // console.log("menuId" ,menuId)
      // // console.log("id" ,id)
      // console.log("branchID" ,branchID)
      const [data, setData] = useState([{ title: "", name: "", additional_products_id: "", product_list: [] }]);

      useEffect(() => {
            getDetails();
      }, [productid]);


      function getDetails() {
            if (productid)
                  api
                        .get(`/groupadmin/product/stock/${productid}`, { headers })
                        .then((res) => {
                              const tableData = res?.data;
                              // console.log(res?.data)
                              setData(tableData);
                        })
                        .catch((err) => {
                              if (err.response) {
                                    console.log(err?.response?.data);
                              }
                        });
      }

      let history = useHistory();

      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      function deleteStock(ID) {
            let item = {
                  stock_id: ID
            }

            // console.log("ID" ,ID ,productid)

            deleteCommonFunction(`/groupadmin/product/stock/${productid}`,
                  "Stock has been successfully deleted.",
                  () => getDetails(), item);
      }


      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1
                                                                  className="anchor fw-bolder mb-5"
                                                                  id="vertical-horizontal-scroll"
                                                            >
                                                                  Menu Management
                                                            </h1>
                                                            <hr />
                                                            <div className="d-flex flex-stack mb-5">
                                                                  <div
                                                                        className="d-flex justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h3
                                                                              className="anchor fw-bolder mb-5"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Product - Stock
                                                                        </h3>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div
                                                                              className="d-flex justify-content-end mx-2"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <BackButtonForProdctu
                                                                                    name={"Go Back"}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <div className="rounded border p-10">
                                                                  <CommonDetailsPage />
                                                                  <br />



                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                              <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                                                                                    Stock
                                                                              </h3>
                                                                        </div>
                                                                        <div className="d-flex flex-stack mb-5">
                                                                              <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                                                                                    {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("create")) && (
                                                                                          <button
                                                                                                onClick={() => history.push(`/group_admin/menu_management/menu_category/menu_products/product_details_add_stock/${productid}/${catagoryid}/${menuId}/${branchID}`)}
                                                                                                className="btn btn-primary ps-7"
                                                                                          >
                                                                                                Add Stock
                                                                                          </button>
                                                                                    )}
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {data?.length > 0 ? (
                                                                        data?.map((main, mainIndex) => (
                                                                              <div key={mainIndex} className="card card-docs mb-10">
                                                                                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                                                          <form  >
                                                                                                <div className="rounded border p-10">
                                                                                                      <div className="row mb-8">
                                                                                                            <div className="col-md-9">
                                                                                                                  <h3>{main.category_name}</h3>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 text-end">
                                                                                                                  {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("edit")) && (
                                                                                                                        <EditIcon style={{cursor : "pointer"}} className="me-3 fs-1" 

                                                                                                                        onClick={() => history.push(`/group_admin/menu_management/menu_category/menu_products/product_details_edit_stock/${productid}/${catagoryid}/${menuId}/${branchID}/${main?._id}`)} 
                                                                                                                        />
                                                                                                                  )}
                                                                                                                  {(userPermissionType === "groupadmin" || MenuPermissions?.access?.includes("delete")) && (
                                                                                                                        <DeleteIcon className="fs-1" style={{cursor : "pointer"}} 
                                                                                                                        onClick={() => deleteStock(main?._id)} />
                                                                                                                  )}

                                                                                                                 
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />
                                                                                                      <React.Fragment>
                                                                                                            <div className="row mt-8 mb-4">
                                                                                                                  <div className="col-md-3">
                                                                                                                        <p>Stock Details</p>
                                                                                                                  </div>
                                                                                                                  <div className="col-md-4">
                                                                                                                        <input
                                                                                                                              type="text"
                                                                                                                              name="title"
                                                                                                                              className="form-control form-control"
                                                                                                                              placeholder="Enter Title"
                                                                                                                              value={main.category_name}
                                                                                                                              disabled
                                                                                                                        />
                                                                                                                  </div>
                                                                                                                  <div className="col-md-4">

                                                                                                                  </div>
                                                                                                            </div>



                                                                                                            {main?.items?.length > 0 && 
                                                                                                                  main?.items.filter(ele => ele?.is_active).map((product, productIndex) => (
                                                                                                                        <div key={productIndex} className="row mt-8">
                                                                                                                              <div className="col-md-3"></div>
                                                                                                                              <div className="col-md-2 d-flex align-items-center">
                                                                                                                                    <input
                                                                                                                                          type="text"
                                                                                                                                          className="form-control"
                                                                                                                                          placeholder="Enter Name"
                                                                                                                                          value={product.name}
                                                                                                                                          disabled
                                                                                                                                    />
                                                                                                                              </div>
                                                                                                                              
                                                                                                                              <div className="col-md-2">
                                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                                          <input
                                                                                                                                                type="number"
                                                                                                                                                className="form-control form-control"
                                                                                                                                                placeholder="Enter Quantity"
                                                                                                                                                value={product.quantity}
                                                                                                                                                disabled
                                                                                                                                          />
                                                                                                                                          <span style={{ marginLeft: "-2.5rem", color: "#667085", fontSize: '14px', fontWeight: 400 }} className="">
                                                                                                                                                {product.unit === "number" ? "no" : product.unit}
                                                                                                                                          </span>
                                                                                                                                    </div>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  ))}
                                                                                                      </React.Fragment>

                                                                                                      <br />
                                                                                                </div>
                                                                                          </form>
                                                                                    </div>
                                                                              </div>
                                                                        ))
                                                                  ) : (
                                                                        <div className="card card-docs mb-10">
                                                                              <div className="card-body fs-6 pt-15 px-10 px-lg-15 text-gray-700">
                                                                                    <h5 className="text-center">No data available.</h5>
                                                                              </div></div>
                                                                  )}

                                                            </div>
                                                      </main>
                                                </div>

                                          </div>
                                    </div>
                                    <GroupAdminUperSide />
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}

