export const unitFullForms = {
      mcg: 'micrograms',
      mg: 'milligrams',
      g: 'grams',
      kg: 'kilograms',
      mt: 'metric tons',
      oz: 'ounces',
      lb: 'pounds',
      t: 'tons',
      ml: 'milliliters',
      l: 'liters',
      no: 'number'
  };
    