import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import Swal from "sweetalert2";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { tableOptionSubcription } from "../../NewTableCommon/tableOption";
import moment from "moment/moment";
import { getMenuList } from "../../../Store/actions/menu/menu-action";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "../../Comman/BranchList";
import { getBranchList } from "../../../Store";
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ColumPopoverCommon from "../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../NewTableCommon/tableFilter";
import useWindowHeight from "../../NewTableCommon/useWindowsPosition";
import "./menuManagmnet.css"
import renderStatusChip from "../../NewTableCommon/renderStatusChip";
import useDrawer from "../../../context/DrawerContext";
import { userPermissionType } from "../../Comman/UserPermission";
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./sub.css"
import { renderStatusChipSubcription } from "../../NewTableCommon/rendderStatucChipDaynamic";

function SubscriptionManagementNew({ getMenuList, menuList, getBranchList, BranchList_ }) {
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[2];

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
      const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

      const userPermissionType_ = userPermissionType === "groupadmin"

      // console.log("userPermissionType_", userPermissionType_)
      const storedDataSub = localStorage.getItem('DataSub');
      const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;

      // console.log("DataSub", DataSub)

      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const history = useHistory();
      const [BranchId, setBranchID] = useState(branchName || "");
      const [error, setError] = useState(null);
      const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover


      const [FilterData, SetFilterData] = useState("");

      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const [subcriptionData, setsubcriptionList] = useState([])


      useEffect(() => {
            subcriptionList();
      }, []);

      // console.log('Branch' ,Branch)

      function subcriptionList() {
            api.get(`/groupadmin/subscription/list`, { headers }).then((res) => {
                  const tableData = res.data.data;
                  //   console.log(tableData);
                  setsubcriptionList(tableData);
            });
      }

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };


      console.log("DataSub", DataSub);
      const filteredMenuList = useMemo(() => {
            const status = FilterData?.Status?.value;
            if (BranchId && BranchId.value !== "All" && status) {
                  return menuList.filter(menu => menu.branch_id === BranchId.value &&
                        menu.is_active === (status === "Active"));
            } else if (BranchId && BranchId.value !== "All") {
                  return menuList.filter(menu => menu.branch_id === BranchId.value);
            } else if (status) {
                  return menuList.filter(menu => menu.is_active === (status === "Active"));
            }
            return menuList;
      }, [menuList, BranchId, FilterData?.Status?.value]);

      useEffect(() => {
            getMenuList();
            getBranchList();
      }, []);

      const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
      };

      const handleCloseMoreOption = (event) => {
            setAnchorEl(null);
      };

      const [anchorEl_, setAnchorEl_] = useState(null);

      const handleClickColum_ = (event) => {
            setAnchorEl_(event.currentTarget);
      };
      const handleCloseColum_ = () => {
            setAnchorEl_(null);
      };

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };


      const [show, setShow] = useState(false);

      const handleClose = () => {
            setShow(false)
      };

      const handleShow = () => setShow(true);


      function handleSubmitActive(id, value) {

            let item = {
                  is_active: value,
            };

            api
                  .patch(`/groupadmin/menu/updateStatus/${id}`, item, { headers })
                  .then((res) => {
                        if (res.data.error) {
                        }
                        Swal.fire({
                              position: "top-center",
                              icon: "success",
                              title: `Menu status has been ${value ? "active" : "inactive"
                                    } succesfully.`,
                              showConfirmButton: false,
                              timer: 1500,
                        });
                        getMenuList();
                  })
                  .catch((err) => {
                        if (err.response) {
                              toast.error(err?.response?.data?.error?.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                              });
                        }
                  });
      }


      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'menu_name',
                        header: 'Sr.No.',
                        size: 300,
                        Cell: ({ row }) => row?.index + 1,
                  },
                  {
                        accessorKey: 'plan_name',
                        header: 'Plan',
                        size: 300,
                        Cell: ({ row }) => row?.original?.plan_name,
                  },
                  {
                        accessorKey: 'start_date',
                        header: 'Purchased Date',
                        size: 270,
                        Cell: ({ row }) => moment(row?.original?.start_date).format("DD-MM-YYYY"),
                  },
                  {
                        accessorKey: 'end_date',
                        header: 'End Date',
                        size: 270,
                        Cell: ({ row }) => moment(row?.original?.end_date).format("DD-MM-YYYY"),
                  },
                  {
                        accessorKey: 'status',
                        header: 'Status',
                        size: 270,
                        Cell: ({ row }) => renderStatusChipSubcription(row?.original?.status),
                  }
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const table = useMaterialReactTable({
            columns: Columns,
            data: subcriptionData,
            ...tableOptionSubcription({
                  getMenuList,
                  url23,
                  handleClickColum_,
                  handleSubmitActive,
                  handleFilterClick,
                  windowHeight,
                  filterData,
                  DisplayCol, handleShow
            }),
      });



      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


      const cardData = [
            {
                  title: "No of licenses",
                  value: DataSub?.number_of_branches || "--",
            },
            {
                  title: "No of used licenses",
                  value: DataSub?.branches || "--",
            },
            {
                  title: "Available licenses",
                  value: Math.abs(DataSub?.number_of_branches - DataSub?.branches) || "--",
            },
      ];


      // Define a mapping for status-based styles
      const statusStyles = {
            active: {
                  background: "#ECFDF3",
                  color: "#027A48",
                  label: "Active",
            },
            trial: {
                  background: "#EFF8FF",
                  color: "#175CD3",
                  label: "Trial",
            },
            pending: {
                  background: "#FFFAEB",
                  color: "#B54708",
                  label: "Pending",
            },
            expired: {
                  background: "#FEF3F2",
                  color: "#B42318",
                  label: "Expired",
            },
      };

      // Get the style based on the status
      const getStatusStyle = (status) => {
            switch (status) {
                  case "active":
                        return statusStyles.active;
                  case "trial":
                        return statusStyles.trial;
                  case "pending":
                        return statusStyles.pending;
                  case "expired":
                        return statusStyles.expired;
                  default:
                        return statusStyles.active; // Fallback to active style
            }
      };


      return (
            <React.Fragment>
                  <ToastContainer
                        toastStyle={{
                              border: "1px solid black",
                              fontSize: "17px",
                        }}
                  />
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                {/* <div style={{height : windowHeight}}  id="kt_content_container" className="container-xxl"> */}
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>

                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Subscription
                                                                              </h1>
                                                                              {/* <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" /> */}

                                                                              {/* <Select
                                                                                    className="search-line mx-3"
                                                                                    value={BranchId}
                                                                                    placeholder={getPlaceholder(filteredOptions)}
                                                                                    options={getSelectOptions(filteredOptions)}
                                                                                    onChange={(opt) => {
                                                                                          setBranchID(opt);
                                                                                          sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                                                                                    }}
                                                                                    styles={selectStyles}
                                                                              /> */}



                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                  </div>
                                                            </div>

                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />

                                                            {DataSub ?
                                                                  <>
                                                                        <div className="row g-xl-12 mt-6">
                                                                              <div className="col-xl-4">
                                                                                    <div className="col-xl-12">
                                                                                          <a className="card hoverable card-xl-stretch mb-xl-8">
                                                                                                <div className="frame8__">
                                                                                                      <div className="current">Current Plan</div>
                                                                                                      <div className="plan-details">
                                                                                                            <div className="plan-year">
                                                                                                                  <h4 className="year">{DataSub?.plan_name}</h4>
                                                                                                            </div>
                                                                                                            <div className="plan-status">
                                                                                                                  {DataSub &&
                                                                                                                        <Chip
                                                                                                                        // icon={<FiberManualRecordIcon style={{ color: getStatusStyle(DataSub?.status)?.color, fontSize: 12 }} />}
                                                                                                                              style={{
                                                                                                                                    background: getStatusStyle(DataSub?.status)?.background,
                                                                                                                                    color: getStatusStyle(DataSub?.status)?.color,
                                                                                                                                    border: "0px",
                                                                                                                                    height: "26px",
                                                                                                                                    fontSize: "12px",
                                                                                                                                    fontWeight: 500,
                                                                                                                                    // width: "62px",
                                                                                                                              }}
                                                                                                                              label={getStatusStyle(DataSub?.status)?.label}
                                                                                                                              variant="outlined"
                                                                                                                        />
                                                                                                                  }
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      {DataSub &&
                                                                                                            <div className="renew">Renews on {moment(DataSub?.end_date).format("DD/MM/YYYY")}</div>
                                                                                                      }
                                                                                                </div>
                                                                                          </a>

                                                                                    </div>
                                                                              </div>

                                                                              <div className="row col-xl-8">
                                                                                    {cardData?.map((card, index) => (
                                                                                          <div key={index} className="col-xl-4">
                                                                                                <a className="card hoverable card-xl-stretch mb-xl-8">
                                                                                                      <div className="frame8__">
                                                                                                            <div className="overlap-group">
                                                                                                                  <div className="text-wrapper">{card.title}</div>
                                                                                                            </div>
                                                                                                            <p style={{ lineHeight: "63px" }} className="element-CHF">
                                                                                                                  <span className="span">{card.value}</span>
                                                                                                            </p>
                                                                                                            {/* <div className="text-wrapper-3">{card.unit}</div> */}
                                                                                                      </div>
                                                                                                </a>
                                                                                          </div>
                                                                                    ))}
                                                                              </div>
                                                                        </div>

                                                                        <div className="mt-5">
                                                                              <MaterialReactTable
                                                                                    table={table}
                                                                              /></div>
                                                                  </>
                                                                  : <div className="card">
                                                                        <div className="card-body p-2">
                                                                              <center>
                                                                                    {/* <div className="my-4">
                                                                                          <i className="fa-light fa-badge-percent text-dark fs-4x" />
                                                                                    </div> */}
                                                                                    <div className="my-8">
                                                                                          <h2 className="fw-normal">Your plan has expired. To continue accessing the service, please renew your plan.</h2>
                                                                                    </div>
                                                                              </center>
                                                                        </div>

                                                                  </div>}
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>

                  <ColumPopoverCommon
                        anchorEl_={anchorEl_}
                        handleClickColum_={handleClickColum_}
                        onColumnDataChange={onColumnDataChange}
                        handleCloseColum_={handleCloseColum_}
                        url23={url23}
                  />

                  <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                  />

                  <Modal dialogClassName="modal-30w"
                        aria-labelledby="example-custom-modal-styling-title"
                        show={show} centered onHide={handleClose}>
                        <Modal.Body>
                              <Modal.Title className="mb-2 heading">
                                    Cancel Subscription
                              </Modal.Title>
                              <form
                              // onSubmit={handleSubmitProduct}
                              >
                                    <div className="mb-5 mt-2">
                                          <div className="discriptionline">
                                                Are you sure you want to cancel this subscription? This action cannot be undone.
                                          </div>
                                    </div>

                                    <div className="mb-5">
                                          <div style={{ color: "rgb(217, 45, 32)" }} className="discriptionline">
                                                Please note that canceling your subscription will only affect your upcoming renewal.                                          </div>
                                    </div>
                                    <div className="col-md-12"> {error && <div className="text-center alert alert-danger">{error}</div>} </div>
                                    <div className="row flex-center d-flex">
                                          <div className="col-md-6">
                                                <button
                                                      type="button"
                                                      onClick={handleClose} className="btn btn-danger w-100">
                                                      Close
                                                </button>
                                          </div>
                                          <div className="col-md-6">
                                                <button
                                                      type="submit"
                                                      className="btn btn-primary  w-100"
                                                >
                                                      Cancel Subscription
                                                </button>
                                          </div>
                                    </div>

                              </form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      menuList: state.menuList,
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getMenuList: () => dispatch(getMenuList()),
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionManagementNew);

