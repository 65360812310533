import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./app.css"
import packageJson from '../package.json'; // Adjust the path based on your structure

/////////// Branch Admin Component ///////////////
import GroupAdminLogin from "./GroupAdmin/Auth/Login";
import GroupAdminBranchManagement from "./GroupAdmin/User/Component/BranchManagement";
import GroupAdminCMSManagement from "./GroupAdmin/User/Component/CMSManagement";
import GroupAdminCouponManagement from "./GroupAdmin/User/Component/CouponManagement";
import GroupAdminCustomManagement from "./GroupAdmin/User/Component/CustomManagement";
import GroupAdminMenuManagement from "./GroupAdmin/User/Component/MenuManagement";
import GroupAdminOrderManagement from "./GroupAdmin/User/Component/OrderManagement/OrderManagement";
import GroupAdminProfile from "./GroupAdmin/Profile/Profile";
import GroupAdminSettings from "./GroupAdmin/User/Component/Setting/Settings";
import GroupAdminPaymentProvider from "./GroupAdmin/User/Component/Setting/PaymentProvider";
import GroupAdminPaymentProviderForm from "./GroupAdmin/User/Component/Setting/PaymentProviderForm";
import GroupAdminBranchDetailView from "./GroupAdmin/User/Page/BranchDetailView";
import GroupAdminOrderDetailView from "./GroupAdmin/User/Page/MenuManagment/OrderDetailView";
import GroupAdminAddManager from "./GroupAdmin/User/Page/AddManager";
import GroupAdminAddStaff from "./GroupAdmin/User/Page/AddStaff";
import GroupAdminAddTable from "./GroupAdmin/User/Page/AddTable";
import GroupAdminAddBranch from "./GroupAdmin/User/Page/AddBranch";
import GroupAdminEditBranchDetails from "./GroupAdmin/User/Page/EditBranchDetails";
import GroupAdminEditManager from "./GroupAdmin/User/Page/EditManager";
import GroupAdminEditStaff from "./GroupAdmin/User/Page/EditStaff";
import GroupAdminEditTermsAndCondition from "./GroupAdmin/User/Page/CMSManagement/EditTermsAndConditions";
import GroupAdminEditPrivacyPolicy from "./GroupAdmin/User/Page/CMSManagement/EditPrivacyPolicy";
import GroupAdminEditAboutUs from "./GroupAdmin/User/Page/CMSManagement/EditAboutUs";
import GroupAdminEnquriy from "./GroupAdmin/User/Component/Enquiry/Enquiry";
import GroupAdminCompletedEnquiryList from "./GroupAdmin/User/Component/Enquiry/CompletedEnquiryList";
import GroupAdminPendingEnquiryList from "./GroupAdmin/User/Component/Enquiry/PendingEnquiryList";
import GroupAdminCompletedEnquiryDetails from "./GroupAdmin/User/Page/Enquiry/CompletedEnquiryDetails";
import GroupAdminPendingEnquiryDetails from "./GroupAdmin/User/Page/Enquiry/PendingEnquiryDetails";

///////////////////////////// Coupan ////////////////////////////
import GroupAdminAddCoupon from "./GroupAdmin/User/Page/Coupon/AddCoupon";
import GroupAdminEditCoupon from "./GroupAdmin/User/Page/Coupon/EditCoupon";
import GroupAdminViewCoupon from "./GroupAdmin/User/Page/Coupon/ViewCoupon";

////////////////////////////// Voucher Managemnet ///////////////////
import GroupAdminVoucherListManagement from "./GroupAdmin/User/Page/VoucherManagement/VoucherListManagement";
import GroupAdminAddVoucher from "./GroupAdmin/User/Page/VoucherManagement/AddVoucher";
import GroupAdminPurchesListVoucher from "./GroupAdmin/User/Page/VoucherManagement/PurchersListVoucher";

///////////////////// Subscription Managment /////////////////////////////
import GroupAdminSubscriptionManagement from "./GroupAdmin/User/Component/SubscriptionManagement";

/////////////////// Setting /////////////////////////
import GroupAdminDelivery from "./GroupAdmin/User/Component/Setting/Delivery/Delivery";
import GroupAdminDeliveryPreparationTime from "./GroupAdmin/User/Component/Setting/Delivery/PreparationTime";
import GroupAdminDeliverySetting from "./GroupAdmin/User/Component/Setting/Delivery/Setting";
import GroupAdminDeliveryZones from "./GroupAdmin/User/Component/Setting/Delivery/Zones";
import GroupAdminDeliveryAdditionalCharges from "./GroupAdmin/User/Component/Setting/Delivery/AdditionalCharges";
import GroupAdminDeliveryZonesForm from "./GroupAdmin/User/Component/Setting/Delivery/ZoneForm";
import GroupAdminEditAdditionCharges from "./GroupAdmin/User/Component/Setting/Delivery/Page/EditAdditionalCharges";
import GroupAdminEditAdditionChargesHeading from "./GroupAdmin/User/Component/Setting/Delivery/Page/EditHeading";
import GroupAdminAddAdditionCharges from "./GroupAdmin/User/Component/Setting/Delivery/Page/AddAdditionalCharges";
import GroupAdminVatCharges from "./GroupAdmin/User/Component/Setting/VatCharges/VatCharges";

////////////////////////  Dinein  ///////////////////////
import GroupAdminDinein from "./GroupAdmin/User/Component/Setting/Dinein/Dinein";
import GroupAdminDineinAdditionalCharges from "./GroupAdmin/User/Component/Setting/Dinein/AdditionalCharges";
import GroupAdminDineinSetting from "./GroupAdmin/User/Component/Setting/Dinein/Setting";
import GroupAdminAddAdditionChargesDineIn from "./GroupAdmin/User/Component/Setting/Dinein/Page/AddAdditionalCharges";
import GroupAdminEditAdditionChargesDineIn from "./GroupAdmin/User/Component/Setting/Dinein/Page/EditAdditionalCharges";
import GroupAdminEditAdditionChargesHeadingDineIn from "./GroupAdmin/User/Component/Setting/Dinein/Page/EditHeading";

////////////////////////  Pickup ///////////////////////
// import GroupAdminPickup from './GroupAdmin/User/Component/Setting/Pickup';
import GroupAdminPickup from "./GroupAdmin/User/Component/Setting/Pickup/Pickup";
import GroupAdminPickupPreparationTime from "./GroupAdmin/User/Component/Setting/Pickup/PreparationTime";
import GroupAdminPickupAdditionalCharges from "./GroupAdmin/User/Component/Setting/Pickup/AdditionalCharges";
import GroupAdminPickupSetting from "./GroupAdmin/User/Component/Setting/Pickup/Setting";
import GroupAdminAddAdditionChargesPickup from "./GroupAdmin/User/Component/Setting/Pickup/Page/AddAdditionalChargesPickup";
import GroupAdminEditAdditionChargesHeadingPickup from "./GroupAdmin/User/Component/Setting/Pickup/Page/EditHeadPickup";
import GroupAdminEditAdditionChargesPickup from "./GroupAdmin/User/Component/Setting/Pickup/Page/EditAdditionalChargesPickup";

///////////////////////////// Pos ////////////////////////////
import GroupAdminPOS from "./GroupAdmin/User/Component/Setting/Pos/Pos";
import GroupAdminPosSettings from "./GroupAdmin/User/Component/Setting/Pos/PosSettingsAuth";
import GroupAdminPosSettingsBusinessSetup from "./GroupAdmin/User/Component/Setting/Pos/PosSettingBusinessSetup";
import GroupAdminPOS_Lightspeed from "./GroupAdmin/User/Component/Setting/POS_Lightspeed";

// ///////////   Menu Management Component ////////////////////
import GroupAdminMenuInformation from "./GroupAdmin/User/Page/MenuManagment/GoldenIndiaLausanne/MenuInformation";
import GroupAdminMenuCategory from "./GroupAdmin/User/Page/MenuManagment/GoldenIndiaLausanne/MenuCategory";
import GroupAdminAddNewMenu from "./GroupAdmin/User/Page/MenuManagment/AddNewMenu";
import GroupAdminMenuCategoryDetailInformation from "./GroupAdmin/User/Page/MenuManagment/MenuCategoryDetailInformation";
import GroupAdminMenuProducts from "./GroupAdmin/User/Page/MenuManagment/MenuCategoryDetailProducts";
import GroupAdminAddNewProduct from "./GroupAdmin/User/Page/MenuManagment/AddNewProduct";
import GroupAdminProductDetailsDeclaration from "./GroupAdmin/User/Page/MenuManagment/ProductDetailsDeclaration";
import GroupAdminProductDetailsDiscriptaion from "./GroupAdmin/User/Page/MenuManagment/ProductDetailsDiscription";
import GroupAdminProductDetailsInformation from "./GroupAdmin/User/Page/MenuManagment/ProductDetailsInformation";
import GroupAdminProductDetailsTimeFilter from "./GroupAdmin/User/Page/MenuManagment/ProductDetaisTimeFilter";
import GroupAdminMenuCategoryEditInformation from "./GroupAdmin/User/Page/MenuManagment/GoldenIndiaLausanne/Catagory/EditCatagory";
import GroupAdminCategoryByProduct from "./GroupAdmin/User/Page/MenuManagment/CatagoryByProduct";
import GroupAdminMenuCategoryEditProduct from "./GroupAdmin/User/Page/MenuManagment/Product/EditProduct";
import GroupAdminTimeFilter from "./GroupAdmin/User/Page/MenuManagment/TimeFilter";
import GroupAdminDiscriptaion from "./GroupAdmin/User/Page/MenuManagment/Discriptaion";
import { store } from "./Store";
import { Provider } from "react-redux";
import GroupAdminAddOn from "./GroupAdmin/User/Page/MenuManagment/AddOn";
import GroupAdminProductDetailsAddOn from "./GroupAdmin/User/Page/MenuManagment/ProductDetailsAddOn";
import GroupAdminCoupon_Voucher from "./GroupAdmin/User/Component/Coupon-Voucher/Coupon_Voucher";
import GroupAdminCustomProperties from "./GroupAdmin/User/Component/Setting/CustomProperties/CustomProperties";
import ForgatePassword from "./GroupAdmin/Auth/ForgotPassword";
import PosCodeGenratPage from "./GroupAdmin/User/Component/Setting/Pos/PosCodeGenratPage";
import PosG_SeriesAuth from "./GroupAdmin/User/Component/Setting/Pos/PosG_SeriesAuth";
import GroupAdminGroupSetup from "./GroupAdmin/User/Component/Setting/GroupSetup/GroupSetup";
import GroupAdminGroupReport from "./GroupAdmin/User/Component/Setting/Report/GroupReport";
import GroupAdminReportListpage from "./GroupAdmin/User/Component/Setting/Report/ReportListpage";
import GroupAdminTableManagement from "./GroupAdmin/User/Component/TabelManagement";
import TableManagementInBranchView from "./GroupAdmin/User/Component/TableManagementInBranchView/TableManagementInBranchView";
import GroupAdminVoucherSetting from "./GroupAdmin/User/Page/VoucherManagement/VoucherSetting";
import GroupAdminEditVoucher from "./GroupAdmin/User/Page/VoucherManagement/EditVoucher";
import GroupAdminRevenueBasedReports from "./GroupAdmin/User/Component/Setting/Report/RevenueBasedReports";
import GroupAdminOrderProductWiseReports from "./GroupAdmin/User/Component/Setting/Report/ProductWiseReports";
import GroupAdminOrderReports from "./GroupAdmin/User/Component/Setting/Report/OrderReports";
import GroupAdminBills from "./GroupAdmin/User/Component/Setting/Report/Bills";
import GroupAdminGenerateFromPDF from "./GroupAdmin/User/Page/MenuManagment/GenerateFromPDF";
import KpGpt from "./GroupAdmin/User/Component/KpGpt/KpGpt";
import GroupAdminUserList from "./GroupAdmin/User/Component/UserList/UserList";
import GroupAdminPurchersVoucherDetails from "./GroupAdmin/User/Page/VoucherManagement/PurchersVoucherDetails";
import GroupAdminVoucherReport from "./GroupAdmin/User/Component/Setting/Report/VoucherReport";
import GroupAdminPrinterList from "./GroupAdmin/User/Component/Setting/Printer/PrinterList";
import GroupAdminPrinterCatagory from "./GroupAdmin/User/Component/Setting/Printer/PrinterCatagory";
import GroupAdminPrinterSettings from "./GroupAdmin/User/Component/Setting/Printer/PrinterSettings";
import GroupAdminPrinterViewDetails from "./GroupAdmin/User/Component/Setting/Printer/PrinterViewDetails";
import GroupAdminPrinterEdit from "./GroupAdmin/User/Component/Setting/Printer/PrinterEdit";
import GroupAdminAddWaiter from "./GroupAdmin/User/Page/AddWaiter";
import GroupAdminEditWaiter from "./GroupAdmin/User/Page/EditWaiter";
import GroupAdminDiscountAndTips from "./GroupAdmin/User/Component/DiscountAndTips/DiscountAndTips";
import GroupAdminDiscountManagement from "./GroupAdmin/User/Page/DiscountAndTips/DiscountManagement";
import GroupAdminReasonsManagement from "./GroupAdmin/User/Page/DiscountAndTips/ReasonsManagement";

/////////////////// Tour Setup Setting /////////////////////////
import GroupAdminTourList from "./GroupAdmin/User/Page/Tour/TourList";
import GroupAdminTourAdd from "./GroupAdmin/User/Page/Tour/TourAdd";
import GroupAdminTourEdit from "./GroupAdmin/User/Page/Tour/TourEdit";
import GroupAdminTourClone from "./GroupAdmin/User/Page/Tour/TourClone";
import GroupAdminTourDetails from "./GroupAdmin/User/Page/Tour/TourDetails";
import GroupAdminTourInvoiceGenerate from "./GroupAdmin/User/Page/Tour/TourInvoiceGenerate";
import GroupAdminTourSetupCompanyList from "./GroupAdmin/User/Component/Setting/TourSetup/Companies/CompanyList";
import GroupAdminTourSetupCompanyAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Companies/CompanyAdd";
import GroupAdminTourSetupCompanyEdit from "./GroupAdmin/User/Component/Setting/TourSetup/Companies/CompanyEdit";
import GroupAdminTourSetupClientList from "./GroupAdmin/User/Component/Setting/TourSetup/Clients/ClientList";
import GroupAdminTourSetupClientAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Clients/ClientAdd";
import GroupAdminTourSetupClientEdit from "./GroupAdmin/User/Component/Setting/TourSetup/Clients/ClientEdit";
import GroupAdminTourSetupMealList from "./GroupAdmin/User/Component/Setting/TourSetup/Meal/MealList";
import GroupAdminTourSetupMealAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Meal/MealAdd";
import GroupAdminTourSetupLocationList from "./GroupAdmin/User/Component/Setting/TourSetup/Location/LocationList";
import GroupAdminTourSetupLocationAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Location/LocationAdd";
import GroupAdminTourSetupStatusList from "./GroupAdmin/User/Component/Setting/TourSetup/Status/StatusList";
import GroupAdminTourSetupStatusAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Status/StatusAdd";
import GroupAdminTourSetupPaymentStatusList from "./GroupAdmin/User/Component/Setting/TourSetup/PaymentStatus/PaymentStatusList";
import GroupAdminTourSetupPaymentStatusAdd from "./GroupAdmin/User/Component/Setting/TourSetup/PaymentStatus/PaymentStatusAdd";
import GroupAdminTourSetupCurrencyList from "./GroupAdmin/User/Component/Setting/TourSetup/Currency/CurrencyList";
import GroupAdminTourSetupCurrencyAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Currency/CurrencyAdd";
import GroupAdminTourSetupVatList from "./GroupAdmin/User/Component/Setting/TourSetup/Vat/VatList";
import GroupAdminTourSetupVatAdd from "./GroupAdmin/User/Component/Setting/TourSetup/Vat/VatAdd";
import GroupAdminTourSetupInvoiceSetup from "./GroupAdmin/User/Component/Setting/TourSetup/InvoiceSetup/InvoiceSetup";

/////////////////// Setting /////////////////////////

import GroupAdminSettingHeader from "./GroupAdmin/User/Component/Setting/Header&Footer/Header";
import GroupAdminSettingFooter from "./GroupAdmin/User/Component/Setting/Header&Footer/Footer";
import GroupAdminSettingColor from "./GroupAdmin/User/Component/Setting/Header&Footer/Color";
import GroupAdminSettingAddHeader from "./GroupAdmin/User/Component/Setting/Header&Footer/AddHeader";
import GroupAdminLanguage from "./GroupAdmin/User/Component/Setting/Language/Language";
import GroupAdminTourGroupReport from "./GroupAdmin/User/Component/Setting/Report/TourGroupReport";

import GroupAdminUserManagment from "./GroupAdmin/User/Component/Setting/UserManagment/UserManagment";
import GroupAdminAddUserManagment from "./GroupAdmin/User/Page/UserManagment/AddUserManagment";
import GroupAdminUserRoleManagment from "./GroupAdmin/User/Component/Setting/UserManagment/UserRoleManagment";
import GroupAdminEditUserManagmnet from "./GroupAdmin/User/Page/UserManagment/EditUserManagmnet";
import GroupAdminViewUserManagment from "./GroupAdmin/User/Page/UserManagment/ViewUserManagment";

import OrderManagementNew from "./GroupAdmin/User/Component/OrderManagement/OrderManagementNew";
import GroupAdminOrderDetailsViewDinein from "./GroupAdmin/User/Page/MenuManagment/OrderDetailsViewDinein";
import GroupAdminMenuManagmentNewUi from "./GroupAdmin/User/Component/MenuManagmentNewUi";

import GroupAdminEmailMarketing from "./GroupAdmin/User/Component/Email-Marketing/EmailMarketion"
import DashboardNew2 from "./GroupAdmin/Dashboard/DashboardNew/DashboardNew2";
import Routes from "./Routes/Routes";
import AddonProperties from "./GroupAdmin/User/Component/Setting/AddonProperties/AddonProperties";
import AddonAddProperties from "./GroupAdmin/User/Component/Setting/AddonProperties/AddonAddProperties";
import AddOnPropertyDaynamic from "./GroupAdmin/User/Page/MenuManagment/AddOnPropertyDaynamic";
import Additionaloptions from "./GroupAdmin/User/Page/MenuManagment/Additionaloptions";
import Additionaloptionsdetails from "./GroupAdmin/User/Page/MenuManagment/Additionaloptionsdetails";
import PopularChoices from "./GroupAdmin/User/Page/MenuManagment/GoldenIndiaLausanne/PopularChoices";
import AddPopularChoices from "./GroupAdmin/User/Page/MenuManagment/GoldenIndiaLausanne/AddPopularChoices";
import AddExtrasProductLevel from "./GroupAdmin/User/Page/MenuManagment/AddExtrasProductLevel";
import AddRoles from "./GroupAdmin/User/Page/UserManagment/AddRoles";
import GroupAdminViewRoleManagmnet from "./GroupAdmin/User/Page/UserManagment/ViewRoleManagmnet";
import EditRoleManagment from "./GroupAdmin/User/Page/UserManagment/EditRoleManagment";
import InventoryProductList from "./GroupAdmin/User/Component/Setting/Inventory/InventoryProduct/InventoryProductList";
import InventoryCategoryList from "./GroupAdmin/User/Component/Setting/Inventory/InventoryCategory/InventoryCategoryList";
import StockManagement from "./GroupAdmin/User/Component/StockManagement/StockManagement";
import AddStock from "./GroupAdmin/User/Page/StockManagement/AddStock";
import StockProductDetails from "./GroupAdmin/User/Page/MenuManagment/StockProductDetails";
import AddStockProduct from "./GroupAdmin/User/Page/MenuManagment/AddStockProduct";
import StockProduct from "./GroupAdmin/User/Page/MenuManagment/StockProduct";
import StockProductDetailsEdit from "./GroupAdmin/User/Page/MenuManagment/StockProductDetailsEdit";
import ViewStockManagement from "./GroupAdmin/User/Page/StockManagement/ViewStockManagement";
import DevToolsDetector from "./GroupAdmin/Comman/AddCommonFun/DevToolsDetector/DevToolsDetector";
import SubscriptionManagementNew from "./GroupAdmin/User/Component/SubscriptionManagementNew";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const routes = [
  { path: "/", component: GroupAdminLogin, type: "PublicRoute", exact: true },
  { path: "/reset_password/:id", component: ForgatePassword, type: "PublicRoute", exact: true },
  { path: "/posauth", component: PosCodeGenratPage, type: "PublicRoute", exact: true },
  { path: "/group_admin/dashboard", component: DashboardNew2, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management", component: GroupAdminBranchManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/add_branch", component: GroupAdminAddBranch, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/edit_branch_details/:id", component: GroupAdminEditBranchDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/branch_detail_view/:id", component: GroupAdminBranchDetailView, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/table_management/:id", component: TableManagementInBranchView, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/user_list/:id", component: GroupAdminUserList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/add_servicestaff/:id", component: GroupAdminAddWaiter, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/edit_servicestaff_details/:id", component: GroupAdminEditWaiter, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/add_manager/:id", component: GroupAdminAddManager, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/add_staff/:id", component: GroupAdminAddStaff, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/add_table/:id/:numberOfTable/:pesentTable", component: GroupAdminAddTable, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/edit_manager_details/:id", component: GroupAdminEditManager, type: "PrivateRoute", exact: true },
  { path: "/group_admin/branch_management/edit_staff_details/:id", component: GroupAdminEditStaff, type: "PrivateRoute", exact: true },
  { path: "/group_admin/table_management", component: GroupAdminTableManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/cms_management", component: GroupAdminCMSManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/coupon_management", component: GroupAdminCouponManagement, type: "PrivateRoute", exact: true },
  // { path: "/group_admin/subscription_management", component: GroupAdminSubscriptionManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/custom_management", component: GroupAdminCustomManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/old", component: GroupAdminMenuManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management", component: GroupAdminMenuManagmentNewUi, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/generate_from_csv", component: GroupAdminGenerateFromPDF, type: "PrivateRoute", exact: true },
  { path: "/group_admin/order_management/old", component: GroupAdminOrderManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/order_management", component: OrderManagementNew, type: "PrivateRoute", exact: true },
  { path: "/group_admin/order_management/order_detail_view/:id", component: GroupAdminOrderDetailView, type: "PrivateRoute", exact: true },
  { path: "/group_admin/order_management/dinein_order_detail_view/:id", component: GroupAdminOrderDetailsViewDinein, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings", component: GroupAdminSettings, type: "PrivateRoute", exact: true },
  { path: "/group_admin/profile", component: GroupAdminProfile, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/payment_provider", component: GroupAdminPaymentProvider, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/add_payment_provider/:id/:branchid/:name", component: GroupAdminPaymentProviderForm, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/language", component: GroupAdminLanguage, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/user_management", component: GroupAdminUserManagment, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/user_management/add_user", component: GroupAdminAddUserManagment, type: "PrivateRoute", exact: true },
  
  { path: "/group_admin/settings/roles/add_roles", component: AddRoles, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/roles/edit_roles/:id", component: EditRoleManagment, type: "PrivateRoute", exact: true },

  { path: "/group_admin/settings/user_management/edit_user/:id", component: GroupAdminEditUserManagmnet, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/user_management/view_user/:id", component: GroupAdminViewUserManagment, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/roles/view_role/:id", component: GroupAdminViewRoleManagmnet, type: "PrivateRoute", exact: true },


  { path: "/group_admin/settings/inventory_management/productlist", component: InventoryProductList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/inventory_management/categorylist", component: InventoryCategoryList, type: "PrivateRoute", exact: true },



  
  { path: "/group_admin/settings/roles", component: GroupAdminUserRoleManagment, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pos", component: GroupAdminPOS, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/K_Series/pos/pos_settings/auth", component: GroupAdminPosSettings, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/G_Series/pos/pos_settings/auth", component: PosG_SeriesAuth, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/K_Series/pos/pos_settings/business_setup", component: GroupAdminPosSettingsBusinessSetup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/pos_lightspeed", component: GroupAdminPOS_Lightspeed, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/restaurant_time_management", component: GroupAdminDelivery, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/delivery_preparation_time", component: GroupAdminDeliveryPreparationTime, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/delivery_setting", component: GroupAdminDeliverySetting, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/delivery_zones", component: GroupAdminDeliveryZones, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/delivery_zones_form", component: GroupAdminDeliveryZonesForm, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/edit_additional_charges/:id", component: GroupAdminEditAdditionCharges, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/edit_additional_charges_heading/:id", component: GroupAdminEditAdditionChargesHeading, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/add_additional_charges/:id", component: GroupAdminAddAdditionCharges, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/delivery/delivery_additional_charges", component: GroupAdminDeliveryAdditionalCharges, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/edit_additional_charges_pickup/:id", component: GroupAdminEditAdditionChargesPickup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/edit_additional_charges_heading/:id", component: GroupAdminEditAdditionChargesHeadingPickup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/add_additional_charges_pickup/:id", component: GroupAdminAddAdditionChargesPickup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/restaurant_time_management", component: GroupAdminPickup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/pickup_preparation_time", component: GroupAdminPickupPreparationTime, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/pickup_additional_charges", component: GroupAdminPickupAdditionalCharges, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/pickup/pickup_setting", component: GroupAdminPickupSetting, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/dinein/restaurant_time_management", component: GroupAdminDinein, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/dinein/dinein_additional_charges", component: GroupAdminDineinAdditionalCharges, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/dinein/Dinein_setting", component: GroupAdminDineinSetting, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/dinein/add_additional_charges/:id", component: GroupAdminAddAdditionChargesDineIn, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/dinein/edit_additional_charges_dinein/:id", component: GroupAdminEditAdditionChargesDineIn, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/dinein/edit_additional_charges_heading/:id", component: GroupAdminEditAdditionChargesHeadingDineIn, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/coupon_management/add_coupon", component: GroupAdminAddCoupon, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/coupon_management/edit_coupon/:id", component: GroupAdminEditCoupon, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/coupon_management/view_coupon/:id", component: GroupAdminViewCoupon, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_information", component: GroupAdminMenuInformation, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category", component: GroupAdminMenuCategory, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/add_new_menu", component: GroupAdminAddNewMenu, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/category/add_new_menu_category", component: GroupAdminMenuCategoryDetailInformation, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/category/edit_menu_category/:id/:menuId/:branchID", component: GroupAdminMenuCategoryEditInformation, type: "PrivateRoute", exact: true },
  
  { path: "/group_admin/menu_management/menu_category/menu_products/:id/:menuId/:branchID", component: GroupAdminCategoryByProduct, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/category/additional-category/:id/:menuId/:branchID", component: PopularChoices, type: "PrivateRoute", exact: true },

  { path: "/group_admin/menu_management/category/additional-category/add/:id/:menuId/:branchID", component: AddPopularChoices, type: "PrivateRoute", exact: true },

  { path: "/group_admin/menu_management/menu_products", component: GroupAdminMenuProducts, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_products/options", component: GroupAdminAddOn, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_products/additional_products", component: Additionaloptions, type: "PrivateRoute", exact: true },

 
  { path: "/group_admin/menu_management/menu_category/menu_products/options/:productID", component: AddOnPropertyDaynamic, type: "PrivateRoute", exact: true },
  
  
  { path: "/group_admin/menu_management/product/add_new_product", component: GroupAdminAddNewProduct, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/product/edit_menu_product/:id", component: GroupAdminMenuCategoryEditProduct, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_products/product_details_time_filter", component: GroupAdminTimeFilter, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_products/product_details_discriptaion", component: GroupAdminDiscriptaion, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_products/stock", component: StockProduct, type: "PrivateRoute", exact: true },

  // { path: "/group_admin/menu_management/menu_products/recipe", component: StockProduct, type: "PrivateRoute", exact: true },

  

  
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_declaration", component: GroupAdminProductDetailsDeclaration, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_time_filter/:productid/:catagoryid/:menuId/:branchID", component: GroupAdminProductDetailsTimeFilter, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_discriptaion/:productid/:catagoryid/:menuId/:branchID", component: GroupAdminProductDetailsDiscriptaion, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_information/:productid/:catagoryid/:menuId/:branchID", component: GroupAdminProductDetailsInformation, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_options/:productid/:catagoryid/:menuId/:branchID", component: GroupAdminProductDetailsAddOn, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_additional_products/:productid/:catagoryid/:menuId/:branchID", component: Additionaloptionsdetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_add_additional_products/:productid/:catagoryid/:menuId/:branchID", component: AddExtrasProductLevel, type: "PrivateRoute", exact: true },

  
  // { path: "/group_admin/menu_management/menu_category/menu_products/product_details_recipe/:productid/:catagoryid/:menuId/:branchID", component: Additionaloptionsdetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_stock/:productid/:catagoryid/:menuId/:branchID", component: StockProductDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_add_stock/:productid/:catagoryid/:menuId/:branchID", component: AddStockProduct, type: "PrivateRoute", exact: true },
  { path: "/group_admin/menu_management/menu_category/menu_products/product_details_edit_stock/:productid/:catagoryid/:menuId/:branchID/:id", component: StockProductDetailsEdit, type: "PrivateRoute", exact: true },

  
  
  { path: "/group_admin/cms_management/edit_terms_and_conditions", component: GroupAdminEditTermsAndCondition, type: "PrivateRoute", exact: true },
  { path: "/group_admin/cms_management/edit_privacy_policy", component: GroupAdminEditPrivacyPolicy, type: "PrivateRoute", exact: true },
  { path: "/group_admin/cms_management/edit_about_us", component: GroupAdminEditAboutUs, type: "PrivateRoute", exact: true },
  { path: "/group_admin/enquiry", component: GroupAdminEnquriy, type: "PrivateRoute", exact: true },
  { path: "/group_admin/enquiry/completed_enquiry", component: GroupAdminCompletedEnquiryList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/enquiry/pending_enquiry", component: GroupAdminPendingEnquiryList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/enquiry/completed_enquiry_view/:id", component: GroupAdminCompletedEnquiryDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/enquiry/pending_enquiry_view/:id", component: GroupAdminPendingEnquiryDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management", component: GroupAdminCoupon_Voucher, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/voucher_management/voucher_templates", component: GroupAdminVoucherListManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/voucher_management/add_voucher", component: GroupAdminAddVoucher, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/voucher_management/edit_voucher/:id/:currency", component: GroupAdminEditVoucher, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/voucher_management/purchase_voucher_details/:id/:currency", component: GroupAdminPurchersVoucherDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/voucher_management/purchased_vouchers", component: GroupAdminPurchesListVoucher, type: "PrivateRoute", exact: true },
  { path: "/group_admin/coupon_voucher_management/voucher_management/voucher_settings", component: GroupAdminVoucherSetting, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/vatCharges", component: GroupAdminVatCharges, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/product_properties", component: GroupAdminCustomProperties, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/group_setup", component: GroupAdminGroupSetup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report", component: GroupAdminReportListpage, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/group_report", component: GroupAdminGroupReport, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/revenue_based_report", component: GroupAdminRevenueBasedReports, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/bills", component: GroupAdminBills, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/voucher", component: GroupAdminVoucherReport, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/order_reports", component: GroupAdminOrderReports, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/product_wise_reports", component: GroupAdminOrderProductWiseReports, type: "PrivateRoute", exact: true },
  { path: "/group_admin/report/tour_gorup_report", component: GroupAdminTourGroupReport, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/printer/printerList", component: GroupAdminPrinterList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/printer/printerCategory", component: GroupAdminPrinterCatagory, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/printer/printerSettings", component: GroupAdminPrinterSettings, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/printer/printerDetails/:id", component: GroupAdminPrinterViewDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/printer/printerEdit/:id", component: GroupAdminPrinterEdit, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour", component: GroupAdminTourList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour/add", component: GroupAdminTourAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour/edit/:tour_id", component: GroupAdminTourEdit, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour/clone/:tour_id", component: GroupAdminTourClone, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour/details/:tour_id", component: GroupAdminTourDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour/comments/:tour_id", component: GroupAdminTourDetails, type: "PrivateRoute", exact: true },
  { path: "/group_admin/tour/invoice-generate", component: GroupAdminTourInvoiceGenerate, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/CompanyList", component: GroupAdminTourSetupCompanyList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/CompanyAdd", component: GroupAdminTourSetupCompanyAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/CompanyEdit/:Company_Id", component: GroupAdminTourSetupCompanyEdit, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/ClientList", component: GroupAdminTourSetupClientList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/ClientAdd", component: GroupAdminTourSetupClientAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/ClientEdit/:Client_Id", component: GroupAdminTourSetupClientEdit, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/MealList", component: GroupAdminTourSetupMealList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/MealAdd", component: GroupAdminTourSetupMealAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/LocationList", component: GroupAdminTourSetupLocationList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/LocationAdd", component: GroupAdminTourSetupLocationAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/StatusList", component: GroupAdminTourSetupStatusList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/StatusAdd", component: GroupAdminTourSetupStatusAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/PaymentStatusList", component: GroupAdminTourSetupPaymentStatusList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/PaymentStatusAdd", component: GroupAdminTourSetupPaymentStatusAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/CurrencyList", component: GroupAdminTourSetupCurrencyList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/CurrencyAdd", component: GroupAdminTourSetupCurrencyAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/VatList", component: GroupAdminTourSetupVatList, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/VatAdd", component: GroupAdminTourSetupVatAdd, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/tourSteup/InvoiceSetup", component: GroupAdminTourSetupInvoiceSetup, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/discount_and_tips_management", component: GroupAdminDiscountAndTips, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/discount_and_tips_management/discout_management", component: GroupAdminDiscountManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/discount_and_tips_management/reasons_management", component: GroupAdminReasonsManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/email_marketing", component: GroupAdminEmailMarketing, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/header_group_admin/order_managementfooter/header", component: GroupAdminSettingHeader, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/header_footer/add_header", component: GroupAdminSettingAddHeader, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/header_footer/footer", component: GroupAdminSettingFooter, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/header_footer/color", component: GroupAdminSettingColor, type: "PrivateRoute", exact: true },
 
  { path: "/group_admin/settings/header_footer/header", component: GroupAdminSettingHeader, type: "PrivateRoute", exact: true },


  { path: "/group_admin/kpgpt", component: KpGpt, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/options/options", component: AddonProperties, type: "PrivateRoute", exact: true },
  { path: "/group_admin/settings/options/add-options", component: AddonAddProperties, type: "PrivateRoute", exact: true },

  { path: "/group_admin/stock_management", component: StockManagement, type: "PrivateRoute", exact: true },
  { path: "/group_admin/stock_management/add_product", component: AddStock, type: "PrivateRoute", exact: true },

  { path: "/group_admin/stock_management/view_stock/:id/:branchId/:catagoryId/:productId/:productName/:productunit/", component: ViewStockManagement, type: "PrivateRoute", exact: true },

  { path: "/group_admin/subscription_management", component: SubscriptionManagementNew, type: "PrivateRoute", exact: true },

];

function App() {

  const currentVersion = packageJson.version;  // Get the current version from .env
  const savedVersion = localStorage.getItem('RGAWebVersion');

  useEffect(()=>{
    if(savedVersion === null){
      localStorage.setItem('RGAWebVersion', currentVersion);
    }
  },[savedVersion === null])
  
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
          <Routes />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
