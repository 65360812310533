import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Chip, ListItemIcon, MenuItem,
} from '@mui/material';
import GroupAdminSidebar from '../../../Comman/Sidebar';
import GroupAdminHeader from '../../../Comman/Header';
import GroupAdminUperSide from '../../../Comman/UperSide';
import { HeaderStyle } from './TableCommonModule';
import api from '../../../../CommonApi/axios';
import headers from '../../../../CommonApi/headers';
import moment from "moment";
import "./table.css"
import BranchListDropDown from '../../../Comman/BranchListDropDown';
import { connect } from "react-redux";
import { getBranchList, getOrderDetails } from '../../../../Store';
import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from '../../../Comman/BranchList';
import Select from "react-select";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { commonTableOptions } from './commonTableOptions';
import SendEmailModel from './SendEmailModel';
import ColumPopover from './ColumPopover';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import deleteOrder from './deleteOrder';
import FilterPopover from './FilterPopover';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import useWindowHeight from '../../../NewTableCommon/useWindowsPosition';
import renderStatusChip from './renderStatusChip';
import useDrawer from '../../../../context/DrawerContext';
import { getCurrencyLabel } from '../../../Comman/currencyUtils/currencyUtils';
import { OrdersPermissions, userPermissionType } from '../../../Comman/UserPermission';
import { selectStyles } from '../../../Comman/CommonFunction/CommonFunction';
import * as XLSX from 'xlsx';


const OrderManagementNew = ({ getBranchList, BranchList_, getOrderDetails }) => {


  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url23 = pathnamesplits[2];

  const TypeSession = sessionStorage.getItem("TypeSession")

  useEffect(() => {
    if (TypeSession === null) {
      sessionStorage.setItem("TypeSession", "dinein");
      // sessionStorage.setItem(`${url23}_${Type}_branchName` , JSON.stringify(opt));
    }
  }, [TypeSession === null]);

  const [Type, setType] = useState(sessionStorage.getItem("TypeSession") || "dinein")
  // const branchNameDinein =  JSON.parse(sessionStorage.getItem(`${url23}_dinein_branchName`));
  // const branchNamepickup_delivery = JSON.parse(sessionStorage.getItem(`${url23}_pickup_delivery_branchName`));

  const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

  const [BranchId, setBranchID] = useState(branchName || "");
  // const [BranchIdpickup_delivery, setBranchIDpickup_delivery] = useState(branchNamepickup_delivery || "");

  const currencyLabel = getCurrencyLabel(BranchId?.currency);

  const filteredOptions = branchfilterAndMapData(BranchList_);
  // console.log("BranchId", BranchId);
  // console.log("filteredOptions", filteredOptions)


  useEffect(() => {
    const findBranchIdAndCheckCurrency = (branchId, options) => {
      const foundOption = options.find(option => option.value === branchId.value);
      return foundOption || null;
    };

    const opt = findBranchIdAndCheckCurrency(BranchId, filteredOptions);

    if (opt && opt.currency !== BranchId.currency) {
      // Assuming setBranchID is a function to update the branch ID in your state
      setBranchID(opt);
      sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
    }
  }, [BranchId, filteredOptions]);


  useEffect(() => {
    getBranchList();
  }, []);

  // const [Type, setType] = useState("dinein")

  const filterButtonColor = JSON.parse(sessionStorage.getItem(`${url23}_${Type}filterData`));

  let history = useHistory();


  const [updateColumns, setUpdateColumns] = useState(false);
  const dineInColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
  const dineInColumnsData = dineInColumnsDataString ? JSON.parse(dineInColumnsDataString) : null;
  const hasFalseValue = dineInColumnsData && Object.values(dineInColumnsData).includes(false);

  const dineInColumnsDataString1 = sessionStorage.getItem(`${url23}_${Type}_columnData`);
  const dineInColumnsData1 = dineInColumnsDataString1 ? JSON.parse(dineInColumnsDataString1) : null;
  const hasFalseValue1 = dineInColumnsData1 && Object.values(dineInColumnsData1).includes(false);

  // console.log("hasFalseValue1",hasFalseValue1)
  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const dineInColumns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'unique_code',
        header: 'Invoice Code',
        size: 80,
        Cell: ({ row }) => (
          <span
            onClick={() => {
              // getOrderDetails(row?.original?._id , "Dinein");
              // getOrderDetails(row?.original?.unique_code , "dineIn" , row?.original?.branch_id); 
              (userPermissionType_ || OrdersPermissions?.access?.includes("view")) && history.push(`/group_admin/order_management/dinein_order_detail_view/${row?.original?.unique_code}?branchId=${row?.original?.branch_id}`);
            }
            }
            style={HeaderStyle?.uniqueCodeHeader}>
            {row?.original?.unique_code}
          </span>
        ),
      },
      {
        accessorKey: 'branchname',
        header: 'Branch',
        size: 200,
        grow: true,
        Cell: ({ row }) => row?.original?.branch?.branch_name_EN,
      },
      {
        accessorKey: 'order_nos',
        header: 'Order Numbers',
        size: 200,
        grow: true,
        Cell: ({ row }) =>
          <span>
            {row?.original?.order_nos?.map((main, index) => (
              <span key={index}>
                {main}
                {index < row.original.order_nos.length - 1 && ', '}  {/* Add a comma except after the last item */}
              </span>
            ))}
          </span>
      },
      {
        accessorKey: 'datetime',
        header: 'Date & Time',
        size: 200,
        Cell: ({ row }) => {
          // const formatDate = (date, timeZone) => {
          //   return timeZone
          //     ? moment?.utc(date)?.tz(timeZone)?.format("DD/MM/YYYY")
          //     : moment?.utc(date)?.format("DD/MM/YYYY");
          // };

          // const formatTime = (time, timeZone) => {
          //   return timeZone ? moment?.utc(time)?.tz(timeZone)?.format("h:mm A") : time;
          // };

          // const { order_date, order_type, order_time, branch } = row.original;
          // const formattedDate = formatDate(
          //   order_date,
          //   order_type === "dineIn" ? branch?.branchTimeZone : null
          // );
          // const formattedTime = formatTime(
          //   order_date,
          //   order_type === "dineIn" ? branch?.branchTimeZone : null
          // );

          return `${moment?.utc(row?.original?.order_date)?.format("DD/MM/YYYY")} ${row?.original?.order_time}`;
        },
      },
      {
        accessorKey: 'order_status',
        header: 'Status',
        size: 200,
        Cell: ({ row }) => renderStatusChip(row?.original?.order_status),
      },
      {
        accessorKey: 'name',
        header: 'Customer',
        size: 250,
        // Cell: ({ row }) => row?.original?.email || "---",
        Cell: ({ row }) => {
          const { email, name, company } = row?.original;
          if (!email && !name && !company) {
            return <div>---</div>;
          } else {
            return (
              <div>
                {email && <div>{email || "---"}</div>}
                {name && <div>{name || "---"}</div>}
                {company && <div>{company || "---"}</div>}
              </div>
            );
          }
        },
      },
      {
        accessorKey: 'table_number',
        header: 'Table No',
        size: 150,
        Cell: ({ row }) => `Table No :- ${row?.original?.table_number}`,
      },
      // {
      //   accessorKey: 'total_amount_without_Discount',
      //   header: BranchId === "" ? "Price" : `Price (${currencyLabel})`,
      //   size: 100,
      //   Cell: ({ row }) => BranchId === "" ? `${getCurrencyLabel(row?.original?.branch?.currency)} ${Number(row?.original?.total_amount_without_Discount || 0).toFixed(2)}` : Number(row?.original?.total_amount_without_Discount || 0).toFixed(2),
      // },

      {
        accessorKey: 'total_amount_without_Discount',
        header: BranchId === "" ? "Price" : `Price (${currencyLabel})`,
        size: 100,
        Cell: ({ row }) => {
          const { branch, total_amount_without_Discount, discount_amount, final_additional_discount_amount } = row?.original || {};
          const totalDiscount = discount_amount || 0;
          const finalDiscount = final_additional_discount_amount || 0;
          const totalAmount = total_amount_without_Discount || 0;

          const amount = totalAmount - totalDiscount - finalDiscount;
          const formattedAmount = Number(amount).toFixed(2);

          if (BranchId === "") {
            const currency = getCurrencyLabel(branch?.currency);
            return `${currency} ${formattedAmount}`;
          }

          return formattedAmount;
        },
      }

    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns, BranchId]);


  const deliveryPickupColumns = useMemo(() => {
    const columns =
      [
        {
          accessorKey: 'unique_code',
          header: 'Invoice Code',
          size: 80,
          Cell: ({ row }) => (

            <span onClick={() => {
              // getOrderDetails(row?.original?._id , "Delivery-pickup")
              (userPermissionType_ || OrdersPermissions?.access?.includes("view")) && history.push(`/group_admin/order_management/order_detail_view/${row?.original?.unique_code}?orderType=${row?.original?.order_type}&branchId=${row?.original?.branch_id}`)
            }}
              style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.unique_code}
            </span>
          ),
        },
        {
          accessorKey: 'branchname',
          header: 'Branch',
          size: 200,
          Cell: ({ row }) => row?.original?.branch?.branch_name_EN,
        },
        {
          accessorKey: 'datetime',
          header: 'Date & Time',
          size: 200,
          Cell: ({ row }) => (
            <div>
              {`${moment?.(row?.original?.order_date)?.format("DD/MM/YYYY")} ${row?.original?.order_time}`}
            </div>
          ),
        },
        {
          accessorKey: 'order_status',
          header: 'Status',
          size: 200,
          Cell: ({ row }) => renderStatusChip(row?.original?.order_status),
        },
        // {
        //   accessorKey: 'email',
        //   header: 'Customer',
        //   size: 250,
        //   Cell: ({ row }) => (
        //     <div>
        //       <div>{row?.original?.name || "---"}</div>
        //       <div>{row?.original?.email || "---" }</div>
        //       <div>{row?.original?.phone_number || "---"}</div>
        //     </div>
        //   ),
        // }
        {
          accessorKey: 'name',
          header: 'Customer',
          size: 250,
          Cell: ({ row }) => {
            const { name, email, phone_number } = row?.original || {};
            const hasValue = name || email || phone_number;

            return hasValue ? (
              <div>
                <div>{name || "---"}</div>
                <div>{email || "---"}</div>
                <div>{phone_number || "---"}</div>
              </div>
            ) : (
              "---"
            );
          },
        },

        {
          accessorKey: 'order_type',
          header: 'Type',
          size: 120,
          Cell: ({ row }) => <div style={{ textTransform: 'capitalize' }}>{row?.original?.order_type}</div>,
        },
        // {
        //   accessorKey: 'total_amount_without_Discount',
        //   header: BranchId === "" ? "Price" : `Price (${currencyLabel})`,
        //   size: 100,
        //   Cell: ({ row }) => BranchId === "" ? `${getCurrencyLabel(row?.original?.branch?.currency)} ${Number(row?.original?.total_amount_without_Discount || 0).toFixed(2)}` : Number(row?.original?.total_amount_without_Discount || 0).toFixed(2),
        // },
        {
          accessorKey: 'total_amount_without_Discount',
          header: BranchId === "" ? "Price" : `Price (${currencyLabel})`,
          size: 100,
          Cell: ({ row }) => {
            const totalAmount = row?.original?.total_amount_without_Discount || 0;
            const totalDiscount = row?.original?.discount_amount || 0;

            const formattedAmount = Number(totalAmount - totalDiscount).toFixed(2);

            if (BranchId === "") {
              const currencyLabel = getCurrencyLabel(row?.original?.branch?.currency);
              return `${currencyLabel} ${formattedAmount}`;
            }

            return formattedAmount;
          },
        }

      ]
    return dineInColumnsData1 === null ? columns : columns.filter(column => dineInColumnsData1[column.header] === true);
  }, [updateColumns, BranchId]);


  const [total_dinein_count, settotal_dinein_count] = useState("");
  const [total_pickup_delivery_count, settotal_pickup_delivery_count] = useState("");

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState("");
  const [OrderData, setOrderData] = useState("");
  const [FilterData, SetFilterData] = useState("");
  const [NewFilterData, SetNewFilterData] = useState("");
  // console.log("FilterData" ,FilterData);
  const prevSorting = useRef([]);

  const [callUsed, setCallUsed] = useState(false)

  useEffect(() => {
    if (callUsed) {
      fetchData1();
    }
  }, [Type === "dinein", globalFilter, BranchId?.value, FilterData?.startDate, FilterData?.orderId, FilterData?.endDate, FilterData?.orderStatus]);

  const fetchData1 = async () => {
    // console.log('filter di')
    if (Type === "dinein") {
      setRowCount(0);
      let url = `/groupadmin/order/getCount/dinein?`;
      let data = JSON.parse(sessionStorage.getItem(`${url23}_dineinfilterData`));
      if (BranchId?.value) {
        url += `branch_id=${BranchId?.value}`;
      }
      if (data?.orderId) {
        url += `&order_number=${data?.orderId}`;
      }
      if (data?.startDate) {
        url += `&start_date=${data?.startDate}`;
      }
      if (data?.endDate) {
        const result2 = moment(data?.endDate).add(1, "days").toDate();
        url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
      }
      if (data?.orderStatus?.value) {
        url += `&order_status=${data?.orderStatus?.value}`;
      }
      if (globalFilter) {
        url += `&search=${globalFilter ?? ''}`;
      }

      try {
        const res = await api.get(url, { headers });
        settotal_dinein_count(res.data.data?.totalOrders || 0);
        if (Type === "dinein") {
          setRowCount(res.data.data?.totalOrders || 0);
        }
      } catch (err) {
        if (Type === "dinein") {
          setRowCount(0);
          settotal_dinein_count(0);
        }
      }
    }
  };

  useEffect(() => {
    // if (!callUsed) { // Check if BranchId and FilterData are defined
    //   fetchData();
    // }
    fetchData2();
  }, [BranchId?.value, FilterData]);

  const fetchData2 = async () => {
    const constructURL = () => {
      let url = `/groupadmin/order/getCount/dinein?`;

      let data = JSON.parse(sessionStorage.getItem(`${url23}_dineinfilterData`));
      // console.log(data)
      if (BranchId?.value) {
        url += `branch_id=${BranchId?.value}`;
      }
      if (data?.orderId) {
        url += `&order_number=${data.orderId}`;
      }
      if (data?.startDate) {
        url += `&start_date=${data.startDate}`;
      }
      if (data?.endDate) {
        const result2 = moment(data.endDate).add(1, "days").toDate();
        url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
      }
      if (data?.orderStatus?.value) {
        url += `&order_status=${data.orderStatus.value}`;
      }

      // if (globalFilter) {
      //   url += `&search=${globalFilter ?? ''}`;
      // }
      return url;
    };

    setRowCount(0);

    const url = constructURL(); // Construct the URL

    // console.log('di url', url);
    try {
      const res = await api.get(url, { headers });
      settotal_dinein_count(res.data.data?.totalOrders || 0);
      if (Type === "dinein") {
        setRowCount(res.data.data?.totalOrders || 0);
        setCallUsed(true)
      }
    } catch (err) {
      if (Type === "dinein") {
        settotal_dinein_count(0);
        setRowCount(0);
      }
    }
  };


  // console.log("BranchId?.value", BranchId?.value)
  useEffect(() => {
    // AllCount()

    // if (!callUsed) {
    //   fetchData();
    // }

    fetchData3();
  }, [BranchId?.value, NewFilterData]);

  const fetchData3 = async () => {
    const constructURL = () => {
      let url = `/groupadmin/order/getCount/pickup_delivery?`;
      let data = JSON.parse(sessionStorage.getItem(`${url23}_pickup_deliveryfilterData`));
      if (BranchId?.value) {
        url += `branch_id=${BranchId?.value}`;
      }
      if (data?.orderId) {
        url += `&order_number=${data.orderId}`;
      }
      if (data?.startDate) {
        url += `&start_date=${data.startDate}`;
      }
      if (data?.endDate) {
        const result2 = moment(data.endDate).add(1, "days").toDate();
        url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
      }
      if (data?.orderStatus?.value) {
        url += `&order_status=${data.orderStatus.value}`;
      }
      return url;
    };

    setRowCount(0);

    const url = constructURL();
    try {
      const res = await api.get(url, { headers });
      // console.log("res" ,res.data.data?.totalOrders)
      settotal_pickup_delivery_count(res.data.data?.totalOrders || 0);
      if (Type === "pickup_delivery") {
        setRowCount(res.data.data?.totalOrders || 0);
        setCallUsed(true)
      }
    } catch (err) {
      if (Type === "pickup_delivery") {
        settotal_pickup_delivery_count(0);
        setRowCount(0);
      }
    }
  };

  useEffect(() => {

    if (callUsed) {
      fetchData4();
    }
  }, [Type === "pickup_delivery", globalFilter, BranchId?.value, NewFilterData?.startDate, NewFilterData?.orderId, NewFilterData?.endDate, NewFilterData?.orderStatus]);

  const fetchData4 = async () => {
    // console.log('filter pi')
    if (Type === "pickup_delivery") {
      setRowCount(0);
      // alert("pickup_delivery")
      let url = `/groupadmin/order/getCount/pickup_delivery?`;
      let data = JSON.parse(sessionStorage.getItem(`${url23}_pickup_deliveryfilterData`));
      if (BranchId?.value) {
        url += `branch_id=${BranchId?.value}`;
      }
      if (data?.orderId) {
        url += `&order_number=${data?.orderId}`;
      }
      if (data?.startDate) {
        url += `&start_date=${data?.startDate}`;
      }
      if (data?.endDate) {
        const result2 = moment(data?.endDate).add(1, "days").toDate();
        url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
      }
      if (data?.orderStatus?.value) {
        url += `&order_status=${data?.orderStatus?.value}`;
      }

      if (globalFilter) {
        url += `&search=${globalFilter ?? ''}`;
      }
      try {
        const res = await api.get(url, { headers });

        settotal_pickup_delivery_count(res.data.data?.totalOrders || 0);
        if (Type === "pickup_delivery") {
          setRowCount(res.data.data?.totalOrders || 0);
        }
      } catch (err) {
        if (Type === "pickup_delivery") {
          settotal_pickup_delivery_count(0);
          setRowCount(0);
        }
      }
    }
  };


  const storedState = sessionStorage.getItem(Type === "dinein" ? `${url23}_paginationState` : `${url23}_paginationState1`);

  useEffect(() => {
    if (storedState !== null) {
      setPagination(JSON.parse(storedState));
    } else {
      setPagination({ pageIndex: 0, pageSize: 10 });
    }
  }, [storedState]);



  const OrdermanagementList = async () => {
    setData([]);
    setIsLoading(true);
    setIsError(false)
    let url = `/groupadmin/order/new/list/${Type}?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;

    if (BranchId?.value) {
      url += `&branch_id=${BranchId?.value}`;
    }
    let data = {}
    if (Type === 'dinein') {
      data = FilterData;
      // if(branchNameDinein !== null){
      //   url += `&branch_id=${BranchId?.value}`;
      // }
    }
    else {
      data = NewFilterData;
      // if(branchNamepickup_delivery !== null){
      //   url += `&branch_id=${BranchId?.value}`;
      // }
    }

    if (data?.orderId) {
      url += `&order_number=${data?.orderId}`;
    }
    if (data?.startDate) {
      url += `&start_date=${data?.startDate}`;
    }
    if (data?.endDate) {
      const result2 = moment(data?.endDate).add(1, "days").toDate();
      url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
    }
    if (data?.orderStatus?.value) {
      url += `&order_status=${data?.orderStatus?.value}`;
    }
    if (sorting?.length > 0 && sorting[0]?.id) {
      switch (sorting[0]?.id) {
        case "branchname":
          url += `&sort_field=branch.branch_name_EN`;
          break;
        case "datetime":
          url += `&sort_field=order_date`;
          break;
        // Add additional cases as needed
        default:
          url += `&sort_field=${sorting[0]?.id}`;
          break;
      }
    }

    if (sorting?.length > 0 && sorting[0]?.desc === true) {
      url += `&sort_type=${'-1'}`;
    }
    if (sorting?.length > 0 && sorting[0]?.desc === false) {
      url += `&sort_type=${'1'}`;
    }
    if (globalFilter) {
      url += `&search=${globalFilter ?? ''}`;
    }



    try {
      const res = await api.get(url, { headers });
      const tableData = res.data.data?.filteredOrderData;
      setData(tableData);
    } catch (err) {
      setIsError(true);
      setData([]);
    } finally {
      setIsLoading(false);
    }

  };

  const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
  prevSorting.current = sorting;

  useEffect(() => {
    if (pagination.pageIndex, pagination.pageSize) {
      OrdermanagementList()
    }
  }, [pagination.pageIndex, pagination.pageSize, globalFilter ?? '', BranchId?.value, Type, FilterData?.startDate,
  FilterData?.orderId, FilterData?.endDate, FilterData?.orderStatus, NewFilterData?.startDate,
  NewFilterData?.orderId, NewFilterData?.endDate, NewFilterData?.orderStatus,
    sortingChanged]);
  // console.log("sorting", sorting)


  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const prevPaginationRef = useRef();

  useEffect(() => {
    if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
      sessionStorage.setItem(Type === "dinein" ? `${url23}_paginationState` : `${url23}_paginationState1`, JSON.stringify(pagination));
    }
    prevPaginationRef.current = pagination;
  }, [pagination]);


  const [show, setShow] = useState(false);

  const handleClose = () => {
    setOrderData("");
    setShow(false)
  };

  const handleShow = () => {
    setShow(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickColum = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColum = () => {
    setAnchorEl(null);
  };

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    // console.log("filterData", filterData);

    Type === 'dinein' ? SetFilterData(filterData) : SetNewFilterData(filterData);

  };

  const userPermissionType_ = userPermissionType === "groupadmin"


  function renderRowActionMenuItems({ closeMenu, row }) {
    return [
      <MenuItem
        key={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            borderRadius: 3,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 35,
              height: 35,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 85,
              width: 13,
              height: 13,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        className='email_'
        onClick={() => {
          handleShow();
          closeMenu();
          setOrderData(row?.original)
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <MailOutlinedIcon />
        </ListItemIcon>
        Send Email
      </MenuItem>,
      (userPermissionType_ || OrdersPermissions?.access?.includes("delete")) && (
        <MenuItem
          key={2}
          className='delete_'
          style={{ color: "#D92D20" }}
          onClick={() => {
            closeMenu();
            deleteOrder(row?.original?._id, OrdermanagementList, pagination, fetchData1, fetchData2, fetchData3, fetchData4)
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon style={{ color: "#D92D20" }} />
          </ListItemIcon>
          Delete Order
        </MenuItem>)
    ];
  }

  const [showMenu, setshowMenu] = useState(false);

  const handleShowMenu = () => {
    setshowMenu(true)
  }

  const [CSVData, setCSVData] = useState([]);
  const [CSVLoader, setCSVLoader] = useState(false);

  useEffect(() => {
    // fetchDataCSVFile();
  }, [globalFilter ?? '', BranchId?.value, Type, FilterData?.startDate,
  FilterData?.orderId, FilterData?.endDate, FilterData?.orderStatus, NewFilterData?.startDate,
  NewFilterData?.orderId, NewFilterData?.endDate, NewFilterData?.orderStatus]);

  const fetchDataCSVFile = async () => {
    setCSVData([]);
    setCSVLoader(true);
    let url = `/groupadmin/order/download/list/${Type}?`;

    if (BranchId?.value) {
      url += `&branch_id=${BranchId?.value}`;
    }
    let data = {}
    if (Type === 'dinein') {
      data = FilterData;
    }
    else {
      data = NewFilterData;
    }

    if (data?.orderId) {
      url += `&order_number=${data?.orderId}`;
    }
    if (data?.startDate) {
      url += `&start_date=${data?.startDate}`;
    }
    if (data?.endDate) {
      const result2 = moment(data?.endDate).add(1, "days").toDate();
      url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
    }
    if (data?.orderStatus?.value) {
      url += `&order_status=${data?.orderStatus?.value}`;
    }
    // if (sorting?.length > 0 && sorting[0]?.id) {
    //   switch (sorting[0]?.id) {
    //     case "branchname":
    //       url += `&sort_field=branch.branch_name_EN`;
    //       break;
    //     case "datetime":
    //       url += `&sort_field=order_date`;
    //       break;
    //     case "email":
    //       url += `&sort_field=name`;
    //       break;
    //     default:
    //       url += `&sort_field=${sorting[0]?.id}`;
    //       break;
    //   }
    // }

    // if (sorting?.length > 0 && sorting[0]?.desc === true) {
    //   url += `&sort_type=${'-1'}`;
    // }
    // if (sorting?.length > 0 && sorting[0]?.desc === false) {
    //   url += `&sort_type=${'1'}`;
    // }
    if (globalFilter) {
      url += `&search=${globalFilter ?? ''}`;
    }

    // console.log("url", url)
    // return
    try {
      const res = await api.get(url, { headers });
      // console.log("ddd", res?.data?.data?.branchData)
      const tableData = res?.data?.data?.branchData;
      setCSVData(tableData);
      if (tableData?.length >= 0) {
        setCSVLoader(false);
      }
    } catch (err) {
      setCSVData([]);
      setCSVLoader(false);
    }
  };

  // console.log("sorting" ,sorting)

  const handleClickCSVDownload = () => {
    const renderStatusChip1 = (status) => {
      const statusLabels = {
        completed: 'Completed',
        payment_approved: 'Payment Approved',
        pending: 'Pending',
      };

      return statusLabels[status] || 'Unknown';
    };

    // Create an object to group orders by branch name
    const branchData = {};

    CSVData.forEach(branch => {
      // Check if the branch has order details
      if (branch.order_details?.length > 0) {
        const branchName = branch.branch_name_EN;

        // Initialize an array for the branch if it doesn't exist
        if (!branchData[branchName]) {
          branchData[branchName] = [];
        }

        // Populate the orders for each branch
        branch?.order_details?.forEach((order, index) => {
          const { name, email, phone_number } = order; // Destructure directly from order
          const hasValue = name || email || phone_number;

          // Prepare customer data
          const customerData = hasValue ? (
            `${email || ""} \n ${name || ""} \n ${phone_number || ""}`
          ) : (
            "---"
          );

          // Function to capitalize the first letter of a string
          const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
          };

          // Push the order data for the specific branch
          branchData[branchName].push({
            "Sr.no": index + 1,
            "Branch name": branchName,
            "Order no": order.unique_code, // Adjust as needed
            "Order type": capitalizeFirstLetter(order.order_type), // Capitalize the first letter
            "Order Date & Time": `${moment(order.order_date).format("DD/MM/YYYY")} ${order.order_time}`,
            "Customer Data": customerData,
            "Order items": order.items?.map((main) => main).join(", "),
            "Payment status": renderStatusChip1(order.order_status),
            "Order amount": Number(order.total_amount || 0),
            "Discount amount": Number(order.discount_amount || 0),
          });
        });
      }
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a separate sheet for each branch with orders
    Object.keys(branchData).forEach(branchName => {
      const xlsxData = branchData[branchName];

      // Only create a worksheet if there is data for the branch
      if (xlsxData.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(xlsxData);

        // Append the worksheet to the workbook with the branch name as the sheet name
        XLSX.utils.book_append_sheet(workbook, worksheet, branchName);
      }
    });

    // Write the workbook to a file
    XLSX.writeFile(workbook, 'Orders.xlsx');
  };





  // console.log("CSVData", CSVData)

  const windowHeight = useWindowHeight();

  const handleFilterColor = () => {
    switch (Type) {
      case "dinein":
        return hasFalseValue ? "#e0e0e045" : "";
      case "pickup_delivery":
        return hasFalseValue1 ? "#e0e0e045" : "";
      default:
        return "";
    }
  };

  const commonOptions = {
    isError, CSVData,
    isRefetching,
    columnFilters,
    globalFilter, CSVLoader,
    sorting,
    setColumnFilters,
    setGlobalFilter,
    setPagination,
    setSorting,
    rowCount,
    isLoading,
    pagination,
    handleClickColum,
    handleFilterClick,
    windowHeight,
    filterButtonColor,
    handleFilterColor,
    Type, handleShow, OrdermanagementList, handleShowMenu, showMenu,
    handleClickCSVDownload

  };

  // console.log("showMenu" ,showMenu)

  const table = useMaterialReactTable({
    columns: dineInColumns,
    data: data,
    ...commonTableOptions(commonOptions),
    renderRowActionMenuItems,
  });

  const table1 = useMaterialReactTable({
    columns: deliveryPickupColumns,
    data: data,
    ...commonTableOptions(commonOptions),
    renderRowActionMenuItems
  });


  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  return (

    <div
      id="kt_body"
      className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
    >
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <GroupAdminHeader />
            <div
              className="content d-flex flex-column flex-column-fluid p-0"
              id="kt_content"
            >
              <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                <main style={{ position: "initial", marginTop: "-30px" }} className={mainClassCustome.join(" ")}>
                  <div className='row col-md-12 d-flex flex-stack'>
                    <div className='d-flex justify-content-start' >
                      <h1
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                        style={{ marginTop: "9px" }}
                      >
                        Orders </h1>
                      <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />
                      <Select
                        className=""
                        value={BranchId}
                        placeholder={getPlaceholder(filteredOptions)}
                        options={getSelectOptions(filteredOptions)}
                        onChange={(opt) => {
                          if (opt.label === "All Locations") {
                            OrdermanagementList();
                            setBranchID("");
                            sessionStorage.removeItem(`${url23}_branchName`)
                          } else {
                            setBranchID(opt);
                            sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                          }
                        }}
                        styles={selectStyles}
                      />
                    </div>
                  </div>
                  <hr style={{ opacity: 0.07, marginTop: 5 }} />
                  <ul className="nav nav-tabs nav-line-tabs mt-5 fs-6">
                    {[
                      {
                        label: "DineIn", value: "dinein",
                        svg:
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            viewBox="0 0 20 20"
                            fill={Type === "dinein" ? "#F25042" : "#667085"}
                          >
                            <g clipPath="url(#clip0_2549_8404)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.79427 16.4212L6.64193 12.5853L8.36719 12.5163C9.10417 12.6178 9.85026 12.9863 10.6003 13.4173L11.9401 13.3639C12.5482 13.377 12.8906 13.9785 12.3164 14.4056C11.8607 14.7689 11.2396 14.7741 10.6016 14.7415C10.1628 14.7376 10.1667 15.3275 10.6237 15.3105C10.7826 15.3171 10.9544 15.2728 11.1042 15.2663C11.8971 15.2337 12.5443 15.0566 12.9193 14.4134L13.1016 13.9368L15.056 12.8691C16.0404 12.5007 16.7031 13.429 15.9974 14.1842C14.5951 15.2923 13.237 16.3027 11.75 17.0605C10.6771 17.7728 9.57552 17.7949 8.45052 17.1921L6.79427 16.4212ZM5.8724 10.5762H17.8385C17.9271 10.5762 18 10.6504 18 10.7376V11.4421C18 11.5293 17.9271 11.6035 17.8385 11.6035H5.8724C5.78385 11.6035 5.71094 11.5306 5.71094 11.4421V10.7376C5.71094 10.6491 5.78385 10.5762 5.8724 10.5762ZM12.3203 3.83919C15.5964 4.10091 18.3555 6.85482 17.9479 10.097H5.76432C5.36198 6.83398 8.13151 4.07617 11.431 3.83529V3.30664H10.9102C10.819 3.30664 10.7435 3.23112 10.7435 3.13997V2.54362C10.7435 2.45247 10.8177 2.37695 10.9102 2.37695H12.8268C12.918 2.37695 12.9935 2.45247 12.9935 2.54362V3.13997C12.9935 3.23112 12.918 3.30664 12.8268 3.30664H12.3203V3.83919ZM2 12.4538L6.10807 12.2376L6.29037 16.8275L2.18229 17.0423L2 12.4538Z"
                                fill={Type === "dinein" ? "#F25042" : "#667085"}
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2549_8404">
                                <rect width={16} height={16} fill="white" transform="translate(2 2)" />
                              </clipPath>
                            </defs>
                          </svg>,
                        count: total_dinein_count

                      },
                      {
                        label: "Delivery/Pickup", value: "pickup_delivery",
                        svg: <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          viewBox="0 0 20 20"
                          fill={Type === "pickup_delivery" ? "#F25042" : "#667085"}
                        >
                          <path
                            d="M10.0001 7.96088L13.0289 6.74939L5.52875 3.74935L2.94291 4.78369C2.81019 4.83678 2.68812 4.90745 2.57894 4.99239L10.0001 7.96088ZM2.03542 5.85202C2.01215 5.95711 2 6.06575 2 6.1764V13.8223C2 14.4357 2.37343 14.9873 2.94291 15.2151L8.70013 17.5179C8.95942 17.6217 9.22802 17.6931 9.50015 17.7324V8.83791L2.03542 5.85202ZM10.5001 17.7324C10.7722 17.6931 11.0407 17.6216 11.2999 17.5179L17.0571 15.2151C17.6266 14.9873 18 14.4357 18 13.8223V6.1764C18 6.06579 17.9879 5.95719 17.9646 5.85213L10.5001 8.83791V17.7324ZM17.4212 4.99247L14.3751 6.21088L6.87504 3.21084L8.70013 2.4808C9.53457 2.14702 10.4654 2.14702 11.2999 2.4808L17.0571 4.78369C17.1898 4.83679 17.312 4.90749 17.4212 4.99247Z"
                            fill={Type === "pickup_delivery" ? "#F25042" : "#667085"}
                          />
                        </svg>,
                        count: total_pickup_delivery_count
                      }
                    ].map((tab, index) => (
                      <li className={Type === tab?.value ? "nav-link active  nav-link_table_active nav-item" : "nav-link_table  nav-item"} key={index}>
                        <div className='nav__' onClick={() => {
                          setType(tab?.value)
                          setGlobalFilter("")
                          sessionStorage.setItem("TypeSession", tab?.value);
                          setSorting([]);
                        }} >
                          <span className='mx-2'>{tab.svg}</span> <span className='navlinkName'> {tab?.label}</span>
                          <Chip className={Type === tab?.value ? 'chips_active mx-2' : "chips mx-2"} label={tab?.count || 0} variant="outlined" />
                        </div>
                      </li>
                    ))}
                  </ul>

                  <MaterialReactTable
                    table={Type === "dinein" ? table : table1}
                  />
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SendEmailModel show={show} OrderData={OrderData} handleShow={handleShow} handleClose={handleClose} />

      <ColumPopover
        anchorEl={anchorEl}
        handleCloseColum={handleCloseColum}
        onColumnDataChange={onColumnDataChange}
        Type={Type}
        BranchId={BranchId}
        currencyLabel={currencyLabel}
      />

      <FilterPopover
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorEl}
        Type={Type}
        handleClose={handleFilterClose}
        handleFilter={handleApplyFilters}
      />

      <ToastContainer />
    </div>

  );
};

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
    getOrderDetails: (id, Type, branchId) => dispatch(getOrderDetails(id, Type, branchId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderManagementNew);
