import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import api from "../../../../CommonApi/axios";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import "./coupon.css";
import useDrawer from "../../../../context/DrawerContext";
import { SubmitformButton } from "../../../Comman/Error/SubmitformButton";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";

window.jquery = window.$ = $;

export default function GroupAdminEditCoupon() {
  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);
  let history = useHistory();

  const [info, setinfo] = useState("");
  const [branch_id, setbranch_id] = useState("");
  const [discount_type, setdiscount_type] = useState("");
  const [discount_value, setdiscount_value] = useState("");
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [min_order_value, setmin_order_value] = useState("");
  const [max_discount_value, setmax_discount_value] = useState("");
  const [apply_coupon_type, setapply_coupon_type] = useState("");
  const [max_usage, setmax_usage] = useState("");
  const [product_ids, setproduct_ids] = useState([]);
  const [end_time, setend_time] = useState("");

  // console.log("apply_coupon_type", apply_coupon_type)


  const [error, setError] = useState(null);

  const { id } = useParams();
  useEffect(() => {
    couponCodeData();
  }, []);

  function couponCodeData() {
    api
      .get(`/groupadmin/coupon/${id}`, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setinfo(tableData.info);
        setend_date(tableData?.end_date?.split("T")[0]);
        setstart_date(tableData?.start_date?.split("T")[0]);
        setmin_order_value(tableData.min_order_value);
        setmax_discount_value(tableData.max_discount_value);
        setbranch_id(tableData.branch_id);
        setdiscount_type(tableData.discount_type);
        setdiscount_value(tableData.discount_value);
        setapply_coupon_type(tableData.apply_coupon_type);
        setmax_usage(tableData.max_usage);
        setproduct_ids(tableData.product_ids ? tableData.product_ids : "");
        setend_time(tableData?.end_time || "")
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
        }
      });
  }

  const [productList, setProductList] = useState([]);
  const [filterData ,setfilterData] = useState([]);
  const [AllData ,setAllData] = useState([])

  useEffect(() => {
    groupAdminProductList();
  }, [branch_id]);

  function groupAdminProductList() {
    api
      .get(`/groupadmin/product/List/${branch_id}`, { headers })
      .then((res) => {
        const options = res.data.data.map((item) => ({
          label: item.name_EN,
          value: item._id,
        }));
        const filteredArray = options.filter(item => product_ids.includes(item.value));
        const filteredArray1 = options.filter(item => !product_ids.includes(item.value));
        setfilterData(filteredArray)
        setProductList(filteredArray1);
        setAllData(options)
        // console.log("res." ,res.data.data)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }



    // console.log("filterData" ,filterData?.length);

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (branch_id == "") {
      setError("Please Select Branch");
      return;
    } else if (discount_type == "") {
      setError("please select discount type.");
      $(".loader").hide();
      return;
    } else if (start_date == undefined) {
      setError("please select start date.");
      $(".loader").hide();
      return;
    } else if (end_date == undefined) {
      setError("please select end date.");
      $(".loader").hide();
      return;
    } else if (start_date > end_date) {
      setError(`Starting date is either equal to or lower than ending date.`);
      $(".loader").hide();
      return;
    } else if (end_date < start_date) {
      setError("End date is either equal to or greater then Start date");
      $(".loader").hide();
      return;
    } else if (apply_coupon_type == "") {
      setError("Select apply coupon typ");
      $(".loader").hide();
      return;
    } else {
      $(".loader").show();

      const formData = new FormData();

      formData.append("branch_id", branch_id);
      formData.append("discount_type", discount_type);
      formData.append("discount_value", discount_value);
      formData.append(
        "start_date",
        moment(new Date(start_date)).format("YYYY-MM-DD")
      );
      formData.append(
        "end_date",
        moment(new Date(end_date)).format("YYYY-MM-DD")
      );
      formData.append("min_order_value", min_order_value);
      formData.append("max_usage", max_usage);
      formData.append("apply_coupon_type", apply_coupon_type);

      if (info) {
        formData.append("info", info);
      }
      if (max_discount_value) {
        formData.append("max_discount_value", max_discount_value);
      }


      // product_ids: product_ids.length > 0 ? product_ids?.map(main => main?.value) : undefined,
      if (filterData.length > 0) {
        formData.append("product_ids", JSON.stringify(filterData?.map(main => main?.value)));
      }

      const time = moment(end_time, 'HH:mm').isValid() ? moment(end_time, 'HH:mm').format('HH:mm:00') : undefined;
      if (time) {
        formData.append("end_time", time);
      }

      // let item = {
      //   branch_id: branch_id,
      //   info: info,
      //   discount_type: discount_type,
      //   discount_value: Number(discount_value),
      //   start_date: moment(new Date(start_date)).format("YYYY-MM-DD"),
      //   end_date: moment(new Date(end_date)).format("YYYY-MM-DD"),
      //   min_order_value: Number(min_order_value),
      //   max_discount_value: Number(max_discount_value),
      //   max_usage: Number(max_usage),
      //   apply_coupon_type: apply_coupon_type,
      //   product_ids: product_ids,
      // };

      // console.log("  sdss", Object.fromEntries(formData.entries()));
      // return

      api
        .patch(`/groupAdmin/coupon/${id}`, formData, { headers })
        .then((res) => {
          $(".loader").hide();
          showSuccessNotification("Coupon has been edited succesfully.")
          history.push("/group_admin/coupon_voucher_management/coupon_management");
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            setError(err.response?.data?.error?.message);
          }
        });
    }
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack mb-3">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Edit Coupon
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="mb-10">
                                <h4>Coupon detail.</h4>
                              </div>
                              <div className="mb-10">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Info
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Enter your info"
                                  value={info}
                                  onChange={(e) => setinfo(e.target.value)}
                                />
                              </div>

                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="required form-label"
                                  >
                                    Min Order value
                                  </label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    placeholder="Enter min order value"
                                    value={min_order_value}
                                    required
                                    onChange={(e) =>
                                      setmin_order_value(e.target.value)
                                    }
                                  />
                                </div>
                                {discount_type == "fix" ? (
                                  ""
                                ) : (
                                  <div className="col-md-6 mb-10">
                                    <label
                                      htmlFor="exampleFormControlInput1"
                                      className="required form-label"
                                    >
                                      Max Discount value
                                    </label>
                                    <input
                                      type="text"
                                      className="filterme form-control form-control-solid"
                                      placeholder="Enter max order value"
                                      required
                                      value={max_discount_value}
                                      onChange={(e) =>
                                        setmax_discount_value(e.target.value)
                                      }
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label required"
                                  >
                                    Max usages
                                  </label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    placeholder="Enter max usages"
                                    value={max_usage}
                                    required
                                    onChange={(e) =>
                                      setmax_usage(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="required form-label"
                                  >
                                    {discount_type === "percentage"
                                      ? "Discount Value (%)"
                                      : "Discount Value"}
                                  </label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    placeholder={
                                      discount_type === "percentage"
                                        ? "Enter discount Value (%)"
                                        : "Enter iscount Value"
                                    }
                                    value={discount_value}
                                    required
                                    onChange={(e) =>
                                      setdiscount_value(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-4">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="required form-label"
                                  >
                                    Start date
                                  </label>

                                  <input type="date" id="endDate"
                                    className="form-control form-control-solid"
                                    min={new Date().toISOString().split('T')[0]}
                                    value={start_date}
                                    required
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(e) => {
                                      setstart_date(e.target.value);
                                    }}
                                  />

                                </div>
                                <div className="mb-10 col-md-4">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="required form-label"
                                  >
                                    End date
                                  </label>
                                  <input type="date" id="endDate"
                                    className="form-control form-control-solid"
                                    min={new Date().toISOString().split('T')[0]}
                                    value={end_date}
                                    required
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(e) => {
                                      setend_date(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mb-5 col-md-4">
                                  <label className="form-label">
                                    End time
                                  </label>

                                  <input type="time" id="endtime"
                                    className="form-control form-control-solid"
                                    value={end_time}
                                    onChange={(e) => {
                                      setend_time(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              {apply_coupon_type === "product" && (
                                <div className="col-md-12 mb-10">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Product
                                  </label>
                                  <Select
                                    id="select2"
                                    className="search-line"
                                    placeholder="Search..."
                                    isMulti={true}
                                    value={filterData}
                                    // options={productList}
                                    options={[
                                      {
                                        label: "All",
                                        value: productList,
                                      },
                                      ...productList,
                                    ]}
                                    onChange={(selectedOptions) => {
                                      if (selectedOptions?.some((opt) => opt.label === "All")) {
                                        // const result = productList.concat(filterData)
                                        setfilterData(AllData);
                                      } else {
                                        setfilterData(selectedOptions); // Set the selected options (Array of selected objects)
                                      }
                                    }}
                                  />
                                </div>
                              )}
                              <div className="col-md-12 text-center">
                                <br />
                                <div
                                  className="loader"
                                  style={{ display: "none" }}
                                >
                                  <img src="/assets/loader.gif" />
                                </div>
                              </div>
                              <ErrorMessage error={error} />
                              <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
