import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { useParams } from "react-router-dom";
import headers from "../../../../CommonApi/headers";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDrawer from "../../../../context/DrawerContext";

export default function GroupAdminViewCoupon() {
  let id = useParams();
  useEffect(() => {
    groupadminCouponDetaisView();
  }, []);
  let history = useHistory();


  const [user, setuser] = useState("");

  function groupadminCouponDetaisView() {
    api
      .get(`/groupadmin/coupon/${id.id}`, { headers })
      .then((res) => {
        const options = res.data.data;
        setuser(options);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
  }

  const [ProductList ,setProductList] = useState([]);

  useEffect(() => {
    groupAdminProductList();
  }, [user]);

  function groupAdminProductList() {
    if(user?.apply_coupon_type === "product" && user?.branch_id){
    api
      .get(`/groupadmin/product/List/${user?.branch_id}`, { headers })
      .then((res) => {
        const options = res.data.data.map((item) => ({
          label: item.name_EN,
          value: item._id,
        }));
        const filteredArray = options.filter(item => user?.product_ids.includes(item.value));
        // const filteredArray1 = options.filter(item => !product_ids.includes(item.value));
        // setfilterData(filteredArray)
        setProductList(filteredArray);
        // setAllData(options)
        // console.log("res." ,filteredArray)
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response?.data?.error?.message);
        }
      });
    }
  }

  console.log("ProductList" ,ProductList)

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                       

                    <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header pt-5 border-1">
                        <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                         Coupon Details
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                        </div>
                        </div>
                        <div className="card-body">
                          <div className="row p-3 pt-0">
                            <table
                              className="table table-bordered border rounded"
                              style={{ width: "100%" }}
                            >
                              <tbody>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Info
                                  </th>
                                  <td>{user ? user.info : "No data"}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Branch Name
                                  </th>
                                  <td>
                                    {user?.branch
                                      ? user?.branch?.branch_name_EN
                                      : "No data"}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Order Type
                                  </th>
                                  <td>
                                    {user?.order_type
                                      ? user?.order_type
                                          ?.charAt(0)
                                          .toUpperCase() +
                                        user?.order_type?.slice(1)
                                      : "No data"}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Discount type
                                  </th>
                                  <td>
                                    {user
                                      ? user.discount_type === "fix"
                                        ? "Fix"
                                        : "Percentage"
                                      : "No Data."}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    {user.discount_type === "percentage"
                                      ? " Discount value (%)"
                                      : " Discount value"}
                                    {/* Discount value */}
                                  </th>
                                  <td>
                                    {user ? user.discount_value : "No Data."}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Start Date
                                  </th>
                                  <td>
                                    {user
                                      ?  moment(user.start_date?.split("T")[0]).format("DD/MM/YYYY")
                                      : "No Data."}
                                    {/* {user
                                      ? moment(
                                          new Date(user.start_date)
                                        ).format("YYYY-MM-DD , h:mm a")
                                      : "No Data."} */}
                                    {/* {user ? user.start_date : "No Data."} */}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    End Date
                                  </th>
                                  <td>
                                    {user
                                      ? moment(user?.end_date?.split("T")[0]).format("DD/MM/YYYY")
                                      : "No Data."}
                                    {/* {user
                                      ? moment(new Date(user.end_date)).format(
                                          "YYYY-MM-DD , h:mm "
                                        )
                                      : "No Data."} */}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    End Time
                                  </th>
                                  <td>
                                    {user?.end_time
                                       || "--"}
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Min order value
                                  </th>
                                  <td>
                                    {user ? user.min_order_value : "No Data."}
                                  </td>
                                </tr>
                                {user.discount_type === "percentage" && (
                                  <tr style={{ border: "1px solid #000" }}>
                                    <th
                                      className="fw-bolder fs-6 text-gray-800 px-7"
                                      style={{
                                        backgroundColor: "darkgray",
                                        width: "30%",
                                      }}
                                    >
                                      Max discount value
                                    </th>
                                    <td>
                                      {user
                                        ? user.max_discount_value
                                        : "No Data."}
                                    </td>
                                  </tr>
                                )}

                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Max usage
                                  </th>
                                  <td>{user ? user.max_usage : "No Data."}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Apply coupon type
                                  </th>
                                  <td>
                                    {user ? user.apply_coupon_type : "No Data."}
                                  </td>
                                </tr>
                                {user.apply_coupon_type === "product" && (
                                  <tr style={{ border: "1px solid #000" }}>
                                    <th
                                      className="fw-bolder fs-6 text-gray-800 px-7"
                                      style={{
                                        backgroundColor: "darkgray",
                                        width: "30%",
                                      }}
                                    >
                                      Product Name
                                    </th>
                                    <td>
                                      {ProductList?.map((main,index)=>(
                                        <div key={index}>{main?.label}</div>
                                      ))}
                                      {/* {user ? user.product_ids : "No Data."} */}
                                    </td>
                                  </tr>
                                )}

                                {/* <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Fields :
                                  </th>
                                  {fields.map((main, index) => (
                                    <div>
                                      {index + 1} :- {main ? main : "No Data."}
                                    </div>
                                  ))}
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
