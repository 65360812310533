import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import MaterialTable from "material-table";
import DownloadIcon from "@mui/icons-material/Download";
import { useStyles } from "../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../Comman/TableOption";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import useDrawer from "../../../../context/DrawerContext";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { LoadingBackdrop } from "../../../Comman/LoadingBackdrop/LoadingBackdrop";
import { getCurrencyLabel } from "../../../Comman/currencyUtils/currencyUtils";


function GroupAdminOrderDetailsViewDinein() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [user, setuser] = useState([]);
  const [currencyLabel, setcurrencyLabel] = useState("");
  const [Loading, setLoading] = useState(false)
  const [orderItemData, setorderItemData] = useState([]);
  const [total, setTotal] = useState("");
  let history = useHistory();
  let id = useParams();


  function getUrlParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }



  const branchId = getUrlParameter('branchId');

  useEffect(() => {
    let currencyLabel = getCurrencyLabel(user && user?.branch?.currency);
    setcurrencyLabel(currencyLabel)
  }, [user && user?.branch?.currency])

  useEffect(() => {
    let isMounted = true; // track if component is mounted
    setLoading(true); // Set loading state to true before making the API call
    if (id.id && branchId) {
      api.get(`/groupadmin/order/new/${id.id}/dineIn/${branchId}`, { headers })
        .then((res) => {
          if (isMounted) {
            const tableData = res.data.data;
            setuser(tableData?.orderData?.length > 0 && tableData?.orderData[0]);
            setTotal(tableData);
            setorderItemData(tableData?.orderData);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (isMounted) setLoading(false);
        });
    }
    return () => {
      isMounted = false; // cleanup function to set isMounted to false
    };
  }, [id.id, branchId]); // Add dependencies

  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      window.scrollTo(0, 0);
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [orderItemData]);

  const classes = useStyles();

  const columns = useMemo(() => [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
      customSort: (a, b) => a?.tableData?.id - b?.tableData?.id,
    },
    {
      field: "url",
      title: "Photo",
      render: (rowData) => (
        <img
          loading="lazy"
          src={rowData?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
          style={{ width: 100, height: 110, borderRadius: "10%" }}
        />
      ),
    },
    {
      field: "name_EN",
      title: "Product Name",
      render: (rowData) => {
        return (
          <div>
            {rowData?.quantity} x {rowData?.name_EN}
            {rowData?.is_pickup && <img alt="Take_away" loading="lazy" src="/assets/media/avatars/take-away1 1.png" style={{ height: 17 }} />}
            {rowData?.is_custom && <img alt="Take_away" loading="lazy" src="/assets/media/avatars/CustomeDish.png" style={{ height: 17 }} />}
            <ul>
              {rowData?.custom_property?.length > 0 && rowData?.custom_property.map((main, index) => (
                <li key={index}>{main?.ingredient}</li>
              ))}
            </ul>
          </div>
        );
      },
      customSort: (a, b) => {
        const productNameA = `${a?.quantity} x ${a?.name_EN}`;
        const productNameB = `${b?.quantity} x ${b?.name_EN}`;
        return productNameA?.localeCompare(productNameB);
      },
    },
    {
      title: "Product Notes",
      field: "product_notes",
      render: (rowData) => <div>{rowData?.product_notes || "---"}</div>,
      customSort: (a, b) => a?.product_notes?.localeCompare(b?.product_notes),
    },
    {
      field: "base_price",
      title: `Product Total Price (${currencyLabel})`,
      render: (rowData) => <div>{Number(rowData?.quantity * rowData?.base_price).toFixed(2)}</div>,
      customSort: (a, b) => (a?.quantity * a?.base_price) - (b?.quantity * b?.base_price),
    },
    {
      field: "add_on_price",
      title: `Addon Price (${currencyLabel})`,
      render: (rowData) => <React.Fragment>{Number(rowData?.add_on_price).toFixed(2)}</React.Fragment>,
      customSort: (a, b) => a?.add_on_price - b?.add_on_price,
    },
    {
      field: "base_price",
      title: `Total Price (${currencyLabel})`,
      render: (rowData) => <div>{Number((rowData?.quantity * rowData?.base_price) + rowData?.add_on_price).toFixed(2)}</div>,
      customSort: (a, b) => ((a.quantity * a.base_price) + a.add_on_price) - ((b.quantity * b.base_price) + b.add_on_price),
    },
  ], [currencyLabel]);

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const { email, name, company } = user;

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div ref={scrollRef} className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="card card-docs mb-2">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div className="d-flex flex-stack">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h1
                              className="anchor fw-bolder"
                              id="custom-form-control"
                            >
                              Order Details
                            </h1>
                          </div>
                          <div className="d-flex flex-stack">
                            <div
                              className="d-flex justify-content-end mx-2"
                              style={{ marginTop: "-5px" }}
                              data-kt-docs-table-toolbar="base"
                            >
                              <button
                                onClick={() => history.goBack()}
                                className="btn btn-primary"
                                data-kt-menu-trigger="click"
                                data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-end"
                              >
                                Go Back
                              </button>
                            </div>
                          </div>
                        </div>
                        <br />
                        <hr />
                        <br />

                        <div className="row">
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="content-section">
                                <div className="row col-md-12">
                                  <div className="col-md-6">
                                    <h1>
                                      <b>
                                        Invoice Code  :{" "}
                                        <strong style={{ color: "#5e6278" }}>
                                          {user?.unique_code || "---"}
                                        </strong>
                                      </b>
                                    </h1>
                                  </div>
                                  <div className="col-md-6 text-end">
                                    <h4>
                                      Branch Name : <strong style={{ color: "#5e6278" }}> {user?.branch?.branch_name_EN || "---"}</strong>
                                    </h4>
                                  </div>

                                  <div className="col-md-6">
                                    <b>Table No  : </b>
                                    <strong style={{ color: "#5e6278" }}>
                                      {user?.table_number || "---"}
                                    </strong>
                                  </div>
                                  <div className="col-md-6 text-end">
                                    <h4>
                                      Payment Method  : <strong style={{ color: "#5e6278" }}>
                                        {user?.payment_method === "COD"
                                          ? "Cash"
                                          : "Online"}</strong>
                                    </h4>
                                  </div>

                                  <div className="col-md-6">
                                    {user?.invoice_link &&
                                      <div className="mt-3">
                                        <b>Order invoice : </b>
                                        <span>
                                          <h2
                                            className="badge badge-primary mx-2"
                                            style={{ fontSize: "14px" }}
                                            type="button"
                                            target="_blank"
                                            download
                                            onClick={() =>
                                              window.open(`${user?.invoice_link}`)
                                            }
                                          >
                                            <DownloadIcon /> Download invoice
                                          </h2>
                                        </span>
                                      </div>}
                                  </div>
                                  <div className="col-md-6 text-end">
                                    {user?.order_type === "dineIn" && (
                                      <h4
                                        className="badge badge-info"
                                        style={{ fontSize: "16px" }}
                                      >
                                        DineIn
                                      </h4>
                                    )}
                                  </div>

                                </div>
                                <div className="row mx-2">
                                  <div className={`col-md-12 ${isMobile ? 'text-start' : 'text-end'}`}>
                                    <strong><b>Order Status :</b> {" "}</strong>
                                    {(() => {
                                      switch (user?.order_status) {
                                        case "pending":
                                          return (
                                            <h4 className="badge badge-primary" style={{ fontSize: "16px" }}>
                                              Pending
                                            </h4>
                                          );
                                        case "payment_approved":
                                          return (
                                            <h4 className="badge badge-success" style={{ fontSize: "16px" }}>
                                              Payment Approved
                                            </h4>
                                          );
                                        case "completed":
                                          return (
                                            <h4 className="badge badge-success" style={{ fontSize: "16px" }}>
                                              Completed
                                            </h4>
                                          );
                                        default:
                                          return "---";
                                      }
                                    })()}
                                  </div>
                                </div>

                                <br />
                                <br />
                                <div
                                  className={
                                    isMobile ? "row col-md-12 my-5" : "row col-md-12"
                                  }
                                >


                                  {!email && !name && !company ? "" :
                                    <h1>Customer Info</h1>}
                                </div>
                                <div className="col-md-12">
                                  {user?.email &&
                                    <p>
                                      Email : {""}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.email || "--"}
                                      </strong>{" "}
                                    </p>}
                                  {user?.name &&
                                    <p>
                                      Name : {""}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.name || "--"}
                                      </strong>{" "}
                                    </p>}
                                  {user?.company &&
                                    <p>
                                      Company : {""}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.company || "--"}
                                      </strong>{" "}
                                    </p>}
                                </div>


                                {orderItemData?.length > 0 &&
                                  orderItemData?.map((main, index) => (
                                    <div className="row" key={index}>
                                      <div className="my-5">
                                        <div className="row col-md-12">
                                          <h2>
                                            <b>
                                              Order No  :{" "}
                                              <strong style={{ color: "#5e6278" }}>
                                                {main?.order_no}
                                              </strong>
                                            </b>
                                          </h2>
                                          <h5>
                                            <b>
                                              Order place date  :{" "}
                                              <strong style={{ color: "#5e6278" }}>
                                                {moment(main?.order_date).format("MMMM Do YYYY")} {main?.order_time}
                                              </strong>
                                            </b>
                                          </h5>
                                          {main?.order_notes &&
                                            <div className="col-md-6">
                                              <b>Order Notes : {""} </b>
                                              <span>
                                                <strong style={{ color: "#5e6278" }}>
                                                  {main?.order_notes}
                                                </strong>
                                              </span>
                                            </div>}
                                        </div>
                                        {main?.orderItemData?.length > 0 &&
                                          <div className={classes.root}>
                                            <MaterialTable
                                              title=""
                                              data={main?.orderItemData}
                                              columns={columns}
                                              options={Tableoptions}
                                            />
                                          </div>}
                                      </div>
                                    </div>
                                  ))
                                }
                                <br />
                              </div>
                            </div>
                            <hr />
                            <div className="row col-md-12">
                              <div className="col-md-10 text-end">
                                <h4>
                                  Sub Total :{" "}
                                  {isMobile
                                    && `${currencyLabel} ${Number(total?.subTotal).toFixed(2)}`
                                  }
                                </h4>
                              </div>
                              {isMobile ? (
                                ""
                              ) : (
                                <div className="col-md-2">
                                  <h4>
                                    {currencyLabel}{" "}
                                    {Number(total?.subTotal || "---").toFixed(2)}
                                  </h4>
                                </div>
                              )}
                            </div>

                            {user?.additional_charges > 0 ? (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Additional Charges :{" "}
                                    {isMobile
                                      ? `${currencyLabel} ${Number(user?.additional_charges || 0).toFixed(2)}`
                                      : ""}
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {
                                        Number(user?.additional_charges || 0).toFixed(2)
                                      }
                                    </h4>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}


                            {total?.discount > 0 && (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Discount Amount :{" "}
                                    {isMobile
                                      && `${currencyLabel} ${Number(total?.discount).toFixed(2)}`
                                    }
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {Number(total?.discount).toFixed(2)}
                                    </h4>
                                  </div>
                                )}
                              </div>
                            )}


                            {total?.additionalDiscount > 0 && (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Additional Discount Amount :{" "}
                                    {isMobile
                                      && `${currencyLabel} ${Number(total?.additionalDiscount).toFixed(2)}`
                                    }
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {
                                        Number(total?.additionalDiscount).toFixed(2)
                                      }
                                    </h4>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="row col-md-12">
                              <div className={"col-md-10 text-end"}>
                                <h3>
                                  Grand Total :{" "}
                                  {isMobile
                                    && `${currencyLabel} ${Number(total?.grandTotal).toFixed(2)}`
                                  }
                                </h3>
                              </div>
                              {isMobile ? (
                                " "
                              ) : (
                                <div className={"col-md-2"}>
                                  <h3>
                                    {currencyLabel} {Number(total?.grandTotal).toFixed(2)}
                                  </h3>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
        <LoadingBackdrop open={Loading} />
      </div>
    </React.Fragment>
  );
}

export default GroupAdminOrderDetailsViewDinein;


