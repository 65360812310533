import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import api from "../../CommonApi/axios";
import { validateEmail } from "../Comman/AddCommonFun/validation";
import { ErrorMessage } from "../Comman/Error/ErrorMessage";
import useError from "../Comman/Error/useError";
import { showSuccessNotification } from "../Comman/Swal/Swal";
import packageJson from "../../../package.json"

export default function GroupAdminLogin() {
  let history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const { error, showError, clearError } = useError();


  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);

    if (!validateEmail(email)) {
      showError("Invalid Email");
    } else if (
      password.toString().trim().length < 0 ||
      password.toString().trim().length < 6
    ) {
      showError("Password must be at least 6 character long");
    } else {
      let item = {
        email: email,
        password: password,
      };
      api
        .post(`/groupadmin/auth/login`, item)
        .then((res) => {
          // console.log("aaaa",res.data.data);
          clearError()
          // localStorage.setItem("customToken", res?.data?.data?.customToken);
          localStorage.setItem("GroupAdminID", res?.data?.data?.uid);
          localStorage.setItem("RGALOGO", res?.data?.data?.logo_url || "/assets/media/logos/Logo_Dark.png");
          localStorage.setItem("RGAICON", res?.data?.data?.icon_url || "/assets/media/logos/favicon-32x32.png");
          // let customToken = localStorage.getItem("customToken");
          localStorage.setItem("RESGroup", JSON.stringify(res.data.data));
          const currentVersion = packageJson.version;
          localStorage.setItem('RGAWebVersion', currentVersion);
          if (res.data.data.customToken) {
            if (res?.data?.data?.user_type === "groupadmin") {
              history.push("/group_admin/dashboard");
              localStorage.setItem("RedirectUrl", '/group_admin/dashboard');
            } else {
              const response = res?.data?.data?.role_details?.permissions;

              let redirected = false;

              for (let i = 1; i < response.length; i++) {
                const item = response[i];
                if (item.access.length > 0) {
                  switch (item.key) {
                    case 'dashboard':
                      history.push('/group_admin/dashboard');
                      localStorage.setItem("RedirectUrl", '/group_admin/dashboard');
                      // alert("ss")
                      redirected = true;
                      break;
                    case 'branch':
                      history.push('/group_admin/branch_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/branch_management');
                      redirected = true;
                      break;
                    case 'tables':
                      history.push('/group_admin/table_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/table_management');
                      redirected = true;
                      break;
                    case 'orders':
                      history.push('/group_admin/order_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/order_management');
                      redirected = true;
                      break;
                    case 'menus':
                      history.push('/group_admin/menu_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/menu_management');
                      redirected = true;
                      break;
                    case 'stock':
                      history.push('/group_admin/stock_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/stock_management');
                      redirected = true;
                      break;
                    case 'coupons':
                      history.push('/group_admin/coupon_voucher_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/coupon_voucher_management');
                      redirected = true;
                      break;
                    case 'vouchers':
                      history.push('/group_admin/coupon_voucher_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/coupon_voucher_management');
                      redirected = true;
                      break;
                    case 'tour_groups':
                      history.push('/group_admin/tour');
                      localStorage.setItem("RedirectUrl", '/group_admin/tour');
                      redirected = true;
                      break;
                    case 'email_marketing':
                      history.push('/group_admin/email_marketing');
                      localStorage.setItem("RedirectUrl", '/group_admin/email_marketing');
                      redirected = true;
                      break;
                    case 'subscriptions':
                      history.push('/group_admin/subscription_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/subscription_management');
                      redirected = true;
                      break;
                    case 'enquiries':
                      history.push('/group_admin/enquiry');
                      localStorage.setItem("RedirectUrl", '/group_admin/enquiry');
                      redirected = true;
                      break;
                    case 'KPGPT':
                      history.push('/group_admin/KPGPT_management');
                      localStorage.setItem("RedirectUrl", '/group_admin/KPGPT_management');
                      redirected = true;
                      break;
                    default:
                      // Handle any other keys if necessary
                      break;
                  }
                  if (redirected) break; // Exit the loop after the first match and successful redirection
                }
              }
              // Check reports and redirect if no previous redirection occurred

              if (!redirected) {
                const reports = res?.data?.data?.role_details?.reports?.filter(item => item.access.length > 0);
                if (reports?.length > 0) {
                  history.push("/group_admin/report");
                  localStorage.setItem("RedirectUrl", '/group_admin/report');
                  redirected = true;
                }
              }

              // Check settings and redirect if no previous redirection occurred
              if (!redirected) {
                const settings = res?.data?.data?.role_details?.settings?.filter(item => item.access.length > 0);
                if (settings?.length > 0) {
                  history.push("/group_admin/settings");
                  localStorage.setItem("RedirectUrl", '/group_admin/settings');
                  redirected = true;
                }
              }

            }

            sessionStorage.clear();
            window.location.reload(true);
          }

        })
        .catch((err) => {
          if (err.response) {
            var ermes = err.response?.data?.error?.message;
            showError(ermes);
            // showError("Invalid Email and password.")
          } else if (err.request) {
            showError(err.request);
          } else {
            showError(err.message);
          }
        });
    }
  }

  const toggleIsPasswordShowValue = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const [errorForgate, setErrorForgate] = useState(null);
  const [forgotemail, setforgotemail] = useState("");

  const handleClose = () => {
    setforgotemail("");
    setErrorForgate(null);
  };

  function handleSubmitForgetPassword(event) {
    event.preventDefault();
    setErrorForgate(null);

    api
      .get(`/groupadmin/auth/forgotpassword/${forgotemail}`)
      .then((res) => {
        showSuccessNotification("Please check your email to proceed further");
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      })
      .catch((err) => {
        if (err.response) {
          setErrorForgate(err?.response?.data?.error?.message);
        }
      });
  }

  return (
    <React.Fragment>
      <div id="kt_body" className="bg-body">
        <div className="d-flex flex-column flex-root">
          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid"
            style={{ minHeight: "100vh" }}
          >
            <div className="row col-md-12">
              <div className="col-md-5">
                <img
                  alt="Logo"
                  style={{ height: "100vh", width: "100%", objectFit: "fill" }}
                  src="/assets/media/illustrations/sketchy-1/beth-d--lw_lvc9hq8-unsplash.jpg"
                />{" "}
              </div>
              <div className="col-md-7 my-auto">
                <div className="d-flex flex-column flex-lg-row-fluid py-10">
                  <div className="d-flex flex-center flex-column flex-column-fluid">
                    <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                      <form
                        className="form"
                        id="gI_sign_in_form"
                        onSubmit={handleSubmit}
                      >
                        <div className="text-center">
                          <img
                            style={{
                              height: "100%",
                            }}
                            alt="Logo"
                            src={
                              "/assets/media/logos/Logo_Dark.png"
                            }
                          />
                        </div>
                        <div className="text-center mt-10">
                          <h1
                            className="text-dark mb-3"
                            style={{ fontSize: "25px" }}
                          >
                            Restaurant Group Admin
                          </h1>
                        </div>

                        <div className="d-flex align-items-center mb-10">
                          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                          <span className="fw-bold text-gray-400 fs-7 mx-2">
                            LOGIN
                          </span>
                          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        </div>

                        <div className="fv-row mb-10">
                          <label className="form-label required fs-6 fw-bolder text-dark">
                            Email
                          </label>

                          <input
                            className="form-control form-control-lg form-control-solid border-dark"
                            type="email"
                            name="email"
                            autoComplete="on"
                            required
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className="fv-row">
                          <label className="form-label required fs-6 fw-bolder text-dark">
                            Password
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control form-control-lg form-control-solid border-dark"
                              name="password"
                              autoComplete="off"
                              type={isPasswordShow ? "text" : "password"}
                              value={password}
                              required
                              onChange={(e) => setpassword(e.target.value)}
                            />
                            {password && (
                              <span
                                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                data-kt-password-meter-control="visibility"
                                onClick={toggleIsPasswordShowValue}
                              >
                                <i className="bi bi-eye-slash fs-2" />
                                <i className="bi bi-eye fs-2 d-none" />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="fv-row text-end my-3">
                          <label className="form-label fw-bolder text-dark">
                            <Link
                              onClick={handleClose}
                              data-bs-toggle="modal"
                              to="#kt_modal_1"
                            >
                              Forgot password ?
                            </Link>
                          </label>
                        </div>
                        <ErrorMessage error={error} />

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary w-100 mb-5"
                          >
                            <span className="indicator-label">Login</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmitForgetPassword}>
                <h2 className="mb-4">Forgot Password</h2>

                <hr />

                <div className="mx-4">
                  <div className="my-5">
                    <label className="required form-label">Email</label>
                    <input
                      className="form-control form-control-lg form-control-solid border-dark"
                      placeholder="Enter your email"
                      required
                      value={forgotemail}
                      onChange={(e) => setforgotemail(e.target.value)}
                    />
                  </div>
                </div>
                <ErrorMessage error={errorForgate} />
                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Send Email</span>
                  </button>
                  <button
                    type="button"
                    className="modal-footer btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>
        <script src="/assets/js/custom/authentication/sign-in/general.js"></script>
      </Helmet>
    </React.Fragment>
  );
}
