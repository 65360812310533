import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getInventoryProList, getMenuList } from "../../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "../../../Comman/BranchList";
import { getBranchList } from "../../../../Store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
// import "../menuManagmnet.css"
import useDrawer from "../../../../context/DrawerContext";
import { userPermissionType } from "../../../Comman/UserPermission";
import { Delete24Regular, AddCircle24Filled } from '@fluentui/react-icons';
import Button from '@mui/material/Button';
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { SubmitformButton } from "../../../Comman/Error/SubmitformButton";
import { submitForm } from "../../../Comman/AddCommonFun/AddCommonFunction";
import useError from "../../../Comman/Error/useError";
import convert from 'convert-units';
import { unitFullForms } from "./unit";


function AddStock({ getMenuList, menuList, getBranchList, BranchList_, getInventoryProList, inventoryproductList_ }) {

      const windowHeight = useWindowHeight();

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const history = useHistory();
      const [stockItems, setStockItems] = useState([{ id: 1, branchId: null, category: null, productName: null, unit: null, price: "", quantity: "", alertThreshold: "" }]);

      // console.log("inventoryproductList_" , inventoryproductList_?.inventoryproductList?.filter((main)=>main?.products?.length > 0))

      useEffect(() => {
            getMenuList();
            getBranchList();
            getInventoryProList();
      }, []);


      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

      const commonSelectStyles = {
            control: (provided) => ({
                  ...provided,
                  borderRadius: "8px",
                  height: '41px',  // Adjust this value to your desired height
                  minHeight: '41px',
                  border: "1px solid #e4e6ef"
            }),
            menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  // maxHeight: '200px',   // Set a maximum height for the menu
                  // overflowY: 'auto',  
            })
      };


      const addStockItem = () => {
            setStockItems([...stockItems, { id: stockItems.length + 1, category: null, productName: null, unit: null, price: "", quantity: "", alertThreshold: "" }]);
      };

      const handleInputChange = (index, field, value) => {
            const updatedItems = stockItems.map((item, i) => {
                  if (i === index) {
                        return { ...item, [field]: value };
                  }
                  return item;
            });
            setStockItems(updatedItems);
      };

      // console.log("stockItems", stockItems)

      const removeStockItem = (index) => {
            const updatedItems = stockItems.filter((_, i) => i !== index);
            setStockItems(updatedItems);
      };

      const { error, showError, clearError } = useError();

      // Fetch units for different categories
      // const lengthUnits = convert().possibilities('length'); // For length units
      const massUnits = convert().possibilities('mass'); // For mass units
      const volumeUnits = convert().possibilities('volume'); // For volume units

      const unitOptions = [
            ...massUnits.map((unit) => ({
                  // label: `${unit} (${unit})`,
                  label: `${unit} (${unitFullForms[unit] || unit})`,
                  value: unit
            })),
            ...volumeUnits
                  .filter((unit) => unit === 'ml' || unit === 'l') // Include only 'ml' and 'l' for milliliters and liters
                  .map((unit) => ({
                        label: `${unit} (${unitFullForms[unit] || unit})`,
                        value: unit
                  })),
            { label: `no (${unitFullForms["no"]})`, value: "number" } // Add number as a custom unit
      ];

      console.log("unitOptions" ,unitOptions)


      function handleSubmit(event) {
            event.preventDefault();
            clearError(null);


            // if (stockItems.length === 0) {
            //       showError("Please add at least one stock item.");
            //     return;
            // }

            for (let i = 0; i < stockItems.length; i++) {
                  const item = stockItems[i];

                  // console.log("item?.branchId" ,item?.branchId)
                  if (item?.branchId === (null || undefined)) {
                        showError("Please select a branch.");
                        return;
                  }
                  // Validate category
                  if (item.category === null) {
                        showError(`Please select a category.`);
                        return;
                  }

                  // Validate product
                  if (item.productName === null) {
                        showError(`Please select a product.`);
                        return;
                  }

                  // Validate unit
                  if (item.unit === null) {
                        showError(`Please select a unit.`);
                        return;
                  }


                  // Validate alert threshold
                  if (item.alertThreshold === "") {
                        showError(`Please enter a valid alert threshold.`);
                        return;
                  }
            }

            const removeDuplicates = (stockItems) => {
                  const seen = new Set();

                  return stockItems.filter(item => {
                        const key = `${item.branchId.value}_${item.category.value}_${item.productName.value}`;
                        if (seen.has(key)) {
                              return false; // Duplicate found, skip it
                        } else {
                              seen.add(key); // Mark as seen
                              return true;   // Unique, keep it
                        }
                  });
            };

            const filteredData = removeDuplicates(stockItems);

            // console.log("filteredData", filteredData)
            const requestData = {
                  stock: filteredData.map((item) => ({
                        inv_category_id: item?.category?.value,
                        inv_product_id: item?.productName?.value,
                        branch_id: item?.branchId?.value,
                        // branch_id: item?.branchId?.value === "all" ? filteredOptions?.map((main) => main?.value) : [item?.branchId?.value],
                        inv_product_name: item?.productName?.label,
                        unit: item?.unit?.value,
                        // price: item.price,
                        // quantity: item.quantity,
                        alert_threshold_qty: item.alertThreshold,
                  })),
            };

            // console.log("requestData", requestData);
            // return

            // Submit the form
            submitForm("/groupadmin/branch_stock/addProduct", requestData, history, showError, clearError, "Prodcut has been added successfully.");

            // Handle any errors after form submission
            // if (error) {
            //       // showError("An error occurred while submitting the form. Please try again.");
            //       //     $(".loader").hide();
            // }
      }




      return (
            <React.Fragment>
                  <ToastContainer
                        toastStyle={{
                              border: "1px solid black",
                              fontSize: "17px",
                        }}
                  />
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                {/* <div style={{height : windowHeight}}  id="kt_content_container" className="container-xxl"> */}
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Stock Management
                                                                              </h1>
                                                                              {/* <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                                                                              <Select
                                                                                    className="search-line mx-3"
                                                                                    placeholder="All Locations"
                                                                                    value={BranchId}
                                                                                    options={[
                                                                                          {
                                                                                                label: "All Locations",
                                                                                                value: "all",
                                                                                          },
                                                                                          ...filteredOptions,
                                                                                    ]}
                                                                                    onChange={(opt) => {
                                                                                          setBranchID(opt);
                                                                                    }}
                                                                                    styles={{
                                                                                          control: (provided) => ({
                                                                                                ...provided,
                                                                                                borderRadius: "8px",
                                                                                                height: "30px",
                                                                                                marginTop: "5px"
                                                                                          }),
                                                                                          menu: (provided) => ({
                                                                                                ...provided,
                                                                                                zIndex: 9999
                                                                                          })
                                                                                    }}
                                                                              /> */}
                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">

                                                                  </div>
                                                            </div>

                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="card card-docs">
                                                                  <form onSubmit={handleSubmit}>
                                                                        <div className="card-body fs-6 text-gray-700">
                                                                              {/* <div style={{ maxHeight : 350 ,overflowX : "hidden" ,overflowY  : "auto"}}> */}
                                                                              {stockItems.map((item, index) => (
                                                                                    <div>
                                                                                    <div className="row my-3" key={item.id}>
                                                                                          <div className="col-md-2">
                                                                                                <label className="required form-label">Branch</label>
                                                                                                <Select
                                                                                                      placeholder="Select Branch"
                                                                                                      value={item.branchId}
                                                                                                      options={filteredOptions}
                                                                                                      onChange={(opt) => {
                                                                                                            handleInputChange(index, "branchId", opt)
                                                                                                      }}
                                                                                                      styles={commonSelectStyles}
                                                                                                />
                                                                                          </div>

                                                                                          <div className="col-md-2">
                                                                                                <label className="required form-label">Category</label>
                                                                                                <Select
                                                                                                      className="search-line"
                                                                                                      placeholder="Select Category"
                                                                                                      value={item.category}
                                                                                                      menuPosition="fixed"   // Fixes the position of the menu to the viewport
                                                                                                      menuPlacement="auto"
                                                                                                      options={inventoryproductList_?.
                                                                                                            inventoryproductList?.
                                                                                                            filter((main) => main?.is_active && (main?.products?.length > 0 && main?.is_active))?.map((main1) => ({
                                                                                                                  label: main1?.category_name,
                                                                                                                  value: main1?._id,
                                                                                                                  products: main1.products
                                                                                                            }))}
                                                                                                      styles={commonSelectStyles}
                                                                                                      onChange={(opt) => {
                                                                                                            handleInputChange(index, "category", opt)
                                                                                                      }
                                                                                                      }
                                                                                                />
                                                                                          </div>
                                                                                          <div className="col-md-2">
                                                                                                <label className="required form-label">Product Name</label>
                                                                                                <Select
                                                                                                      className="search-line"
                                                                                                      placeholder="Select Product"
                                                                                                      value={item.productName}
                                                                                                      options={item?.category?.products?.filter((main) => main?.is_active)?.map((main1) => ({
                                                                                                            label: main1?.name,
                                                                                                            value: main1?._id,
                                                                                                      }))}
                                                                                                      styles={commonSelectStyles}
                                                                                                      onChange={(opt) => handleInputChange(index, "productName", opt)}
                                                                                                />
                                                                                          </div>
                                                                                          <div className="col-md-2">
                                                                                                <label className="required form-label">Select Unit</label>
                                                                                                <Select
                                                                                                      className="search-line"
                                                                                                      placeholder="Select Unit"
                                                                                                      value={item.unit}
                                                                                                      options={unitOptions}
                                                                                                      styles={commonSelectStyles}
                                                                                                      onChange={(opt) => handleInputChange(index, "unit", opt)}
                                                                                                />
                                                                                          </div>

                                                                                          <div className="col-md-2">
                                                                                                <label className="required form-label">Alert Threshold</label>
                                                                                                <div className="d-flex align-items-center">
                                                                                                      <input
                                                                                                            type="number"
                                                                                                            className="form-control form-control"
                                                                                                            placeholder="Alert Threshold"
                                                                                                            value={item.alertThreshold}
                                                                                                            onChange={(e) => {
                                                                                                                  const value = e.target.value;
                                                                                                                  if (value !== "0") {
                                                                                                                        handleInputChange(index, "alertThreshold", value);
                                                                                                                  }
                                                                                                            }}
                                                                                                      // onChange={(e) => handleInputChange(index, "alertThreshold", e.target.value)}
                                                                                                      />
                                                                                                      <span style={{ marginLeft: "-3rem", color: "#667085", fontSize: '14px', fontWeight: 400 }}
                                                                                                            className="">{item?.unit?.value === "number" ? "no" : item?.unit?.value || ""}
                                                                                                      </span>
                                                                                                </div>
                                                                                          </div>
                                                                                          <div className="col-md-1 mt-10">
                                                                                                {index > 0 && (
                                                                                                      <Delete24Regular
                                                                                                            onClick={() => removeStockItem(index)}
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                      />
                                                                                                )}
                                                                                          </div>
                                                                                    </div>
                                                                                    </div>
                                                                              ))}
                                                                              {/* </div> */}
                                                                              <br />
                                                                              <div className="row text-centre d-flex justify-content-center col-md-12">
                                                                                    <Button onClick={addStockItem} style={{
                                                                                          textTransform: "none", height: "45px",
                                                                                          border: '1px solid #D0D5DD', width: "163px",
                                                                                          background: "#F9FAFB",
                                                                                          color: '#344054', fontSize: '14.3px'
                                                                                    }} variant="outlined" startIcon={<AddCircle24Filled />}>
                                                                                          Add Product
                                                                                    </Button>
                                                                              </div>
                                                                              <br />
                                                                              <ErrorMessage error={error} />
                                                                              <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                                                                        </div>
                                                                  </form>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>


            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      menuList: state.menuList,
      BranchList_: state.branchList,
      inventoryproductList_: state
});

const mapDispatchToProps = (dispatch) => {
      return {
            getMenuList: () => dispatch(getMenuList()),
            getBranchList: () => dispatch(getBranchList()),
            getInventoryProList: () => dispatch(getInventoryProList()),

      };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStock);

