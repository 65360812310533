import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useStyles } from "../../Comman/TableuseStyles";
import { Tableoptions } from "../../Comman/TableOption";
import { getCouponList } from "../../../Store";
import { connect } from "react-redux";
import useDrawer from "../../../context/DrawerContext";
import { deleteCommonFunction } from "../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { generateTableActions } from "../../Comman/TableActions";
import Addnewdetails from "../../Comman/AddNewDetails/Addnewdetails";
import { CouponsPermissions, userPermissionType } from "../../Comman/UserPermission";

function GroupAdminCouponManagement({ getCouponList, couponList }) {
  let history = useHistory();

  useEffect(() => {
    getCouponList();
  }, []);

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  function deleteCoupon(_id) {
    deleteCommonFunction(`/groupadmin/coupon/${_id}`, "Coupon has been successfully deleted.", () => getCouponList());
  }

  
  const classes = useStyles();

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Branch name",
      render: (rowData) => {
        return <>{rowData.branch?.branch_name_EN}</>;
      },
    },
    {
      title: "code",
      field: "code",
    },
    {
      title: "Discount type",
      field: "discount_type",
      render: (rowData) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{rowData.discount_type}</div>
        );
      },
    },
    {
      title: "Discount value",
      field: "discount_value",
    },

    {
      title: "Apply coupon type",
      render: (rowData) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{rowData.apply_coupon_type}</div>
        );
      },
    },
    {
      title: "Order type",
      render: (rowData) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{rowData.order_type}</div>
        );
      },
    },

  ];

  const userPermissionType_ = userPermissionType === "groupadmin"


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>
                    <div className="row g-5 g-xl-8">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                        <div className="d-flex flex-stack mb-7">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h1
                              className="anchor fw-bolder"
                              id="custom-form-control"
                            >
                              Coupon Management
                            </h1>
                          </div>
                          <div className="d-flex flex-stack">
                            <div
                              className="d-flex justify-content-end mx-2"
                              data-kt-docs-table-toolbar="base"
                            >
                              
                              {(userPermissionType_ || CouponsPermissions?.access?.includes("create")) && (
                                <Addnewdetails
                                url={"/group_admin/coupon_voucher_management/coupon_management/add_coupon"}
                                Name="Add Coupon" />                              )}
                            </div>
                          </div>
                        </div>
                        <div className="my-5">
                          <div className={classes.root}>
                            <MaterialTable
                              title=""
                              data={couponList}
                              columns={columns}
                              options={Tableoptions}
                              // actions={[
                              //   {
                              //     icon: () => <VisibilityIcon />,
                              //     tooltip: "View Details",
                              //     onClick: (event, rowData) =>
                              //       history.push(
                              //         `/group_admin/coupon_voucher_management/coupon_management/view_coupon/${rowData._id}`
                              //       ),
                              //   },
                              //   {
                              //     icon: "edit",
                              //     tooltip: "Edit Coupon",
                              //     onClick: (event, rowData) =>
                              //       history.push(
                              //         `/group_admin/coupon_voucher_management/coupon_management/edit_coupon/${rowData._id}`
                              //       ),
                              //   },
                              //   {
                              //     icon: "delete",
                              //     tooltip: "Delete Coupon",
                              //     onClick: (event, rowData) =>
                              //       deleteCoupon(rowData._id),
                              //   },
                              // ]}
                              actions={generateTableActions(history, getCouponList, {
                                viewPath: "group_admin/coupon_voucher_management/coupon_management/view_coupon",
                                editPath: "group_admin/coupon_voucher_management/coupon_management/edit_coupon",
                                deletePath: "groupadmin/coupon"
                              }, {
                                viewTooltip: "View Coupon",
                                editTooltip: "Edit Coupon",
                                deleteTooltip: "Delete Coupon"
                              }, {
                                deleteMessage: "Coupon has been successfully deleted."
                              },{
                                access: CouponsPermissions,
                              }
                              
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  couponList: state.couponList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCouponList: () => dispatch(getCouponList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminCouponManagement);


