import React, { useEffect, useMemo, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import Map from "./Map";
import NoteState from "../CommanBranch/NoteState";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import $ from "jquery";
import { useStyles } from "../../../../Comman/TableuseStyles";
import { useRef } from "react";
import { Tableoptions } from "../../../../Comman/TableOption";
import headers from "../../../../../CommonApi/headers";
import { useMediaQuery } from "react-responsive";
import CommonDelivery from "./CommonDelivery";
import useDrawer from "../../../../../context/DrawerContext";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import { googleMapsApiKey } from "../../../../../CommonApi/googelApiKey";
window.jquery = window.$ = $;

function GroupAdminDeliveryZones(props) {


  const { branchID, branchData } = props;

  const currencyLabel = getCurrencyLabel(branchData && branchData?.currency);

  const scrollRef = useRef();

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const isTablet = useMediaQuery({ minWidth: 761, maxWidth: 988 });

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ bihavior: "smooth" });
    // window.scrollTo({ behavior: "smooth", top: scrollRef.current.offsetTop });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  $(document).ready(function () {
    $(".filterme").keypress(function (eve) {
      if (
        ((eve.which != 46 || $(this).val().indexOf(".") != -1) &&
          (eve.which < 48 || eve.which > 57)) ||
        (eve.which == 46 && $(this).caret().start == 0)
      ) {
        eve.preventDefault();
      }
      $(".filterme").keyup(function (eve) {
        if ($(this).val().indexOf(".") == 0) {
          $(this).val($(this).val().substring(1));
        }
      });
    });
  });

  $("#abcd").on("change, keyup", function () {
    var currentInput = $(this).val();
    var fixedInput = currentInput.replace(/[A-Za-z!@#$%^&*()]/g, "");
    $(this).val(fixedInput);
  });

  $("#Editabcd").on("change, keyup", function () {
    var currentInput = $(this).val();
    var fixedInput = currentInput.replace(/[A-Za-z!@#$%^&*()]/g, "");
    $(this).val(fixedInput);
  });

  useEffect(() => {
    groupAdminuserZoneList();
    groupAdminuserZoneListByZip();
  }, [branchID]);

  ///////////////////////// Branch List //////////////////////////

  // const google = window.google;

  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  var url2 = pathnamesplits[4];

  /////////////////////////////////   Add Zone ///////////////////////////////

  const [zone_radius1, setzone_radius1] = useState("");
  const [zone_radius2, setzone_radius2] = useState("");
  const [zone_radius3, setzone_radius3] = useState("");
  const [zone_radius4, setzone_radius4] = useState("");
  const [zone_radius5, setzone_radius5] = useState("");
  const [zone_radius6, setzone_radius6] = useState("");
  const [zone_zip, setzone_zip] = useState("");
  const [min_order_qty1, setmin_order_qty1] = useState("");
  const [min_order_qty2, setmin_order_qty2] = useState("");
  const [min_order_qty3, setmin_order_qty3] = useState("");
  const [min_order_qty4, setmin_order_qty4] = useState("");
  const [min_order_qty5, setmin_order_qty5] = useState("");
  const [min_order_qty6, setmin_order_qty6] = useState("");
  const [min_order_qtyzip, setmin_order_qtyzip] = useState("");
  const [delivery_cost1, setdelivery_cost1] = useState("");
  const [delivery_cost2, setdelivery_cost2] = useState("");
  const [delivery_cost3, setdelivery_cost3] = useState("");
  const [delivery_cost4, setdelivery_cost4] = useState("");
  const [delivery_cost5, setdelivery_cost5] = useState("");
  const [delivery_cost6, setdelivery_cost6] = useState("");
  const [delivery_costzip, setdelivery_costzip] = useState("");
  const [no_delivery_cost_from1, setno_delivery_cost_from1] = useState("");
  const [no_delivery_cost_from2, setno_delivery_cost_from2] = useState("");
  const [no_delivery_cost_from3, setno_delivery_cost_from3] = useState("");
  const [no_delivery_cost_from4, setno_delivery_cost_from4] = useState("");
  const [no_delivery_cost_from5, setno_delivery_cost_from5] = useState("");
  const [no_delivery_cost_from6, setno_delivery_cost_from6] = useState("");
  const [no_delivery_cost_fromzip, setno_delivery_cost_fromzip] = useState("");
  const [zone_nozip, setzone_nozip] = useState("");

  const [errorradius, setErrorradius] = useState(null);
  const errorDivradius = errorradius ? (
    <div className="text-center alert alert-danger">{errorradius}</div>
  ) : (
    ""
  );

  const [errorzipradio, setErrorzipradio] = useState(null);
  const errorDivzipradio = errorzipradio ? (
    <div className="text-center alert alert-danger">{errorzipradio}</div>
  ) : (
    ""
  );

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [error2, setError2] = useState(null);
  const errorDiv2 = error2 ? (
    <div className="text-center alert alert-danger">{error2}</div>
  ) : (
    ""
  );

  const [error3, setError3] = useState(null);
  const errorDiv3 = error3 ? (
    <div className="text-center alert alert-danger">{error3}</div>
  ) : (
    ""
  );

  const [error4, setError4] = useState(null);
  const errorDiv4 = error4 ? (
    <div className="text-center alert alert-danger">{error4}</div>
  ) : (
    ""
  );

  const [error5, setError5] = useState(null);
  const errorDiv5 = error5 ? (
    <div className="text-center alert alert-danger">{error5}</div>
  ) : (
    ""
  );

  const [error6, setError6] = useState(null);
  const errorDiv6 = error6 ? (
    <div className="text-center alert alert-danger">{error6}</div>
  ) : (
    ""
  );

  const [errorzip, setErrorzip] = useState(null);
  const errorDivzip = errorzip ? (
    <div className="text-center alert alert-danger">{errorzip}</div>
  ) : (
    ""
  );

  ///////////////////////// Zone List //////////////////////////

  const [userZone, setuserZone] = useState([]);

  // useEffect(() => {
  //   // groupAdminuserZoneList();
  // }, []);

  const [xyz1, setxyz1] = useState("");
  const [xyz2, setxyz2] = useState("");
  const [xyz3, setxyz3] = useState("");
  const [xyz4, setxyz4] = useState("");
  const [xyz5, setxyz5] = useState("");
  const [xyz6, setxyz6] = useState("");

  // const [xyzRadiosOrZip, setxyzRadiosOrZip] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        id: xyz1.zone_no ? xyz1.zone_no : "",
        name: "Park Slope",
        latitude: xyz1.branch_lat ? xyz1.branch_lat.toString() : "",
        longitude: xyz1.branch_long ? xyz1.branch_long.toString() : "",
        circle: {
          radius: xyz1.zone_radius ? xyz1.zone_radius : 0,
          options: {
            strokeColor: "#FF0000",
          },
        },
      },
      {
        id: xyz2.zone_no ? xyz2.zone_no : "",
        name: "Park Slope",
        latitude: xyz2.branch_lat ? xyz2.branch_lat.toString() : "",
        longitude: xyz2.branch_long ? xyz2.branch_long.toString() : "",
        circle: {
          radius: xyz2.zone_radius ? xyz2.zone_radius : 0,
          options: {
            strokeColor: "#FF0000",
          },
        },
      },
      {
        id: xyz3.zone_no ? xyz3.zone_no : "",
        name: "Park Slope",
        latitude: xyz3.branch_lat ? xyz3.branch_lat.toString() : "",
        longitude: xyz3.branch_long ? xyz3.branch_long.toString() : "",
        circle: {
          radius: xyz3.zone_radius ? xyz3.zone_radius : 0,
          options: {
            strokeColor: "#FF0000",
          },
        },
      },
      {
        id: xyz4.zone_no ? xyz4.zone_no : "",
        name: "Park Slope",
        latitude: xyz4.branch_lat ? xyz4.branch_lat.toString() : "",
        longitude: xyz4.branch_long ? xyz4.branch_long.toString() : "",
        circle: {
          radius: xyz4.zone_radius ? xyz4.zone_radius : 0,
          options: {
            strokeColor: "#FF0000",
          },
        },
      },
      {
        id: xyz5.zone_no ? xyz5.zone_no : "",
        name: "Park Slope",
        latitude: xyz5.branch_lat ? xyz5.branch_lat.toString() : "",
        longitude: xyz5.branch_long ? xyz5.branch_long.toString() : "",
        circle: {
          radius: xyz5.zone_radius ? xyz5.zone_radius : 0,
          options: {
            strokeColor: "#FF0000",
          },
        },
      },
      {
        id: xyz6.zone_no ? xyz6.zone_no : "",
        name: "Park Slope",
        latitude: xyz6.branch_lat ? xyz6.branch_lat.toString() : "",
        longitude: xyz6.branch_long ? xyz6.branch_long.toString() : "",
        circle: {
          radius: xyz6.zone_radius ? xyz6.zone_radius : 0,
          options: {
            strokeColor: "#FF0000",
          },
        },
      },
    ]);
  }, [xyz1, xyz2, xyz3, xyz4, xyz5, xyz6]);

  const [isradius, setradius] = useState(false);
  const [iszip, setzip] = useState(false);

  function groupAdminuserZoneList() {
    setxyz1("");
    setxyz2("");
    setxyz3("");
    setxyz4("");
    setxyz5("");
    setxyz6("");
    // setData([]);
    api
      .get(`/groupadmin/settings/zone/${branchID}?type=${"radius"}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setuserZone(tableData);

        if (tableData) {
          for (let index = 0; index < tableData.length; index++) {
            const element = tableData[index];
            if (element.zone_no == "1") {
              setxyz1(element);
            } else if (element.zone_no == "2") {
              setxyz2(element);
            } else if (element.zone_no == "3") {
              setxyz3(element);
            } else if (element.zone_no == "4") {
              setxyz4(element);
            } else if (element.zone_no == "5") {
              setxyz5(element);
            } else if (element.zone_no == "6") {
              setxyz6(element);
            } else {
              // console.log(element)
              // custom.push({
              //     isChecked: true,
              //     name: element
              // })
            }
            // if (element?.branch?.delivery_zone_type === "zip") {
            //   setzip(true);

            // }
            if (element?.branch?.delivery_zone_type === "radius") {
              setradius(true);
              // console.log(
              //   "SSSSS",
              //   element?.branch?.delivery_zone_type === "radius"
              // );
            } else {
              setradius(false);
            }
            // if (element?.branch?.delivery_zone_type === "zip") {
            //   setzip(true);
            //   // console.log(
            //   //   "SSSSS",
            //   //   element?.branch?.delivery_zone_type === "radius"
            //   // );
            // } else {
            //   setzip(false);
            // }
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err?.response?.data?.error?.message) {
            setxyz1("");
            setxyz2("");
            setxyz3("");
            setxyz4("");
            setxyz5("");
            setxyz6("");
          }
        }
      });
  }

  function DeliveryZoneByRadius(event) {
    setErrorradius(null);

    if (userZone?.length === 0) {
      Swal.fire({
        position: "center-center",
        icon: "error",
        title: "Please add atleast one zone.",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      if (branchID.length === 0) {
        setErrorradius("Please Select Branch");
      } else {
        let item = {
          type: isradius == true ? "" : "radius",
        };

        api
          .post(`/groupadmin/settings/zone/${branchID}`, item, { headers })
          .then((res) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title:
                isradius == true
                  ? "Delivery zone by radius removed successfully"
                  : "Delivery zone by radius saved successfull",
              showConfirmButton: false,
              timer: 1500,
            });
            groupAdminuserZoneList();
            groupAdminuserZoneListByZip();
            // window.location.reload(true);
          })
          .catch((err) => {
            if (err.response) {
              setErrorradius(err.response.data.error.message);
            }
          });
      }
    }
  }

  function DeliveryZoneByZip(event) {
    setErrorzipradio(null);

    if (ADD?.length === 0) {
      Swal.fire({
        position: "center-center",
        icon: "error",
        title: "Please add atleast one zip.",
        showConfirmButton: false,
        timer: 2500,
      });
    } else {
      if (branchID.length === 0) {
        setErrorzipradio("Please Select Branch");
      } else {
        let item = {
          type: iszip == true ? "" : "zip",
        };

        api
          .post(`/groupadmin/settings/zone/${branchID}`, item, { headers })
          .then((res) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title:
                iszip == true
                  ? "Delivery Zone By Zip Removed"
                  : "Delivery Zone By Zip Saved",
              showConfirmButton: false,
              timer: 1500,
            });
            groupAdminuserZoneList();
            groupAdminuserZoneListByZip();
            // window.location.reload(true);
          })
          .catch((err) => {
            if (err.response) {
              setErrorzipradio(err.response.data.error.message);
            }
          });
      }
    }
  }

  ///////////////////////// Zone List //////////////////////////

  function handalAddZone1(event) {
    event.preventDefault();
    setError(null);

    if (branchID.length === 0) {
      setError("Please Select Branch");
    }
    // else if (min_order_qty1.toString().trim().length < 0 || min_order_qty1.toString().trim().length < 3) {
    //     setError('Manager Name Must Be at least 3 character Long');
    // }
    // else if (zone_radius1.toString().trim().length < 0 || zone_radius1.toString().trim().length < 3) {
    //     setError('zone_radius1 Name Must Be at least 3 character Long');
    // }
    // else if (delivery_cost1.toString().trim().length < 0 || delivery_cost1.toString().trim().length < 3) {
    //     setError('delivery_cost1 Name Must Be at least 3 character Long');
    // }
    // else if (no_delivery_cost_from1.toString().trim().length < 0 || no_delivery_cost_from1.toString().trim().length < 3) {
    //     setError('no_delivery_cost_from1 Name Must Be at least 3 character Long');
    // }
    else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        zone_radius: Number(zone_radius1),
        min_order_qty: Number(min_order_qty1),
        delivery_cost: Number(delivery_cost1),
        no_delivery_cost_from: Number(no_delivery_cost_from1),
        zone_no: 1,
        type: "radius",
      };
      // return

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          setzone_radius1("");
          setmin_order_qty1("");
          setdelivery_cost1("");
          setno_delivery_cost_from1("");
          groupAdminuserZoneList();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response.data.error.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  function EdithandalAddZone1(event) {
    event.preventDefault();
    setError(null);

    if (branchID.length === 0) {
      setError("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(xyz1.min_order_qty),
        zone_radius: Number(xyz1.zone_radius),
        delivery_cost: Number(xyz1.delivery_cost),
        no_delivery_cost_from: Number(xyz1.no_delivery_cost_from),
        zone_no: 1,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response.data.error.message);
          } else if (err.request) {
            setError(err.request);
          } else {
            setError(err.message);
          }
        });
    }
  }

  function handalAddZone2(event) {
    event.preventDefault();
    setError2(null);

    if (branchID.length === 0) {
      setError2("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(min_order_qty2),
        zone_radius: Number(zone_radius2),
        delivery_cost: Number(delivery_cost2),
        no_delivery_cost_from: Number(no_delivery_cost_from2),
        zone_no: 2,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setzone_radius2("");
          setmin_order_qty2("");
          setdelivery_cost2("");
          setno_delivery_cost_from2("");
          groupAdminuserZoneList();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError2(err.response.data.error.message);
          } else if (err.request) {
            setError2(err.request);
          } else {
            setError2(err.message);
          }
        });
    }
  }

  function EdithandalAddZone2(event) {
    event.preventDefault();
    setError2(null);

    if (branchID.length === 0) {
      setError2("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(xyz2.min_order_qty),
        zone_radius: Number(xyz2.zone_radius),
        delivery_cost: Number(xyz2.delivery_cost),
        no_delivery_cost_from: Number(xyz2.no_delivery_cost_from),
        zone_no: 2,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError2(err.response.data.error.message);
          } else if (err.request) {
            setError2(err.request);
          } else {
            setError2(err.message);
          }
        });
    }
  }

  function handalAddZone3(event) {
    event.preventDefault();
    setError3(null);

    if (branchID.length === 0) {
      setError3("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(min_order_qty3),
        zone_radius: Number(zone_radius3),
        delivery_cost: Number(delivery_cost3),
        no_delivery_cost_from: Number(no_delivery_cost_from3),
        zone_no: 3,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setzone_radius3("");
          setmin_order_qty3("");
          setdelivery_cost3("");
          setno_delivery_cost_from3("");
          groupAdminuserZoneList();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError3(err.response.data.error.message);
          } else if (err.request) {
            setError3(err.request);
          } else {
            setError3(err.message);
          }
        });
    }
  }

  function EdithandalAddZone3(event) {
    event.preventDefault();
    setError3(null);

    if (branchID.length === 0) {
      setError3("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(xyz3.min_order_qty),
        zone_radius: Number(xyz3.zone_radius),
        delivery_cost: Number(xyz3.delivery_cost),
        no_delivery_cost_from: Number(xyz3.no_delivery_cost_from),
        zone_no: 3,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError3(err.response.data.error.message);
          } else if (err.request) {
            setError3(err.request);
          } else {
            setError3(err.message);
          }
        });
    }
  }

  function handalAddZone4(event) {
    event.preventDefault();
    setError4(null);

    if (branchID.length === 0) {
      setError4("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(min_order_qty4),
        zone_radius: Number(zone_radius4),
        delivery_cost: Number(delivery_cost4),
        no_delivery_cost_from: Number(no_delivery_cost_from4),
        zone_no: 4,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setzone_radius4("");
          setmin_order_qty4("");
          setdelivery_cost4("");
          setno_delivery_cost_from4("");
          groupAdminuserZoneList();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError4(err.response.data.error.message);
          } else if (err.request) {
            setError4(err.request);
          } else {
            setError4(err.message);
          }
        });
    }
  }

  function EdithandalAddZone4(event) {
    event.preventDefault();
    setError4(null);

    if (branchID.length === 0) {
      setError4("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(xyz4.min_order_qty),
        zone_radius: Number(xyz4.zone_radius),
        delivery_cost: Number(xyz4.delivery_cost),
        no_delivery_cost_from: Number(xyz4.no_delivery_cost_from),
        zone_no: 4,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });

          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError4(err.response.data.error.message);
          } else if (err.request) {
            setError4(err.request);
          } else {
            setError4(err.message);
          }
        });
    }
  }

  function handalAddZone5(event) {
    event.preventDefault();
    setError5(null);

    if (branchID.length === 0) {
      setError5("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(min_order_qty5),
        zone_radius: Number(zone_radius5),
        delivery_cost: Number(delivery_cost5),
        no_delivery_cost_from: Number(no_delivery_cost_from5),
        zone_no: 5,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setzone_radius5("");
          setmin_order_qty5("");
          setdelivery_cost5("");
          setno_delivery_cost_from5("");
          groupAdminuserZoneList();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError5(err.response.data.error.message);
          } else if (err.request) {
            setError5(err.request);
          } else {
            setError5(err.message);
          }
        });
    }
  }

  function EdithandalAddZone5(event) {
    event.preventDefault();
    setError5(null);

    if (branchID.length === 0) {
      setError5("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(xyz5.min_order_qty),
        zone_radius: Number(xyz5.zone_radius),
        delivery_cost: Number(xyz5.delivery_cost),
        no_delivery_cost_from: Number(xyz5.no_delivery_cost_from),
        zone_no: 5,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError5(err.response.data.error.message);
          } else if (err.request) {
            setError5(err.request);
          } else {
            setError5(err.message);
          }
        });
    }
  }

  function handalAddZone6(event) {
    event.preventDefault();
    setError6(null);

    if (branchID.length === 0) {
      setError6("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(min_order_qty6),
        zone_radius: Number(zone_radius6),
        delivery_cost: Number(delivery_cost6),
        no_delivery_cost_from: Number(no_delivery_cost_from6),
        zone_no: 6,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setzone_radius6("");
          setmin_order_qty6("");
          setdelivery_cost6("");
          setno_delivery_cost_from6("");
          groupAdminuserZoneList();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError6(err.response.data.error.message);
          } else if (err.request) {
            setError6(err.request);
          } else {
            setError6(err.message);
          }
        });
    }
  }

  function EdithandalAddZone6(event) {
    event.preventDefault();
    setError6(null);

    if (branchID.length === 0) {
      setError6("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(xyz6.min_order_qty),
        zone_radius: Number(xyz6.zone_radius),
        delivery_cost: Number(xyz6.delivery_cost),
        no_delivery_cost_from: Number(xyz6.no_delivery_cost_from),
        zone_no: 6,
        type: "radius",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError6(err.response.data.error.message);
          } else if (err.request) {
            setError6(err.request);
          } else {
            setError6(err.message);
          }
        });
    }
  }

  /////////////////////////// Delete Zone ////////////////////////

  function deleteZone(_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/groupadmin/settings/zone/${_id}`, { headers })
          .then((res) => {
            // groupAdminuserZoneList();
            // groupAdminuserZoneListByZip();
            // if (userZone?.length === 0) {
            //   setradius(false);
            // }
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Data has been successfully deleted.",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 1500);
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response.data.error.message);
            }
          });
      }
    });
  }

  //////////////////////// Delete Zone ///////////////////////////////////

  ////////////////////////// Zip By Zone /////////////////////

  const [ADD, setADD] = useState([]);
  const [SelectedZip, setSelectedZip] = useState([]);

  function groupAdminuserZoneListByZip() {
    api
      .get(`/groupadmin/settings/zone/${branchID}?type=${"zip"}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setzip(
          tableData[0]?.branch?.delivery_zone_type === "zip" ? true : false
        );
        let arr = [];
        for (let index = 0; index < tableData.length; index++) {
          const element = tableData[index];
          arr.push(element.zone_no);
        }
        setSelectedZip(arr);
        setADD(tableData);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.error.message) {
            setADD([]);
          }
        }
      });
  }

  function handalAddZoneZip(event) {
    event.preventDefault();
    setErrorzip(null);

    if (branchID.length === 0) {
      setErrorzip("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(min_order_qtyzip),
        zone_zip: String(zone_zip),
        delivery_cost: Number(delivery_costzip),
        no_delivery_cost_from: Number(no_delivery_cost_fromzip),
        zone_no: Number(zone_nozip),
        type: "zip",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been save",
            showConfirmButton: false,
            timer: 1500,
          });
          groupAdminuserZoneListByZip();
          setzone_zip("");
          setno_delivery_cost_fromzip("");
          setdelivery_costzip("");
          setdelivery_costzip("");
          setmin_order_qtyzip("");
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setErrorzip(err.response.data.error.message);
          } else if (err.request) {
            setErrorzip(err.request);
          } else {
            setErrorzip(err.message);
          }
        });
    }
  }

  const classes = useStyles();

  // if (!ADD || !ADD.length) {
  //     ADD.forEach((ADD, index) => { ADD.serial = index + 1; });
  // } else {

  // }
  const columns = [
    // {
    //     title: 'Sr.No.', field: 'serial',
    // },
    {
      title: "Zone No",
      field: "zone_no",
    },
    {
      title: "Zip Code",
      field: "zone_zip",
    },
    {
      title: `Min. Order Quantity (In ${currencyLabel})`,
      field: "min_order_qty",
    },
    {
      title: `Delivery Costs (In ${currencyLabel})`,
      field: "delivery_cost",
    },
    {
      title: `No Delivery Cost From (In ${currencyLabel})`,
      field: "no_delivery_cost_from",
    },

    {
      title: "Type",
      field: "type",
    },
  ];

  function deleteAdditionCharges(_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/groupadmin/settings/zone/${_id}`, {
            headers: headers,
          })
          .then((result) => {
            Swal.fire({
              position: "center-center",
              icon: "success",
              title: "Data has been successfully deleted.",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 1500);

            // groupAdminuserZoneList();
            // groupAdminuserZoneListByZip();
            // setTimeout(() => {
            //   if (ADD?.length === 0) {
            //     setzip(false);
            //   }
            // }, 1200);
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response.data.error.message);
            }
          });
      }
    });
  }

  //////////////////////////  Zip By Zone /////////////////////

  //////////////////////////////   Zone Edit  Form Submit Poppup //////////////////////////////////
  const [Last, setlast] = useState("");
  function EditZoneByZip(data) {
    setlast(data);
  }

  function EdithandleSubmitZoneByZip(event) {
    event.preventDefault();
    setError(null);

    if (branchID.length === 0) {
      setError6("Please Select Branch");
    } else {
      let item = {
        branch_id: branchID,
        branch_lat: branchData.branch_lat,
        branch_long: branchData.branch_long,
        min_order_qty: Number(Last.min_order_qty),
        zone_zip: String(Last.zone_zip),
        delivery_cost: Number(Last.delivery_cost),
        no_delivery_cost_from: Number(Last.no_delivery_cost_from),
        zone_no: Number(Last.zone_no),
        type: "zip",
      };

      api
        .post(`/groupadmin/settings/zone`, item, { headers })
        .then((res) => {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: "Your work has been saved successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // $('#kt_modal_1').hide();
          groupAdminuserZoneListByZip();
          // window.location.reload(true);
        })
        .catch((err) => {
          if (err.response) {
            setError6(err.response.data.error.message);
          } else if (err.request) {
            setError6(err.request);
          } else {
            setError6(err.message);
          }
        });
    }
  }

  const map = useMemo(() => {
    return (
      <Map
        center={{
          lat: branchData.branch_lat ? branchData.branch_lat : 22.9869754,
          lng: branchData.branch_long ? branchData.branch_long : 72.4948776,
        }}
        zoom={13}
        places={data}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `600px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }, [data]);
  //////////////////////////////   Zone Edit  Form Submit Poppup //////////////////////////////////

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-2"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-2 d-inline"
                          id="custom-form-control"
                        >
                          Delivery - Zone
                        </h3>
                      </div>

                      <div
                        className="col-md-3 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <NoteState />
                      </div>
                    </div>
                    <div className="rounded border p-5">
                      <CommonDelivery />

                      <div
                        className="tab-content"
                        id="myTabContent"
                        ref={scrollRef}
                      >
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div
                                className={
                                  isMobile
                                    ? "card-body fs-6 py-15 px-5 py-lg-15 text-gray-700"
                                    : "card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700"
                                }
                              >
                                <div className="row">
                                  <div div className="col-md-12">
                                    <div
                                      className={
                                        isMobile ? "" : "rounded border p-10"
                                      }
                                    >
                                      <div className="col-md-12">{map}</div>
                                    </div>
                                    <br />
                                    <div className="col-md-12">
                                      <div
                                        className={
                                          isMobile ? "" : "rounded border p-10"
                                        }
                                      >
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={isradius}
                                            onClick={DeliveryZoneByRadius}
                                          />
                                          <label className="form-check-label mx-3">
                                            <h3>Delivery Zone By Radius</h3>
                                          </label>
                                        </div>
                                        <div className="col-md-12">
                                          {" "}
                                          {errorDivradius}{" "}
                                        </div>
                                        <div className="mb-10">
                                          <label className="form-label">
                                            GPS LAT
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            value={branchData.branch_lat}
                                            readOnly
                                          // onChange={(e) => setbranch_id(e.target.value)}
                                          />
                                        </div>
                                        <div className="mb-10">
                                          <label className="form-label">
                                            GPS LONG
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            value={branchData.branch_long}
                                            readOnly
                                          />
                                        </div>
                                        <div className="row">
                                          {xyz1 != "" ? (
                                            <div className="col-md-6">
                                              <form
                                                onSubmit={EdithandalAddZone1}
                                              >
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 1</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz1.zone_radius}
                                                      required
                                                      // onChange={(e) => setuserZone_(e)}
                                                      onChange={(e) => {
                                                        // xyz2.zone_radius = e.target.value
                                                        setxyz1({
                                                          ...xyz1,
                                                          zone_radius:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz1.min_order_qty}
                                                      required={
                                                        xyz1.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz1({
                                                          ...xyz1,
                                                          min_order_qty:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz1.delivery_cost}
                                                      required={
                                                        xyz1.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz1({
                                                          ...xyz1,
                                                          delivery_cost:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        xyz1.no_delivery_cost_from
                                                      }
                                                      required={
                                                        xyz1.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz1({
                                                          ...xyz1,
                                                          no_delivery_cost_from:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">

                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("edit"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}


                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("delete"))
                                                      )) && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) =>
                                                            deleteZone(xyz1._id)
                                                          }
                                                          className="btn btn-danger m-2"
                                                        >
                                                          {" "}
                                                          Delete{" "}
                                                        </button>)}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          ) : (
                                            <div className="col-md-6">
                                              <form onSubmit={handalAddZone1}>
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 1</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={zone_radius1}
                                                      onChange={(e) =>
                                                        setzone_radius1(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={min_order_qty1}
                                                      required={
                                                        zone_radius1
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setmin_order_qty1(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={delivery_cost1}
                                                      required={
                                                        zone_radius1
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setdelivery_cost1(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        no_delivery_cost_from1
                                                      }
                                                      required={
                                                        zone_radius1
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setno_delivery_cost_from1(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">

                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("create"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}

                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          )}

                                          {xyz2 != "" ? (
                                            <div className="col-md-6">
                                              <form
                                                onSubmit={EdithandalAddZone2}
                                              >
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 2</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz2.zone_radius}
                                                      required
                                                      onChange={(e) => {
                                                        setxyz2({
                                                          ...xyz2,
                                                          zone_radius:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz2.min_order_qty}
                                                      required={
                                                        xyz2.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz2({
                                                          ...xyz2,
                                                          min_order_qty:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz2.delivery_cost}
                                                      required={
                                                        xyz2.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz2({
                                                          ...xyz2,
                                                          delivery_cost:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        xyz2.no_delivery_cost_from
                                                      }
                                                      required={
                                                        xyz2.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz2({
                                                          ...xyz2,
                                                          no_delivery_cost_from:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv2}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("edit"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                        {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("delete"))
                                                      )) && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) =>
                                                            deleteZone(xyz2._id)
                                                          }
                                                          className="btn btn-danger m-2"
                                                        >
                                                          {" "}
                                                          Delete{" "}
                                                        </button>)}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          ) : (
                                            <div className="col-md-6">
                                              <form onSubmit={handalAddZone2}>
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 2</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={zone_radius2}
                                                      onChange={(e) =>
                                                        setzone_radius2(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={min_order_qty2}
                                                      required={
                                                        zone_radius2
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setmin_order_qty2(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={delivery_cost2}
                                                      required={
                                                        zone_radius2
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setdelivery_cost2(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        no_delivery_cost_from2
                                                      }
                                                      required={
                                                        zone_radius2
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setno_delivery_cost_from2(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv2}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("create"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                    {/* <button className="btn btn-danger m-2" onClick={() => history.goBack()}> Cancel </button> */}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          )}
                                        </div>

                                        <br />

                                        <div className="row">
                                          {xyz3 != "" ? (
                                            <div className="col-md-6">
                                              <form
                                                onSubmit={EdithandalAddZone3}
                                              >
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 3</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz3.zone_radius}
                                                      required
                                                      onChange={(e) => {
                                                        setxyz3({
                                                          ...xyz3,
                                                          zone_radius:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz3.min_order_qty}
                                                      required={
                                                        xyz3.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz3({
                                                          ...xyz3,
                                                          min_order_qty:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz3.delivery_cost}
                                                      required={
                                                        xyz3.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz3({
                                                          ...xyz3,
                                                          delivery_cost:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        xyz3.no_delivery_cost_from
                                                      }
                                                      required={
                                                        xyz3.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz3({
                                                          ...xyz3,
                                                          no_delivery_cost_from:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv3}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("edit"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                        {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("delete"))
                                                      )) && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) =>
                                                            deleteZone(xyz3._id)
                                                          }
                                                          className="btn btn-danger m-2"
                                                        >
                                                          {" "}
                                                          Delete{" "}
                                                        </button>)}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          ) : (
                                            <div className="col-md-6">
                                              <form onSubmit={handalAddZone3}>
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 3</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={zone_radius3}
                                                      required
                                                      onChange={(e) =>
                                                        setzone_radius3(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={min_order_qty3}
                                                      required={
                                                        zone_radius3
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setmin_order_qty3(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={delivery_cost3}
                                                      required={
                                                        zone_radius3
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setdelivery_cost3(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        no_delivery_cost_from3
                                                      }
                                                      required={
                                                        zone_radius3
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setno_delivery_cost_from3(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv3}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("create"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                    {/* <button className="btn btn-danger m-2" onClick={() => history.goBack()}> Cancel </button> */}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          )}

                                          {xyz4 != "" ? (
                                            <div className="col-md-6">
                                              <form
                                                onSubmit={EdithandalAddZone4}
                                              >
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 4</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz4.zone_radius}
                                                      required
                                                      onChange={(e) => {
                                                        setxyz4({
                                                          ...xyz4,
                                                          zone_radius:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz4.min_order_qty}
                                                      required={
                                                        xyz4.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz4({
                                                          ...xyz4,
                                                          min_order_qty:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz4.delivery_cost}
                                                      required={
                                                        xyz4.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz4({
                                                          ...xyz4,
                                                          delivery_cost:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        xyz4.no_delivery_cost_from
                                                      }
                                                      required={
                                                        xyz4.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz4({
                                                          ...xyz4,
                                                          no_delivery_cost_from:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv4}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("edit"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                        {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("delete"))
                                                      )) && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) =>
                                                            deleteZone(xyz4._id)
                                                          }
                                                          className="btn btn-danger m-2"
                                                        >
                                                          {" "}
                                                          Delete{" "}
                                                        </button>)}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          ) : (
                                            <div className="col-md-6">
                                              <form onSubmit={handalAddZone4}>
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 4</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={zone_radius4}
                                                      onChange={(e) =>
                                                        setzone_radius4(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={min_order_qty4}
                                                      required={
                                                        zone_radius4
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setmin_order_qty4(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={delivery_cost4}
                                                      required={
                                                        zone_radius4
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setdelivery_cost4(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        no_delivery_cost_from4
                                                      }
                                                      required={
                                                        zone_radius4
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setno_delivery_cost_from4(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv4}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("create"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                    {/* <button className="btn btn-danger m-2" onClick={() => history.goBack()}> Cancel </button> */}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          )}
                                        </div>

                                        <br />

                                        <div className="row">
                                          {xyz5 != "" ? (
                                            <div className="col-md-6">
                                              <form
                                                onSubmit={EdithandalAddZone5}
                                              >
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 5</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz5.zone_radius}
                                                      required
                                                      onChange={(e) => {
                                                        setxyz5({
                                                          ...xyz5,
                                                          zone_radius:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz5.min_order_qty}
                                                      required={
                                                        xyz5.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz5({
                                                          ...xyz5,
                                                          min_order_qty:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz5.delivery_cost}
                                                      required={
                                                        xyz5.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz5({
                                                          ...xyz5,
                                                          delivery_cost:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        xyz5.no_delivery_cost_from
                                                      }
                                                      required={
                                                        xyz5.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz5({
                                                          ...xyz5,
                                                          no_delivery_cost_from:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv5}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("edit"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                        {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("delete"))
                                                      )) && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) =>
                                                            deleteZone(xyz5._id)
                                                          }
                                                          className="btn btn-danger m-2"
                                                        >
                                                          {" "}
                                                          Delete{" "}
                                                        </button>)}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          ) : (
                                            <div className="col-md-6">
                                              <form onSubmit={handalAddZone5}>
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 5</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={zone_radius5}
                                                      onChange={(e) =>
                                                        setzone_radius5(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={min_order_qty5}
                                                      required={
                                                        zone_radius5
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setmin_order_qty5(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={delivery_cost5}
                                                      required={
                                                        zone_radius5
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setdelivery_cost5(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        no_delivery_cost_from5
                                                      }
                                                      required={
                                                        zone_radius5
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setno_delivery_cost_from5(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv5}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("create"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                    {/* <button className="btn btn-danger m-2" onClick={() => history.goBack()}> Cancel </button> */}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          )}

                                          {xyz6 != "" ? (
                                            <div className="col-md-6">
                                              <form
                                                onSubmit={EdithandalAddZone6}
                                              >
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 6</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz6.zone_radius}
                                                      required
                                                      onChange={(e) => {
                                                        setxyz6({
                                                          ...xyz6,
                                                          zone_radius:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz6.min_order_qty}
                                                      required={
                                                        xyz6.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz6({
                                                          ...xyz6,
                                                          min_order_qty:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={xyz6.delivery_cost}
                                                      required={
                                                        xyz6.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz6({
                                                          ...xyz6,
                                                          delivery_cost:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        xyz6.no_delivery_cost_from
                                                      }
                                                      required={
                                                        xyz6.zone_radius
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        setxyz6({
                                                          ...xyz6,
                                                          no_delivery_cost_from:
                                                            e.target.value,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv6}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("edit"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                        {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("delete"))
                                                      )) && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) =>
                                                            deleteZone(xyz6._id)
                                                          }
                                                          className="btn btn-danger m-2"
                                                        >
                                                          {" "}
                                                          Delete{" "}
                                                        </button>)}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          ) : (
                                            <div className="col-md-6">
                                              <form onSubmit={handalAddZone6}>
                                                <div className="rounded border p-10">
                                                  <div className="mb-10">
                                                    <h6>Zone 6</h6>
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Radius (In Meter)
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={zone_radius6}
                                                      onChange={(e) =>
                                                        setzone_radius6(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Min. Order Quantity (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={min_order_qty6}
                                                      required={
                                                        zone_radius6
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setmin_order_qty6(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      Delivery Costs (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={delivery_cost6}
                                                      required={
                                                        zone_radius6
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setdelivery_cost6(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-10">
                                                    <label className="form-label">
                                                      No Delivery Cost From (In {currencyLabel})
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="filterme form-control form-control-solid"
                                                      value={
                                                        no_delivery_cost_from6
                                                      }
                                                      required={
                                                        zone_radius6
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        setno_delivery_cost_from6(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-12">
                                                    {" "}
                                                    {errorDiv6}{" "}
                                                  </div>
                                                  <div className="modal-footer flex-center">
                                                    {(userPermissionType === "groupadmin" ||
                                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                        permission?.key === "delivery" &&
                                                        (permission?.access?.includes("create"))
                                                      )) && (
                                                        <button type="submit" className="btn btn-primary">
                                                          <span className="indicator-label">Save Zone</span>
                                                        </button>)}
                                                    {/* <button className="btn btn-danger m-2" onClick={() => history.goBack()}> Cancel </button> */}
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div
                                className={
                                  isMobile
                                    ? "card-body fs-6 py-15 px-5 py-lg-15 text-gray-700"
                                    : "card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700"
                                }
                              >
                                {" "}
                                <div className="col-md-12">
                                  <div>
                                    <form onSubmit={handalAddZoneZip}>
                                      <div
                                        className={
                                          isMobile ? " " : "rounded border p-10"
                                        }
                                      >
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={iszip}
                                            onChange={DeliveryZoneByZip}
                                            name="fav_language"
                                          />
                                          <label className="form-check-label mx-3">
                                            <h3>Delivery Zone By Zip</h3>
                                          </label>
                                        </div>
                                        <div className="col-md-12">
                                          {" "}
                                          {errorDivzipradio}{" "}
                                        </div>
                                        <div className="row col-md-12">
                                          <div className="row col-md-6">
                                            <div className="mb-10">
                                              <label className="form-label">
                                                ZIP
                                              </label>
                                              <input
                                                type="text"
                                                id="abcd"
                                                // pattern="[^0-9 \,]"
                                                className=" form-control form-control-solid"
                                                value={zone_zip}
                                                // maxLength={6}
                                                // max={6}
                                                // min={6}
                                                onChange={(e) =>
                                                  setzone_zip(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="mb-10">
                                              <label className="form-label">
                                                Delivery Zone
                                              </label>
                                              <select
                                                className="form-select"
                                                // value={zone_nozip}

                                                onChange={(e) =>
                                                  setzone_nozip(e.target.value)
                                                }
                                              >
                                                <option>Select Zone</option>
                                                <option
                                                  value="1"
                                                  disabled={
                                                    SelectedZip.includes(1)
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Zone 1
                                                </option>
                                                <option
                                                  value="2"
                                                  disabled={
                                                    SelectedZip.includes(2)
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Zone 2
                                                </option>
                                                <option
                                                  value="3"
                                                  disabled={
                                                    SelectedZip.includes(3)
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Zone 3
                                                </option>
                                                <option
                                                  value="4"
                                                  disabled={
                                                    SelectedZip.includes(4)
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Zone 4
                                                </option>
                                                <option
                                                  value="5"
                                                  disabled={
                                                    SelectedZip.includes(5)
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Zone 5
                                                </option>
                                                <option
                                                  value="6"
                                                  disabled={
                                                    SelectedZip.includes(6)
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  Zone 6
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Min. Order Quantity (In {currencyLabel})
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            value={min_order_qtyzip}
                                            onChange={(e) =>
                                              setmin_order_qtyzip(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="mb-10">
                                          <label className="form-label">
                                            Delivery Costs (In {currencyLabel})
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            value={delivery_costzip}
                                            onChange={(e) =>
                                              setdelivery_costzip(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="mb-10">
                                          <label className="form-label">
                                            No Delivery Cost From (In {currencyLabel})
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control-solid"
                                            value={no_delivery_cost_fromzip}
                                            onChange={(e) =>
                                              setno_delivery_cost_fromzip(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="col-md-12">
                                          {" "}
                                          {errorDivzip}{" "}
                                        </div>
                                        <div className="modal-footer flex-center">
                                          {(userPermissionType === "groupadmin" ||
                                            UserPermissionAllData?.role_details?.settings?.find(permission =>
                                              permission?.key === "delivery" &&
                                              (permission?.access?.includes("create"))
                                            )) && (
                                              <button type="submit" className="btn btn-primary">
                                                <span className="indicator-label">Save Zone</span>
                                              </button>)}
                                          {/* <button className="btn btn-danger m-2" onClick={() => history.goBack()}> Cancel </button> */}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <br />
                                <br />
                                {ADD !== "" && (
                                  <div className={classes.root}>
                                    <MaterialTable
                                      title=""
                                      data={ADD ? ADD : ""}
                                      columns={columns}
                                      options={Tableoptions}
                                      actions={[
                                        {
                                          icon: (rowData) => (

                                            (userPermissionType === "groupadmin" ||
                                              UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                permission?.key === "delivery" &&
                                                (permission?.access?.includes("edit"))
                                              )) && (
                                            <button
                                              style={{ fontSize: "12px" }}
                                              className="btn btn-primary btn-sm"
                                              type="button"
                                              data-bs-toggle="modal"
                                              data-bs-target="#kt_modal_1"
                                            >
                                              Edit
                                            </button>)
                                          ),
                                          tooltip: "Edit Data",

                                          onClick: (event, rowData) =>
                                            EditZoneByZip(rowData),
                                        },
                                        {
                                          icon: (rowData) => ( 
                                            (userPermissionType === "groupadmin" ||
                                              UserPermissionAllData?.role_details?.settings?.find(permission =>
                                                permission?.key === "delivery" &&
                                                (permission?.access?.includes("delete"))
                                              )) && (                                           
                                            <button
                                              style={{ fontSize: "12px" }}
                                              className="btn btn-danger btn-sm"
                                              type="button"
                                            >
                                              Delete
                                            </button>)
                                          ),
                                          tooltip: "Delele Data",

                                          onClick: (event, rowData) =>
                                            deleteAdditionCharges(rowData._id),
                                        },
                                      ]}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={EdithandleSubmitZoneByZip}>
                <h5 className="modal-title">Select Zone</h5>

                <select className="form-select my-3">
                  <option>{"Zone " + Last.zone_no}</option>
                </select>
                <h5 className="modal-title">ZIP Code</h5>
                <input
                  type="text"
                  id="Editabcd"
                  className="form-control form-control-solid my-3"
                  value={Last.zone_zip}
                  onChange={(e) => {
                    setlast({ ...Last, zone_zip: e.target.value });
                  }}
                />
                <h5 className="modal-title">Min. Order Quantity (In {currencyLabel})</h5>
                <input
                  type="text"
                  className="filterme form-control form-control-solid my-3"
                  value={Last.min_order_qty}
                  onChange={(e) => {
                    setlast({ ...Last, min_order_qty: e.target.value });
                  }}
                />
                <h5 className="modal-title">Delivery Costs (In {currencyLabel})</h5>
                <input
                  type="text"
                  className="filterme form-control form-control-solid filterme my-3"
                  value={Last.delivery_cost}
                  onChange={(e) => {
                    setlast({ ...Last, delivery_cost: e.target.value });
                  }}
                />
                <h5 className="modal-title">No Delivery Cost From (In {currencyLabel})</h5>
                <input
                  type="text"
                  className="filterme form-control form-control-solid filterme my-3"
                  value={Last.no_delivery_cost_from}
                  onChange={(e) => {
                    setlast({ ...Last, no_delivery_cost_from: e.target.value });
                  }}
                />

                <div className="col-md-12"> {errorDiv} </div>

                <div className="modal-footer flex-center">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    className="modal-footer btn btn-primary"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="modal-footer btn btn-danger m-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script src="/assets/plugins/global/plugins.bundle.js"></script>
        <script src="/assets/js/scripts.bundle.js"></script>
        <script src="/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>
        <script src="/assets/js/custom/widgets.js"></script>
        <script src="/assets/js/custom/apps/chat/chat.js"></script>
        <script src="/assets/js/custom/modals/create-app.js"></script>
        <script src="/assets/js/custom/modals/upgrade-plan.js"></script>
        <script src="/assets/plugins/custom/datatables/datatables.bundle.js"></script>
        <script src="/assets/js/custom/documentation/documentation.js"></script>
        <script src="/assets/js/custom/documentation/general/datatables/advanced.js"></script>
        <script src="/assets/js/custom/authentication/sign-in/general.js"></script>
        {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAl5i_Lv4MQwX4rz890fRzMIElAaHBDrDw&libraries=places"
          async defer></script> */}
      </Helmet>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
  branchData: state.branch.branchData,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAdminDeliveryZones);
