import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const formatCurrency = (amount) => Number(amount || 0).toFixed(2);


const calculateTotal = (salesData) => {
  const cashSales = parseFloat(salesData?.cash_sales || 0);
  const onlineSales = parseFloat(salesData?.online_sales || 0);
  const creditCardSales = parseFloat(salesData?.credit_card_sales || 0);
  return (cashSales + onlineSales + creditCardSales).toFixed(2);
};

export const BusinessSummary = ({ salesData, CurrencyLabel }) => {
  const totalRevenue = calculateTotal(salesData);

  const discountItems = [
    { label: 'Coupon & Voucher', value: salesData?.discount?.total_coupon_and_voucher_discount },
    { label: 'Additional Discount', value: salesData?.discount?.additional },
  ];

  const totalSum = discountItems?.reduce((total, item) => total + (item.value || 0), 0);

  // console.log("salesData" ,salesData)

  return (
    <>
      <div className="frame8New">
        <div className="overlap-group">
          <div className="text-wrapper">Business Summary</div>
        </div>
        <Row className="mx-2">
          <Col className="total_head my-3" lg={12} md={12} xs={12}>Total Revenue</Col>
          <Col className="name_titel" lg={8} md={8} xs={6}>Cash Sales</Col>
          <Col className="name_value" lg={4} md={4} xs={6}>{formatCurrency(salesData?.cash_sales)}</Col>
          <hr style={{ margin: "5px 0px 5px 0px", opacity: 0.07 }} />
          <Col className="name_titel" lg={8} md={8} xs={6}>Online Sales</Col>
          <Col className="name_value" lg={4} md={4} xs={6}>{formatCurrency(salesData?.online_sales)}</Col>
          <hr style={{ margin: "5px 0px 5px 0px", opacity: 0.07 }} />
          <Col className="name_titel" lg={8} md={8} xs={8}>Credit Card Sales</Col>
          <Col className="name_value" lg={4} md={4} xs={4}>{formatCurrency(salesData?.credit_card_sales)}</Col>
          <hr style={{ margin: "5px 0px 5px 0px", opacity: 0.07, border: '1px dashed #D0D5DD' }} />
          <Col className="total_sum" lg={12} md={12} xs={12}>{CurrencyLabel} {totalRevenue}</Col>
          <Col className="name_titel mt-2" lg={5} md={5} xs={5}>Voucher {salesData?.voucherSales?.count || 0}x</Col>
          <Col className="name_value text-end mt-2" lg={7} md={7} xs={7}>{CurrencyLabel} {formatCurrency(salesData?.voucherSales?.total_sales || 0)}</Col>
          <Col className="name_titel mb-3 mt-2" lg={5} md={5} xs={5}>Tips Amount</Col>
          <Col className="name_value mb-3 mt-2" lg={7} md={7} xs={7}>{CurrencyLabel} {formatCurrency(salesData?.total_tip_amount || 0)}</Col>
          {/* <Col className="total_head mb-2" lg={12} md={12} xs={12}>Voids</Col> */}
          <Col className="name_titel mb-2" lg={5} md={5} xs={5}>Void Orders {salesData?.deleted_orders || 0}x</Col>
          <Col className="name_value mb-2" style={{ color: "#BC2619" }} lg={7} md={7} xs={7}>{CurrencyLabel} {formatCurrency(salesData?.deleted_orders_value)}</Col>
          <Col className="name_titel mb-2" lg={5} md={5} xs={5}>Void Items {salesData?.voided_items?.total_quantity || 0}x</Col>
          <Col className="name_value mb-2" style={{ color: "#BC2619" }} lg={7} md={7} xs={7}>{CurrencyLabel} {formatCurrency(salesData?.voided_items?.total_price)}</Col>
        </Row>
        <Row className="mx-2">
          <Col className="total_head my-4" lg={12} md={12} xs={12}>Discount</Col>
          {discountItems.map((item, index) => (
            <React.Fragment key={index}>
              <Col className="name_titel" lg={8} md={8} xs={8}>{item.label}</Col>
              <Col className="name_value" lg={4} md={4} xs={4}>{formatCurrency(item.value)}</Col>
              {index < discountItems.length - 1 && <hr style={{ margin: "5px 0px", opacity: 0.07 }} />}
            </React.Fragment>
          ))}
          <hr style={{ margin: "5px 0px", opacity: 0.07, border: '1px dashed #D0D5DD' }} />
          <Col className="total_sum" style={{ color: "#BC2619" }}  lg={12} md={12} xs={12}>{CurrencyLabel} {formatCurrency(totalSum)}</Col>
        </Row>
      </div>
    </>
  );
};

export const Top5Tender = ({ salesData ,CurrencyLabel }) => {

  const topTenderEntries = salesData?.top_tender && Object?.entries(salesData?.top_tender);


  return (
    <div className="frame8New" style={{ height: "240px" }}>
      <div className="overlap-group">
        <div className="text-wrapper">Top 5 Tender</div>
      </div>
      <Row className="mx-2 my-2">

      {topTenderEntries?.length > 0 && (
          <>
           <Col className="name_titel_tender mb-2" lg={6} md={6} xs={6}>Tender</Col>
           <Col className="name_titel_tender text-end mb-2" lg={6} md={6} xs={6}>Amount ({CurrencyLabel})</Col>
          </>
        )}
        {topTenderEntries?.length > 0 ? (
        topTenderEntries?.map(([key, value], index) => (
          <React.Fragment key={key}>
            <Col style={{textTransform  :"capitalize"}} className="name_titel2" lg={8} md={8} xs={8}>
              {key}
            </Col>
            <Col className="name_value2" lg={4} md={4} xs={4}>
              {formatCurrency(value)}
            </Col>
            {index !== topTenderEntries?.length - 1 && (
              <hr style={{ margin: '5px 0px 5px 0px', opacity: 0.07 }} />
            )}
          </React.Fragment>
        ))
      ) : (
        <Col className="text-center name_titel2 mt-20">No any data found.</Col>
      )}
      </Row>
    </div>
  )
};


const formatDateLabel = (TypeShow , salesData) => {
    const now = new Date(salesData?.date);
    const Data = salesData?.formatted_time_difference;

    // console.log("TypeShow" ,TypeShow)

    switch (TypeShow) {
        case "Today":
            return `${Data}`;
        case "Yesterday":
            return `${Data}`;
        case "Week":
            return `${moment(now).format('dddd')}, ${Data}`;
        case "Current Month":
            return `${moment(now).format('Do')} - ${moment(now).format('dddd')}, ${Data}`;
        case "Last Month":
          return `${moment(now).format('Do')} - ${moment(now).format('dddd')}, ${Data}`;
        case "Current Year":
          return `${moment(now).format('Do MMMM')} - ${moment(now).format('dddd')}, ${Data}`;
        case "Period":
          return `${moment(now).format('Do MMMM')} - ${moment(now).format('dddd')}, ${Data}`;
        default:
          return `${Data}`;
    }
};

export const TopHours = ({ salesData , TypeShow , CurrencyLabel}) => {

  return (
    <div className="frame8New mt-7" style={{ height: "240px" }}>
      <div className="overlap-group">
        <div className="text-wrapper">Top Hours</div>
      </div>
      <Row className="mx-2 my-2">
      {salesData?.top_hours?.length > 0 && (
          <>
            <Col className="name_titel_tender mb-2" lg={6} md={6} xs={6}>Hour</Col>
            <Col className="name_titel_tender text-end mb-2" lg={6} md={6} xs={6}>Amount ({CurrencyLabel})</Col>
          </>
        )}
        <div style={{ overflowY: "auto", height: 145 }}>
          {salesData?.top_hours?.length > 0 ? salesData?.top_hours?.map((main, index) => (
            <Row key={index}>
              <Col className="name_titel2" lg={8} md={8} xs={8}>{formatDateLabel(TypeShow , main)}</Col>
              <Col className="name_value2" lg={4} md={4} xs={4}>{formatCurrency(main?.final_amount)}</Col>
              {index !== salesData?.top_hours?.length - 1 && <hr style={{ margin: "5px 0px 5px 0px", opacity: 0.07 }} />}
            </Row>
          )) :
            <Col className='text-center name_titel2 mt-20'>No any data found.</Col>
          }
        </div>
      </Row>
    </div>
  )
};

export const TopCustomers = ({ salesData ,drawerPos ,CurrencyLabel }) => {


 
  return (
    <div className="frame8New" style={{ height: "240px" }}>
      <div className="overlap-group">
        <div className="text-wrapper">Top Customers</div>
      </div>
      <Row className="mx-2 my-2">

      {salesData?.top_customers?.length > 0 && (
          <>
          <Col className={drawerPos === 1 ? "name_titel_tender mb-2" : "name_titel_tenderNano mb-2"} lg={4} md={4} xs={4}>Customer</Col>
        <Col className={drawerPos === 1 ?  "name_titel_tender text-center mb-2" : "name_titel_tenderNano text-center mb-2"} lg={4} md={4} xs={4}>No of Orders</Col>
        <Col className={drawerPos === 1 ? "name_titel_tender text-end mb-2" : "name_titel_tenderNano text-end mb-2"} lg={4} md={4} xs={4}>Amount ({CurrencyLabel})</Col>
          </>
        )}
        
        <div style={{ overflowY: "auto", height: 145 }}>
          {salesData?.top_customers?.length > 0 ? salesData?.top_customers?.map((main, index) => (
            <Row key={index}>
                <Col className="name_titel2 text-overflow" lg={4} md={4} xs={4}>{main.name ? main.name : "---"}</Col>
              <Col className="name_titel2  text-center" lg={4} md={4} xs={4}>{main.number_of_orders}</Col>
              <Col className="name_value2" lg={4} md={4} xs={4}>{formatCurrency(main?.total_amount)}</Col>
              {index !== salesData?.top_customers?.length - 1 && <hr style={{ margin: "5px 0px 5px 0px", opacity: 0.07 }} />}
            </Row>
          )) :
            <Col className='text-center name_titel2 mt-20'>No any data found.</Col>
          }
        </div>
      </Row>
    </div>
  )
};

export const StaffDetails = ({ salesData ,drawerPos ,TypeShow }) => {

  const StaffDetails_ = [
    { src: "/assets/media/Dashboard/Grid Circles.png", name: "Staff logged In", value: salesData?.staff_logged_in || 0},
    { src: "/assets/media/Dashboard/People.png", name: "Tables open", value: TypeShow === "Yesterday" ? 0 : salesData?.tables_open || 0},
    { src: "/assets/media/Dashboard/Food.png", name: "Tables served", value: salesData?.tables_served || 0},
  ];

  return (
    <div className="frame8New mt-7" style={{ height: "240px" }}>
      <div className="overlap-group">
        <div className="text-wrapper">Staff Details</div>
      </div>
      <Row className="my-9">
        {StaffDetails_.length > 0 && StaffDetails_?.map((main, index) => (
          <Col key={index} className={`name_titel2 text-center ${index !== StaffDetails_.length - 1 ? 'border-right' : ''}`} lg={4} md={4} xs={4}>
            <img style={{ height: 75, width: 75 }} src={main.src} alt={main.name} />
            <div className={drawerPos === 1 ?  'name_titel22222 text-center mt-4' : 'name_titel22222Nano text-center mt-4'}>{main.name}</div>
            <div className='staff_count text-center mt-3'>{main.value}</div>
          </Col>
        ))}
      </Row>
    </div>
  )
};


export const Bestsellers2 = ({ salesData }) => (
  <div className="frame8New2">
    <div className="overlap-group">
      <div className="text-wrapper">Bestsellers</div>
    </div>
    <Row className="mx-2 my-4">
      {salesData?.bestsellers?.length > 0 ? salesData?.bestsellers?.map((main, index) => (
        <React.Fragment key={index}>
          <Col className="name_title_bestseller text-overflow" lg={8} md={8} xs={8}>{main?.total_quantity}x {main?.name_EN}</Col>
          <Col className="name_value_bestseller" lg={4} md={4} xs={4}>{formatCurrency(main?.total_price)}</Col>
        </React.Fragment>
      )) : 
        <Col 
        style={{position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%"}} className='text-center name_titel2'>No any data found.</Col>
      }
    </Row>
  </div>
);

