import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import MaterialTable from "material-table";
import headers from "../../../../CommonApi/headers";
import DownloadIcon from "@mui/icons-material/Download";
import { useStyles } from "../../../Comman/TableuseStyles";
import { Tableoptions } from "../../../Comman/TableOption";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { getOrderDetails } from "../../../../Store";
import { connect } from "react-redux";
import useDrawer from "../../../../context/DrawerContext";
import { LoadingBackdrop } from "../../../Comman/LoadingBackdrop/LoadingBackdrop";
import { getCurrencyLabel } from "../../../Comman/currencyUtils/currencyUtils";


function GroupAdminOrderDetailView({ getOrderDetails, orderDetails }) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  function getUrlParameter(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  const orderType = getUrlParameter('orderType');
  const branchId = getUrlParameter('branchId');

  const [Loading, setLoading] = useState(false)
  const [user, setuser] = useState([]);
  const [Data ,setData] = useState("")
  const [Branch, setBranch] = useState("");
  const [orderItemData, setorderItemData] = useState([]);
  let history = useHistory();
  let id = useParams();

  const currencyLabel = getCurrencyLabel(user && user?.branch?.currency);


  // useEffect(()=>{
  //   if(id.id && orderType){
  //   getOrderDetails(id.id , orderType ,branchId); 
  //   }
  // },[orderType])

  // useEffect(() => {
  //     if(orderDetails){
  //     const tableData = orderDetails;
  //     setuser(tableData?.orderData?.length > 0 && tableData?.orderData[0]);
  //     setBranch(tableData?.orderData?.length > 0 && tableData?.orderData[0]?.branch);
  //     setorderItemData(tableData?.orderData?.length > 0 && tableData?.orderData[0]?.orderItemData);
  //     }
  // }, [orderDetails]);


  useEffect(() => {
    OrderDetailsGet();
  }, [id.id && branchId]);

  function OrderDetailsGet() {
    setLoading(true);
    if (id.id && branchId) {
      api.get(`/groupadmin/order/new/${id.id}/${orderType}/${branchId}`, { headers }).then((res) => {
        const tableData = res.data.data;
        setData(tableData)
        setuser(tableData?.orderData?.length > 0 && tableData?.orderData[0]);
        setBranch(tableData?.orderData?.length > 0 && tableData?.orderData[0]?.branch);
        setorderItemData(tableData?.orderData?.length > 0 && tableData?.orderData[0]?.orderItemData);
        setLoading(false);
      })
        .catch((error) => {
          setLoading(false);
        })

    }
  }

  const classes = useStyles();

  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      field: "url",
      title: "Photo",
      render: (rowData) => (
        <img
          src={rowData?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
          style={{ width: 100, height: 110, borderRadius: "10%" }}
        />
      ),
    },
    {
      title: "Product Name",
      render: (rowData) => {
        return (
          <div>
            <div className="mb-3" style={{fontSize :"16px"}}>{rowData?.quantity} x {rowData?.name_EN}  {rowData?.is_pickup && <span className="mx-1">
              <img
                alt="Take_away"
                loading="lazy"
                src="/assets/media/avatars/take-away1 1.png"
                style={{ height: 17 }}
              />
            </span>
            }

            {rowData?.is_custom && <span className="mx-1">
              <img
                alt="Take_away"
                loading="lazy"
                src="/assets/media/avatars/CustomeDish.png"
                style={{ height: 17 }}
              />
            </span>
            }
            </div>
             <div>
              {
                rowData?.options?.length > 0 &&
                rowData?.options.map((option, optionIndex) => (
                  <div key={`${optionIndex}`}>[O] {option?.name}</div>
                ))
              }
            </div>
            {/* {rowData?.product_notes &&
            <div>
             Item Notes : {rowData?.product_notes}
            </div> } */}
          </div>
        );
      },
    },
    {
      title: "Product Notes",
      render: (rowData) => {
        return (
          <div>{rowData?.product_notes || "---"}</div>
        );
      },
    },
    {
      title: `Product Total Price (${currencyLabel})`,
      render: (rowData) => {
        return (
           <div>
            <div className="mb-3" style={{fontSize :"16px"}}>
           {Number(rowData?.quantity * rowData?.base_price).toFixed(2)}
            </div>
            <div>
              {
                rowData?.options?.length > 0 &&
                rowData?.options.map((option, optionIndex) => (
                  <div key={`${optionIndex}`}> {Number(option?.price || 0).toFixed(2)}</div>
                ))
              }
            </div>
          </div>
        );
      },
    },
    // {
    //   title: `Addon Price (${currencyLabel})`,
    //   render: (rowData) => {
    //     return (
    //       <React.Fragment>
    //         {Number(rowData?.add_on_price).toFixed(2)}
    //       </React.Fragment>
    //     );
    //   },
    // },
    {
  title: `Total Price (${currencyLabel})`,
  render: (rowData) => {
    // Calculate the total price including options
    const baseTotal = Number(rowData?.quantity * rowData?.base_price);
    const optionsTotal = rowData?.options?.reduce((acc, option) => acc + Number(option?.price || 0), 0);
    const totalPrice = Number(baseTotal + optionsTotal).toFixed(2);
    // console.log("ss" ,baseTotal ,optionsTotal ,totalPrice)

    return (
      <div>
       {totalPrice}
      </div>
    );
  },
}

  ];

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="card card-docs mb-2">
                      <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                        <div className="d-flex flex-stack">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h1
                              className="anchor fw-bolder"
                              id="custom-form-control"
                            >
                              Order Details
                            </h1>
                          </div>
                          <div className="d-flex flex-stack">
                            <div
                              className="d-flex justify-content-end mx-2"
                              style={{ marginTop: "-5px" }}
                              data-kt-docs-table-toolbar="base"
                            >
                              <button
                                onClick={() => history.goBack()}
                                className="btn btn-primary"
                                data-kt-menu-trigger="click"
                                data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-end"
                              >
                                Go Back
                              </button>
                            </div>
                          </div>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <div className="row">
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="content-section">
                                <div className="row col-md-12">
                                  <h1>
                                    <b>
                                      Order No :{" "}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.order_no || ""}
                                      </strong>
                                    </b>
                                  </h1>
                                  <div className="col-md-6">

                                    <b>Order place date : </b>
                                    <strong style={{ color: "#5e6278" }}>
                                      {moment(user?.created_at).format("MMMM Do YYYY, h:mm A")}
                                    </strong>
                                  </div>
                                  <div className="col-md-6 text-end">
                                    <h4>
                                      Branch Name : <strong style={{ color: "#5e6278" }}> {Branch?.branch_name_EN || "---"}</strong>
                                    </h4>
                                  </div>
                                  <div className="col-md-6">
                                    {user?.order_type === "pickup" ?
                                      <b>Order pickup date : </b> : user?.order_type === "delivery" ? <b>Order deliver date : </b> : ""}
                                    <strong style={{ color: "#5e6278" }}>
                                      {moment(user?.order_date).format("MMMM Do YYYY")},{" "}
                                      {/* {moment.utc(user?.order_date).format("MMMM Do YYYY")},{" "} */}
                                      {user?.order_time}
                                    </strong>
                                  </div>
                                  <div className="col-md-6 text-end">
                                    <h4>
                                      Payment Method  : <strong style={{ color: "#5e6278" }}>
                                        {user?.payment_method === "COD"
                                          ? "Cash"
                                          : "Online"}</strong>
                                    </h4>
                                  </div>
                                  <div className="col-md-6">
                                    {user?.invoice_link &&
                                      <div className="mt-3">
                                        <b>Order invoice : </b>
                                        <span>
                                          <h2
                                            className="badge badge-primary mx-2"
                                            style={{ fontSize: "14px" }}
                                            type="button"
                                            target="_blank"
                                            download
                                            onClick={() =>
                                              window.open(`${user?.invoice_link}`)
                                            }
                                          >
                                            <DownloadIcon /> Download invoice
                                          </h2>
                                        </span>
                                      </div>}
                                  </div>
                                  <div className="col-md-6 text-end">
                                    {user?.order_type === "delivery" ? (
                                      <h4
                                        className="badge badge-info"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Delivery
                                      </h4>
                                    ) : user?.order_type === "pickup" ? (
                                      <h4
                                        className="badge badge-info"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Pickup
                                      </h4>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {user?.order_notes &&
                                    <div className="col-md-6">
                                      <b>Order Notes : {""} </b>
                                      <span>
                                        <strong style={{ color: "#5e6278" }}>
                                          {user?.order_notes}
                                        </strong>
                                      </span>
                                    </div>}
                                </div>
                                <div className="row mx-2">
                                  <div className={`col-md-12 ${isMobile ? 'text-start' : 'text-end'}`}>
                                    <strong><b>Order Status :</b> {" "}</strong>
                                    {(() => {
                                      switch (user?.order_status) {
                                        case "pending":
                                          return (
                                            <h4 className="badge badge-primary" style={{ fontSize: "16px" }}>
                                              Pending
                                            </h4>
                                          );
                                        case "payment_approved":
                                          return (
                                            <h4 className="badge badge-success" style={{ fontSize: "16px" }}>
                                              Payment Approved
                                            </h4>
                                          );
                                        default:
                                          return null;
                                      }
                                    })()}
                                  </div>
                                </div>

                                <br />
                                <br />
                                <div
                                  className={
                                    isMobile ? "row col-md-12 my-5" : "row col-md-12"
                                  }
                                >
                                  {user?.email &&
                                    <h1>Customer Info</h1>}
                                </div>
                                <div className="col-md-12">
                                  {user?.order_type === "dineIn" ? "" :
                                    <p>
                                      Name :{" "}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.full_name || "--"}
                                      </strong>
                                    </p>}
                                  {user?.email &&
                                    <p>
                                      Email : {""}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.email || "--"}
                                      </strong>{" "}
                                    </p>}
                                  {user?.order_type === "dineIn" ? "" :
                                    <p>
                                      Phone No :{" "}
                                      <strong style={{ color: "#5e6278" }}>
                                        {user?.phone_number
                                          || "--"}{" "}
                                      </strong>
                                    </p>}
                                  {user?.order_type === "delivery" && (
                                    <React.Fragment>
                                      <p>
                                        Address :{" "}
                                        {user?.address_line1 && (
                                          <strong style={{ color: "#5e6278" }}>
                                            {user?.address_line1
                                              || "--"}
                                          </strong>
                                        )}
                                      </p>
                                      {user?.address_line2 && (
                                        <strong style={{ color: "#5e6278" }}>
                                          {user?.address_line2
                                            || "--"}
                                        </strong>
                                      )}
                                    </React.Fragment>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="my-5">
                                    <div className={classes.root}>
                                      <MaterialTable
                                        title=""
                                        data={orderItemData}
                                        columns={columns}
                                        options={Tableoptions}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                            <hr />
                            {Data?.subTotal && ( 
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Sub Total :{" "}
                                    {isMobile
                                      ? `${currencyLabel} ${Number(
                                        Data?.subTotal || 0
                                      ).toFixed(2)}`
                                      : ""}
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {Number(
                                       Data?.subTotal || 0
                                      ).toFixed(2)}
                                    </h4>
                                  </div>
                                )}
                              </div>
                            )}

                            {user?.additional_charges > 0 && (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Additional Charges :{" "}
                                    {isMobile
                                      ? `${currencyLabel} ${Number(user?.additional_charges || 0).toFixed(2)}`
                                      : ""}
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {
                                        Number(user?.additional_charges || 0).toFixed(2)
                                      }
                                    </h4>
                                  </div>
                                )}
                              </div>
                            )}


                            {user?.order_type === "delivery" && (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Delivery Cost :{" "}
                                    {isMobile
                                      ? `${currencyLabel} ${Number(user?.delivery_cost || 0).toFixed(2)}`
                                      : ""}
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {
                                        Number(user?.delivery_cost || 0).toFixed(2)
                                      }
                                    </h4>
                                  </div>
                                )}
                              </div>
                            ) }

                            {user?.discount_amount ? (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Discount Amount :{" "}
                                    {isMobile
                                      ? `${currencyLabel} ${Number(
                                        user?.discount_amount ? user?.discount_amount : 0
                                      ).toFixed(2)}`
                                      : ""}
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel}{" "}
                                      {Number(user?.discount_amount ? user?.discount_amount : 0).toFixed(2)}
                                    </h4>
                                  </div>
                                )}
                              </div>
                            ) : (
                              " "
                            )}


                            {user?.VAT_charges && (
                              <div className="row col-md-12">
                                <div className="col-md-10 text-end">
                                  <h4>
                                    Tax Charges :{" "}
                                    {isMobile
                                      ? `${currencyLabel} ${Number(user?.VAT_charges).toFixed(
                                        2
                                      )}`
                                      : ""}
                                  </h4>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <div className="col-md-2">
                                    <h4>
                                      {currencyLabel} {Number(user?.VAT_charges).toFixed(2)}
                                    </h4>
                                  </div>
                                )}
                              </div>
                            )}
      
                              <div className="row col-md-12">
                                <div className={"col-md-10 text-end"}>
                                  <h3>
                                    Grand Total :{" "}
                                    {isMobile
                                      && `${currencyLabel} ${Number(Data?.grandTotal || 0).toFixed(2)}`}
                                  </h3>
                                </div>
                                {isMobile ? (
                                  " "
                                ) : (
                                  <div className={"col-md-2"}>
                                    <h3>
                                      {currencyLabel} {Number(Data?.grandTotal || 0).toFixed(2)}
                                    </h3>
                                  </div>
                                )}
                              </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
        <LoadingBackdrop open={Loading} />
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  orderDetails: state.orderDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetails: (id, Type, branchId) => dispatch(getOrderDetails(id, Type, branchId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminOrderDetailView);
