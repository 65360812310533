import { Popover, List, ListItem, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./ColumPopover.css";

export default function ColumPopoverCommon(props) {

  const { anchorEl_, handleCloseColum_, onColumnDataChange, url23 } = props

  const [columnData, setColumnData] = useState({});

  const [Array_, setArray] = useState("")

  // var pathname = window.location.pathname;
  // var pathnamesplits = pathname.split("/");
  // var url23 = pathnamesplits[2];

  // console.log("columnData" ,columnData);
  // console.log("Array_" ,Array_);


  const columnsMenu = [
    {
      header: 'Menu Name',
    },
    {
      header: 'Branch',
    },
    {
      header: 'Created Date',
    },
    {
      header: 'Status',
    },
  ];

  const columnsInventoryProducts = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Product Name',
    },
    {
      header: 'Category Name',
    },
    {
      header: 'Status',
    },
  ];

  const columnsInventoryCategory = [
    {
      header: 'Sr.No',
    },
    {
      header: 'Category Name',
    },
    {
      header: 'Status',
    },
  ];

  const columnsStock = [
    {
      header: 'Product',
    },
    {
      header: 'Category',
    },
    {
      header: 'Total Quantity',
    },
    // {
    //   header: 'Price',
    // },
    {
      header: 'Branch',
    }, 
    {
      header: 'Minimum Quantity',
    },
  ];

  const columnsStockViewList = [
    {
      header: 'Quantity Added',
    },
    {
      header: 'Remaining Quantity',
    },
    {
      header: 'Price',
    },
    {
      header: 'Date & Time',
    },
    {
      header: 'Added by',
    },
  ];
  

  const columnsStockViewUsed = [
    {
      header: 'Quantity',
    },
    {
      header: 'Description',
    },
    {
      header: 'Date & Time',
    },
  ];

  const columnsEmailMarketion = [
    {
      header: 'Customer Name',
    },
    {
      header: 'Customer Email',
    },
    {
      header: 'Customer Number',
    },
    {
      header: 'Status',
    },]

    const columnsSubcription = [
      {
        header: 'Sr.No.',
      },
      {
        header: 'Plan',
      },
      {
        header: 'Purchased Date',
      },
      {
        header: 'End Date',
      },
      {
        header: 'Status',
      },
    ]; 


  useEffect(() => {
    const storedData = sessionStorage.getItem(`${url23}_columnData`);
    if (storedData === null) {
      // If sessionStorage does not have the data, initialize it
      const initialDataMenu = Object.fromEntries(columnsMenu.map(column => [column.header, true]));
      const initialDataEmailMarketing = Object.fromEntries(columnsEmailMarketion.map(column => [column.header, true]));
      const initialData1 = Object.fromEntries(columnsEmailMarketion.map(column => [column.header, true]));
      const initialDatacolumnsInventoryProducts = Object.fromEntries(columnsInventoryProducts.map(column => [column.header, true]));
      const initialDatacolumnsInventoryCategory = Object.fromEntries(columnsInventoryCategory.map(column => [column.header, true]));
      const initialDataStock = Object.fromEntries(columnsStock.map(column => [column.header, true]));

      const StockViewList = Object.fromEntries(columnsStockViewList.map(column => [column.header, true]));
      const StockViewUsed = Object.fromEntries(columnsStockViewUsed.map(column => [column.header, true]));


      const Subcription = Object.fromEntries(columnsSubcription.map(column => [column.header, true]));

      

      if (url23 === "menu_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDataMenu));
        setColumnData(initialDataMenu);
      } else if (url23 === "stock_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDataStock));
        setColumnData(initialDataStock);
      } 
      else if (url23 === "view_stock_list") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(StockViewList));
        setColumnData(StockViewList);
      }
      else if (url23 === "view_stock_used") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(StockViewUsed));
        setColumnData(StockViewUsed);
      }
      else if (url23 === "email_marketing") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDataEmailMarketing));
        setColumnData(initialDataEmailMarketing);
      } else if (url23 === "productlist") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDatacolumnsInventoryProducts));
        setColumnData(initialDatacolumnsInventoryProducts);
      } else if (url23 === "categorylist") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialDatacolumnsInventoryCategory));
        setColumnData(initialDatacolumnsInventoryCategory);
      }
      else if (url23 === "subscription_management") {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(Subcription));
        setColumnData(Subcription);
      }
      else {
        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialData1));
        setColumnData(initialData1);
      }
    } else {
      const parsedData = JSON.parse(storedData);
      setColumnData(parsedData);
    }
    setArray(url23 === "menu_management" ?
      columnsMenu :
      url23 === "stock_management" ?
        columnsStock :  
        url23 === "view_stock_list" ? 
        columnsStockViewList :  
        url23 === "view_stock_used" ? 
        columnsStockViewUsed :
        url23 === "email_marketing" ?
          columnsEmailMarketion :
          url23 === "productlist" ?
            columnsInventoryProducts :
            url23 === "categorylist" ? 
              columnsInventoryCategory : url23 === "subscription_management" ? columnsSubcription: "")
  }, [url23]);



  
  // const handleCheckboxClick = (columnName, isChecked) => {
  //   const updatedColumnData = {
  //     ...columnData,
  //     [columnName]: isChecked
  //   };
  //   if(url23 === "menu_management"){
  //     sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData));
  //   }
  //   else if(url23 === "email_marketing"){
  //     sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData));
  //   }
  //   else{
  //     sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData)); 
  //   }
  //   setColumnData(updatedColumnData);
  //   onColumnDataChange(updatedColumnData);
  // };

  const handleCheckboxClick = (columnName, isChecked) => {
    const updatedColumnData = {
      ...columnData,
      [columnName]: isChecked,
    };

    sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData));

    setColumnData(updatedColumnData);
    onColumnDataChange(updatedColumnData);
  };


  const isColumnChecked = (columnName) => {
    return columnData[columnName] === true;
  };



  return (
    <Popover
      open={Boolean(anchorEl_)}
      anchorEl={anchorEl_}
      onClose={handleCloseColum_}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: 3,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 35,
            height: 35,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 85,
            width: 13,
            height: 13,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Typography variant="h6" sx={{ px: 2, py: 1 }}>
        Display Columns
      </Typography>
      <List sx={{ px: 1 }}>
        {Array_ !== "" && Array_?.map((column, index) => (
          <ListItem sx={{ py: 0 }} key={index}
            style={{
              display: column?.header === "Menu Name"
                ? "none" : ""
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  className="checkbox"
                  checked={isColumnChecked(column.header)}
                  onClick={(e) => handleCheckboxClick(column.header, e.target.checked)} />
              }
              label={
                <Typography
                  className="checkboxname">{column.header}</Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}
