import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { userPermissionType } from "../../../../Comman/UserPermission";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { deleteTourgroupSetting } from "./deleteTourgroupSetting";

/**
 * Generates table action handlers.
 * 
 * @param {object} history - The history object from react-router-dom.
 * @param {function} refreshData - The function to call for delete operations.
 * @param {object} paths - An object containing dynamic paths for view, edit, and delete actions.
 * 
 * @returns {Array} - An array of action configurations.
 * 
 */





export function Tabletoursetup(history, refreshData, paths, tooltips, messages, permission) {
  const actions = [];


  const abca = window?.location?.pathname?.split("/");
  const urlPa = abca?.[4];

  const userPermissionType_ = userPermissionType === "groupadmin"

  // console.log("printer" ,permission)
  // If userPermissionType_ is true, add all actions regardless of permissions
  if (userPermissionType_) {
    if (paths.editPath) {
      actions.push({
        icon: "edit",
        tooltip: tooltips.editTooltip,
        onClick: (event, rowData) => {
          history.push(`/${paths.editPath}/${rowData._id}`);
        },
      });
    }

    actions.push({
      icon: "delete",
      tooltip: tooltips.deleteTooltip,
      onClick: (event, rowData) => {
        deleteCallback(rowData._id, paths.deletePath, refreshData, messages.deleteMessage);
      },
    });
  }
  else {

    if (paths.editPath) {
      // Add actions based on individual permissions
      if (permission?.access?.access?.includes("edit")) {
        actions.push({
          icon: "edit",
          tooltip: tooltips.editTooltip,
          onClick: (event, rowData) => {
            history.push(`/${paths.editPath}/${rowData._id}`);
          },
        });
      }
    }

    if (permission?.access?.access?.includes("delete")) {
      actions.push({
        icon: "delete",
        tooltip: tooltips.deleteTooltip,
        onClick: (event, rowData) => {
          deleteCallback(rowData._id, paths.deletePath, refreshData, messages.deleteMessage);
        },
      });
    }
  }

  return actions;
}



/**
 * A common delete function for all table actions.
 * 
 * @param {string} id - The ID of the item to be deleted.
 * @param {string} url - The API endpoint for the delete operation.
 * @param {function} refreshData - The function to refresh the table data after deletion.
 */
export function deleteCallback(id, url, refreshData, messages) {


  const abca = window?.location?.pathname?.split("/");
  const urlPa = abca?.[4];

  let item;
  switch (urlPa) {
    case "ClientList":
      item = { client_id: id };
      break;
    case "MealList":
      item = { meal_id: id };
      break;
    case "CompanyList":
      item = { company_id: id };
      break;
    case "LocationList":
      item = { location_id: id };
      break;
    case "StatusList":
      item = { status_id: id };
      break;
    case "PaymentStatusList":
      item = { payment_status_id: id };
      break;
    case "CurrencyList":
      item = { currency_id: id };
      break;
    case "VatList":
      item = { vat_id: id };
      break;
    case "InvoiceSetup":
      item = { invoicesetup: id };
      break;
    // Add more cases as needed
    default:
      item = {}; // Default item if no matching case
      break;
  }

  deleteTourgroupSetting(`${url}`, messages, refreshData, item);
}

