import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Box, Typography } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import useDrawer from "../../../../../context/DrawerContext";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { connect } from "react-redux";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";
import { setMaxDate } from "../../../../Comman/DateUtils/dateUtils";


function GroupAdminOrderProductWiseReports({ getBranchList, BranchList_ }) {

  const filteredOptions = branchfilterAndMapData(BranchList_);


  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [user, setuser] = useState([]);
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState([]);
  const [Order_type, setOrder_type] = useState("");
  const [Order_type2, setOrder_type2] = useState([]);
  const [Order_Active, setOrder_Active] = useState("");
  const [Order_Active2, setOrder_Active2] = useState([]);
  const [loader, setloader] = useState(false);
  const [bestSellingProduct , setbestSellingProduct] = useState("")
  const [Dowloader, setDowloader] = useState(false);


  // console.log(Order_type)
  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);

  useEffect(() => {
    getBranchList();
    //  setMaxDate(["date", "date1"]); //// Max date validation ////
    }, []);
  


    const productNamesEN = bestSellingProduct?.length > 0 && bestSellingProduct?.map(item => item.product_name_EN);
    const basePrices = bestSellingProduct?.length > 0 && bestSellingProduct?.map(item => item.base_price);

    const [chartData3, setChartData3] = useState("");

      useEffect(()=>{
        
        const chartData3 = {
          labels: productNamesEN,
          datasets: [
          {
            label: Order_type,
            backgroundColor: "#9ca3af",
            borderColor: "#4b5563",
            borderWidth: 1,
            stack: 1,
            barThickness : 40,
            hoverBorderColor: "#4b5563",
            data: basePrices
          },
        ]
        }
        setChartData3(chartData3);
        }, [bestSellingProduct?.length > 0])
     

        const handleConvertToPdf1 = async () => {
          const content = document.getElementById('contentToConvert');
          
          // Define the options for the PDF
          const pdfOptions = {
            orientation: 'portrait', // or 'landscape'
            unit: 'mm',
            format: 'a4',
          };
        
          // Explicitly set width and height for the PDF page
          pdfOptions.width = content.offsetWidth;
          pdfOptions.height = content.offsetHeight;
        
          if (content) {
            try {
              const canvas = await html2canvas(content);
        
              // Create a PDF document
              const pdf = new jsPDF('p', 'mm', 'a4');
              const imgData = canvas.toDataURL('image/png');
        
              // Add the image to the PDF
              pdf.addImage(imgData, 'PNG', 10, 10, 190, 100); // Adjust the position and size
        
              // Save the PDF or open it in a new window
              pdf.save('document.pdf');
            } catch (error) {
              console.error('Error generating PDF:', error);
            }
          }
        };
        

        const handleConvertToPdf = () => {
          setDowloader(true);

          const doc = new jsPDF();
          

           // Set PDF properties
            doc.setProperties({
              title: 'My PDF Document',
              subject: 'Adding a Border',
              author: 'Your Name',
              keywords: 'PDF, border, example',
            });



            

        // Add a border to the entire PDF
        doc.setDrawColor(0); // Set border color (black)
        doc.setLineWidth(0.3); // Set border line width (1 unit)

        // Add a rectangle to create a border around the entire page
        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);
        

        doc.addImage(bestSellingProduct[0]?.logo_base64 ? bestSellingProduct[0]?.logo_base64 : "/assets/media/logos/Logo_Dark.png", 'PNG', 150, 15, 40, 20);
          // Create an array to hold the table data
          const tableData = [];
        
          // Add the table header row
          tableData.push(['Category', 'Product', `Amount (${currencyLabel})`]);
        
          // Add data from your bestSellingProduct array
          bestSellingProduct?.forEach((item) => {
            tableData.push([
              item.category_name_EN,
              item.product_name_EN,
              Number(item.base_price).toFixed(2),
            ]);
          });
        
          // Define the table styling
          const tableConfig = {
            startY: 45, // Adjust the vertical starting position
            headStyles: {
              fillColor: [192, 192, 192], // Header background color
              textColor: [0, 0, 0], // Header text color
            },
          };
        
          // Add the table using autoTable
          doc.autoTable({
            head: [tableData[0]], // Use the first row as the table header
            body: tableData.slice(1), // Use the rest of the rows as table data
            ...tableConfig,
          });
        
          // Calculate the total amount
          const totalAmount = Number(
            bestSellingProduct?.length > 0
              ? bestSellingProduct
                  .map((item) => item?.base_price)
                  .reduce((sum, price) => sum + price, 0)
              : 0
          ).toFixed(2);
          doc.setFontSize(12);
          // Calculate the width of the table
          const tableWidth = doc.internal.pageSize.getWidth() - 70; // Adjust the left margin and right margin (20 + 20)
        
          // Calculate the position for the "Total Amount" text
          const xPosition = tableWidth - doc.getStringUnitWidth(`Total Amount: ${totalAmount}`) * 1; // Adjust the font size (12) if needed
          const yPosition = doc.autoTable.previous.finalY + 6; // Position it below the table with some spacing
        
          // Set the font style to bold and add the "Total Amount" text using text
          doc.setFont("Inter");
          doc.text(Order_type ,15,20)
          
          doc.text(
            `Filter: `,
            20,
            26,
          );
          doc.text(
            `Date : ${moment(start_date).format("DD.MM.YYYY")} - ${moment(
              end_date ? end_date : new Date()
            ).format("DD.MM.YYYY")}`,
            20,
            32, 
          );
          
          doc.text(`Total Amount: ${totalAmount}`, xPosition, yPosition, { align: 'left' });


          const chartElement = document.getElementById('contentToConvert');

          html2canvas(chartElement, { allowTaint: true }).then(function (canvas) {
            // Convert the canvas to a data URL
            const chartImage = canvas.toDataURL('image/png');
        
            // Calculate the width and height of the chart image
            const chartWidth = tableWidth;
            const chartHeight = (canvas.height / canvas.width) * chartWidth;
        
            // Add the chart image to the PDF
            doc.addImage(chartImage, 'PNG', 30, yPosition + 10, chartWidth, chartHeight);
        
            // Save the PDF with a filename
            doc.output('save', `${"Best Selling Products"} ${moment(new Date()).format(
                                                          "DD-MM-YYYY"
                                                        )}.pdf`, { compress: true });
          });


          setDowloader(false)
        };


    const [error, setError] = useState(null);
     const errorDiv = error ? (
        <div className="text-center alert alert-danger">{error}</div>
      ) : (
        ""
      );

  // console.log("bestSellingProduct" ,bestSellingProduct)

    function handalsubmit(event){
      event.preventDefault();
      setError(null);

    if (branch_id == "") {
      setError("Please select branch.");
      return;
    }
    else if (Order_type == "") {
      setError("Please select order type");
      return;
    }
    else if (start_date == "") {
      setError("Please select start date");
      return;
    }
    const result2 = moment(end_date).add(1, "days").toDate();
     if (Date.parse(result2) <= Date.parse(start_date)) {
      setError("End date should be greater than Start date");
      
      return;
    }
    else{
      setloader(true);
      const result2 = moment(end_date).add(1, "days").toDate();
      if(Order_type === "Active Products List"){

        if(Order_Active !== ""){
          if(end_date !== ""){

            let url = `/groupadmin/report/productWise?branch_id=${branch_id}&&start_date=${moment(start_date).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(result2).format(
              "YYYY-MM-DD"
            )}&is_active=${Order_Active}`
  
            api
              .get(`${url}`, {
                headers,
              })
              .then((res) => {
                setloader(false);
  
                  // Iterate through the JSON response and add base_price to the total
                 
                // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
                setbestSellingProduct(res?.data?.data?.length > 0 ? res?.data?.data : [] )
                // setErrorNoData(res?.data?.data == undefined ? true : false);
                // console.log("res", res?.data?.data);
              })
              .catch((err) => {
                setloader(false);
                if (err.response) {
                  setError(err.response?.data?.error?.message);
                }
              });
          }else{
             
          let url = `/groupadmin/report/productWise?branch_id=${branch_id}&start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}`;
          
          api
            .get(`${url}`, {
              headers,
            })
            .then((res) => {
              setloader(false);
              // console.log(res?.data?.data)
              // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
              setbestSellingProduct(res?.data?.data)
              // setErrorNoData(res?.data?.data == undefined ? true : false);
              // console.log("res", res?.data?.data);
            })
            .catch((err) => {
              setloader(false);
              if (err.response) {
                setError(err.response?.data?.error?.message);
              }
            });
          }
        }else{
          if(end_date !== ""){

            let url = `/groupadmin/report/productWise?branch_id=${branch_id}&&start_date=${moment(start_date).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(result2).format(
              "YYYY-MM-DD"
            )}`
  
            api
              .get(`${url}`, {
                headers,
              })
              .then((res) => {
                setloader(false);
  
                  // Iterate through the JSON response and add base_price to the total
                 
                // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
                setbestSellingProduct(res?.data?.data?.length > 0 ? res?.data?.data : [] )
                // setErrorNoData(res?.data?.data == undefined ? true : false);
                // console.log("res", res?.data?.data);
              })
              .catch((err) => {
                setloader(false);
                if (err.response) {
                  setError(err.response?.data?.error?.message);
                }
              });
          }else{
             
          let url = `/groupadmin/report/productWise?branch_id=${branch_id}&start_date=${moment(start_date).format(
            "YYYY-MM-DD"
          )}`;
          
          api
            .get(`${url}`, {
              headers,
            })
            .then((res) => {
              setloader(false);
              // console.log(res?.data?.data)
              // setFilterData(res?.data?.data?.filter((word) => word.branch_id));
              setbestSellingProduct(res?.data?.data)
              // setErrorNoData(res?.data?.data == undefined ? true : false);
              // console.log("res", res?.data?.data);
            })
            .catch((err) => {
              setloader(false);
              if (err.response) {
                setError(err.response?.data?.error?.message);
              }
            });
          }
        }
        

      }else if(Order_type === "All Products List by Category"){
        setloader(false);

      }
      else{

      }

    }
    }



    function convertToCSV(data) {
      const headers = ["Category", "Product", `Amount (${currencyLabel})`];
      const csvData = [headers.join(",")];
    
      data.forEach((item) => {
        const { category_name_EN, product_name_EN, base_price } = item;
        const formattedRow = [category_name_EN, product_name_EN, base_price].join(",");
        csvData.push(formattedRow);
      });

      
      const totalBasePrice = data.reduce((total, item) => total + item.base_price, 0);
      const totalRow = ["", "", `Total :- ${totalBasePrice.toFixed(2)}`].join(",");
      csvData.push(totalRow);

    
      return csvData.join("\n");
    }

    function downloadCSV(data , file) {
      const csvContent = convertToCSV(data);
    
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: "text/csv" });
    
      // Create a download link for the Blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = file // Change the filename as needed
    
      // Append the link to the body and trigger the click event
      document.body.appendChild(a);
      a.click();
    
      // Clean up by removing the link and revoking the URL
      window.URL.revokeObjectURL(url);
    }

    const { drawerPos, handleDrawer , mainClassCustome } = useDrawer();

     

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container"className={isMobile ? "mx-5 p-0" : "mx-7 p-0"}>
                <main style={{position : "initial"}} className={mainClassCustome.join(" ")}>

                  <div className="d-flex flex-stack mb-2 p-0">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-docs-table-toolbar="base"
                    >
                      <h2
                        className="anchor fw-bolder mb-5"
                        id="custom-form-control"
                      >
                    Product Reports
                      </h2>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end mx-2"
                        data-kt-docs-table-toolbar="base"
                      >
                        <button
                          onClick={() => history.push("/group_admin/report")}
                          className="btn btn-primary ps-7"
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-2">
                  <Card style={{border : "1px solid #bdbcbc"}}>
                  <Card.Body>
                  <Row >
                  <Col lg={4} md={4} sx={4}> 
                  <label className="required form-label">
                                  Select Branch
                                </label>
                                <Select
                                 styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                //  options={[
                                //     {
                                //       label: "All",
                                //       value: "All",
                                //     },
                                //     ...filteredOptions,
                                //   ]}

                                  // placeholder={getPlaceholder(filteredOptions)}
                                options={getSelectOptions(filteredOptions)}
                                  value={branch_id2}
                                  onChange={(opt) => {
                                    if(opt.value === "All"){
                                      setbranch_id(opt.value);
                                    }else{
                                      setbranch_id(opt.value?.value);
                                    }
                                    setbranch_id2(opt);
                                  }}
                                />
                  </Col>

                              <Col lg={4} md={4} sx={4}> 
                              <label className="required form-label">
                              Select Product Type
                              </label>
                              <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  options={[
                                    {
                                      label: "Active Products List",
                                      value: "Active Products List",
                                    },
                                    {
                                      label: "All Products List by Category",
                                      value: "All Products List by Category",
                                    },
                                  ]}
                                  value={Order_type2}
                                  onChange={(opt) => {
                                    setOrder_type(opt.value);
                                    setOrder_type2(opt);
                                  }}
                              /></Col>
                              <Col lg={4} md={4} sx={4}> 
                              <label className="form-label">
                              Active/Inactive 
                                </label>
                              <Select
                                  styles={selectStylesReport}
                                  className="search-line"
                                  placeholder="Search..."
                                  options={[
                                    {
                                      label: "Active",
                                      value: "Active",
                                    },
                                    {
                                      label: "Inactive",
                                      value: "Inactive",
                                    },
                                  ]}
                                  value={Order_Active2}
                                  onChange={(opt) => {
                                    setOrder_Active(opt.value);
                                    setOrder_Active2(opt);
                                  }}
                              /></Col>
                          </Row>
            
                          <div className="row mt-7" >
                              <div className={isTabletOrMobile ? "col-md-4" :"col-md-2" } >
                                <label className="required form-label">
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  name="date"
                                  id="date"
                                  className="form-control"
                                  value={start_date}
                                  onChange={(e) => {
                                    setstart_date(e.target.value);
                                  }}
                                />
                              </div>
                              <div className={isTabletOrMobile ? "col-md-4" :"col-md-2" }>
                                <label className="form-label">End Date</label>
                                <input
                                  type="date"
                                  name="date1"
                                  id="date1"
                                  className="form-control"
                                  value={end_date}
                                  onChange={(e) => {
                                    setend_date(e.target.value);
                                  }}
                                />
                              </div>
                              <div className={isTabletOrMobile ? "col-md-4 mt-8 text-end" : "col-md-3 mt-8" } >
                              {loader ? (
                                  <Box>
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                  <button
                                                      onClick={handalsubmit}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-eye fs-2"></i> &nbsp; 
                                                                        Preview</span>
                                                        </button>
                                      )}
                              
                                                        </div>
                                                    <div className={isTabletOrMobile ? "col-md-12 mt-8 text-end" : "col-md-5 mt-9 text-end" }>
                                                      {bestSellingProduct?.length > 0 ?
                                                      <React.Fragment>
                                                        {Dowloader  ?  <Box>
                                                          <CircularProgress />
                                                        </Box> :
                                                      <button
                                                      onClick={handleConvertToPdf}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        <i className="fa fa-file-pdf-o fs-3"></i>&nbsp;Download PDF</span>
                                                        </button> }&nbsp;&nbsp;
                                                        {/* <CsvDownloadButton lassName="btn btn-secondary"   fields={fields} data={bestSellingProduct} > */}
                                                        <button
                                                        onClick={()=>downloadCSV(bestSellingProduct ,`${"Best Selling Products"} ${moment(new Date()).format(
                                                          "DD-MM-YYYY"
                                                        )}.csv` )}
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        >
                                                        <span className="indicator-label">
                                                        
                                                        <i className="fa fa-file-excel-o fs-3"></i>&nbsp;Download CSV</span>
                                                        </button>
                                                        {/* </CsvDownloadButton> */}
                                                        </React.Fragment> : ""}
                                                      </div>
                                                </div> 

                                                <div className="col-md-12 my-3"> {errorDiv} </div>

                                    </Card.Body>
                                    </Card>

                  
                                    {/* <Row className="text-center mt-15">
                                          <Col>
                                          <button
                                          style={{height : 70}}
                                                                        //   onClick={handleSubmitClose}
                                                                        type="button"
                                                                        className="btn btn-secondary mx-4"
                                                                        onClick={handalsubmit}
                                                                        >
                                                                        <span style={{fontSize : "22px"}} className="indicator-label">
                                                                        <i className="fa fa-eye" style={{fontSize : "4.55rem"}}></i> &nbsp; &nbsp; Show 
                                                                        preview</span>
                                                                        </button>
                                          </Col>
                                    </Row> */}

                                  {bestSellingProduct?.length > 0 ? 
                                  <div>
                                  <Card className="mt-10">
                                    <Typography>
                                          <div className="my-3">
                                                  <div
                                                    style={{
                                                      overflowX: "auto",
                                                    }}
                                                  >
                                                            <React.Fragment>
                                                              <div>
                                                                <h1 className="my-5 mx-5">
                                                                Date : {moment(start_date).format("DD-MM-YYYY")}  - {moment(end_date ? end_date : new Date()).format("DD-MM-YYYY")}
                                                                </h1>
                                                              </div>
                                                              <table
                                                                id="kt_datatable_example_5"
                                                                className="table table-striped table-row-bordered gy-5 gs-7"
                                                              >
                                                                <thead>
                                                                  <tr
                                                                    className="fw-bold fs-6 text-gray-800"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "darkgray",
                                                                    }}
                                                                  >
                                                                    <th style={{ fontFamily: "Inter" ,fontSize : 18 }} className="min-w-25px">
                                                                    Category 
                                                                    </th>
                                                                    <th style={{ fontFamily: "Inter" ,fontSize : 18 }}  className="min-w-30px">
                                                                      Product 
                                                                    </th>
                                                                    <th style={{ fontFamily: "Inter" ,fontSize : 18 }}  className="min-w-30px">
                                                                      Amount ({currencyLabel})
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>

                                                                {bestSellingProduct?.length > 0 ? bestSellingProduct.map((item, index) => (
                                                                      <tr key={index}>
                                                                      <td style={{ fontFamily: "Inter" ,fontSize : 16 ,height: "73px" }} >{item.category_name_EN}</td>
                                                                      <td style={{ fontFamily: "Inter" ,fontSize : 16 ,height: "73px"}} >{item.product_name_EN}</td>
                                                                      <td style={{ fontFamily: "Inter",fontSize : 16 ,height: "73px"}} > {Number(item.base_price).toFixed(2)}</td>
                                                                      </tr>
                                                                      )) : <tr>
                                                                      <td  style={{ fontFamily: "Inter" }}  colSpan={3}> No Data Found </td>
                                                                      </tr>}
                                                                  <tr>
                                                                  <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                      <h6 style={{ fontFamily: "Inter" ,fontSize : 17 ,height: "40px" }} >
                                                                        Total
                                                                        Amount
                                                                         :{" "}
                                                                       {Number( bestSellingProduct?.length > 0 ? bestSellingProduct.map(item => item?.base_price)?.reduce((sum, price) => sum + price, 0) : 0).toFixed(2)}
                                                                      </h6>
                                                                    </td>
                                                                    
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                              <br />
                                                            </React.Fragment>
                                                  </div>
                                                </div>
                                    </Typography>
                                  </Card>

                                  </div>  : 
                                  
                                 ""
                                  }

                                {bestSellingProduct ? bestSellingProduct.length == 0 ?
                                <Card style={{border: "1px solid rgb(189, 188, 188)"}} className="mt-10 my-4 text-center">
                                    <Row className="my-5 text-center">
                                      <Col className="my-4">
                                      <h5>
                                      No Data for the selected Date or Filter. Please select a different Date or Filter.
                                      </h5>
                                      </Col>
                                    </Row>
                                    
                                  </Card> : "" : "" }

                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
        getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminOrderProductWiseReports);

