import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import MaterialTable from "material-table";
import { useStyles } from "../../Comman/TableuseStyles";
import { Tableoptions } from "../../Comman/TableOption";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { getBranchList } from "../../../Store";
import { connect } from "react-redux";
import useDrawer from "../../../context/DrawerContext";
import { generateTableActions } from "../../Comman/TableActions";
import Addnewdetails from "../../Comman/AddNewDetails/Addnewdetails";
import { BranchesPermissions, userPermissionType } from "../../Comman/UserPermission";

function GroupAdminBranchManagement({ getBranchList, BranchList_ }) {
  let history = useHistory();

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();
  useEffect(() => {
    getBranchList();
  }, []);

  const classes = useStyles();
  const columns = [
    {
      title: "Sr.No.",
      render: (rowData) => {
        return <>{rowData.tableData.id + 1}</>;
      },
    },
    {
      title: "Branch Name",
      field: "branch_name_EN",
    },
    {
      title: "Branch Location",
      field: "address",
    },
    {
      title: "Phone Number",
      field: "phone_number",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Time Zone",
      field: "branchTimeZone",
    },
    {
      title: "Currency",
      field: "currency",
    },
    {
      title: "Sequence No",
      field: "seq_no",
      render: (rowData) => {
        return (
          <div className="text-center" style={{ fontSize: "16px" }}>
            {rowData?.seq_no}
          </div>
        );
      },
    },
    {
      title: "Branch Status",
      render: (rowData) => {
        return (
          <div>
            {rowData.is_active === true ? (
              <button type="button" className="btn btn-success">
                Active
              </button>
            ) : (
              <button type="button" className="btn btn-Inactive">
                Inactive
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const userPermissionType_ = userPermissionType === "groupadmin"

  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;
  const shouldShowCouponVoucher = DataSub && (DataSub.status === 'active' || DataSub.status === 'trial') && DataSub

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <ToastContainer />
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container"
                  // className="container-xxl"
                  className="mx-7"

                >
                  <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>
                    <div className="row g-5 g-xl-8">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                        <div className="d-flex flex-stack mb-3">
                          <div
                            className="d-flex justify-content-end"
                            data-kt-docs-table-toolbar="base"
                          >
                            <h2
                              className="anchor fw-bolder mb-5"
                              id="custom-form-control"
                            >
                              Branches
                            </h2>
                          </div>
                          <div className="d-flex flex-stack mb-5">
                            <div
                              className="d-flex justify-content-end mx-2"
                              data-kt-docs-table-toolbar="base"
                            >

                              {/* {console.log("aaa" ,shouldShowCouponVoucher?.branches , shouldShowCouponVoucher?.number_of_branches)} */}

                              {(userPermissionType_ || BranchesPermissions?.access?.includes("create")) &&
                                (shouldShowCouponVoucher?.branches < shouldShowCouponVoucher?.number_of_branches
                                ? 
                                (
                                  <Addnewdetails url="/group_admin/branch_management/add_branch" Name="Add Branch" />
                                ) : "")}

                            </div>
                          </div>
                        </div>
                        <div className={classes.root}>
                          <MaterialTable
                            title=""
                            data={BranchList_}
                            columns={columns}
                            options={Tableoptions}
                            actions={generateTableActions(history, getBranchList, {
                              viewPath: "group_admin/branch_management/branch_detail_view",
                              editPath: "group_admin/branch_management/edit_branch_details",
                              deletePath: "groupadmin/branch"
                            }, {
                              viewTooltip: "View Branch Details",
                              editTooltip: "Edit Branch",
                              deleteTooltip: "Delete Branch"
                            }, {
                              deleteMessage: "Branch has been successfully deleted."
                            }, {
                              access: BranchesPermissions,
                            }
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminBranchManagement);