import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { useHistory } from "react-router-dom";
import api from "../../../../../CommonApi/axios";
import Select from "react-select";
import moment from "moment";
import NoteState from "../CommanBranch/NoteState";
import { connect } from "react-redux";
import headers from "../../../../../CommonApi/headers";
import $ from "jquery";
import "./Delivery.css";
import CommonDelivery from "./CommonDelivery";
import useDrawer from "../../../../../context/DrawerContext";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { deleteCommonFunction } from "../../../../Comman/DeleteCommonFun/DeleteCommonFunction";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import Swal from "sweetalert2";
import { LoadingBackdrop } from "../../../../Comman/LoadingBackdrop/LoadingBackdrop";

window.jquery = window.$ = $;

function GroupAdminDelivery(props) {
  const { branchID } = props;

  // console.log("branchIDCommonDDD" , branchID)
  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const ErrorDisplay = <div className="col-md-12">
    <div className="card shadow-sm">
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="col-md-12">
          <a>
            <b style={{ color: "rgb(0, 0, 0)", fontSize: 20 }}>
              No Data Found.
            </b>
            <span />
          </a>
        </div>
      </div>
    </div>{" "}
  </div>

  // const { Select } = React.createContext();
  // const value = useContext(NumberContext);

  ///////////////////////// Branch List //////////////////////////
  const [slot_management_type, setslot_management_type] = useState("auto");
  const [loader, setloader] = useState(false)


  useEffect(() => {
    if (branchID !== null) {
      groupAdminDelieveyList();
      groupAdminDeliveryHolidayList();
    }
    setForm([
      {
        Week: "",
        start_time: "",
        end_time: "",
        prep_time: "",
      },
    ])
  }, [branchID]);

  ///////////////////////// Branch List //////////////////////////

  ////////////////////////// Delivery Order List /////////////////////
  const [manual_dates, setmanual_dates] = useState("")
  const [DeliveryList, setDeliveryList] = useState([]);

  const [errorListDe, setErrorListDl] = useState(null);

  function groupAdminDelieveyList() {
    setErrorListDl(null);

    api
      .get(`/groupadmin/settings/order_time/delivery/${branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        // setDeliveryList(tableData);
        setErrorListDl(null);
        setslot_management_type(tableData?.[0].slot_management_type || "auto")

        // if(tableData?.[0].slot_management_type === "direct"){
        //   setDeliveryList([]);
        // }else{
          setDeliveryList(tableData);
        // }

      })
      .catch((err) => {
        if (err.response) {
          setErrorListDl("No Data Found.");
          setDeliveryList([]);
          setslot_management_type("auto")
          // console.log("f", err.response.data);
          // console.log("f", err.response.status);
          // console.log("f", err.response.headers);
        } else if (err.request) {
          setError(err.request);
        } else {
          setError(err.message);
        }
      });
  }

  function groupAdminSlotChange(data) {
    setDeliveryList([]);
    setloader(true);
    setmanual_dates("")
    setForm([{
        Week: "",
        start_time: "",
        end_time: "",
        prep_time: "",
      },])

    let item = {
      branch_id: branchID,
      type: "delivery",
      slot_management_type: data
    }

    api
      .post(`/groupadmin/settings/order_time/changeSlotManagementType`, item, { headers })
      .then((res) => {
        const tableData = res.data.data;
        setErrorListDl(null);
        showSuccessNotification("Slot has been changed succesfully.")
        groupAdminDelieveyList()
        setloader(false);

      })
      .catch((err) => {
        setloader(false);

        // if (err.response) {
        //   setErrorListDl("No Data Found.");
        // }
      });
  }

  ////////////////////////// Delivery Order List /////////////////////

  ////////////////////////// Delivery Holiday Order List /////////////////////

  const [errorListHo, setErrorListHo] = useState(null);
  
  const [DeliveryHolidayList, setDeliveryHolidayList] = useState([]);
  // console.log(DeliveryHolidayList);

  function groupAdminDeliveryHolidayList() {
    setErrorListHo(null);
    setError(null)
    setDeliveryHolidayList([])
    setcustomDates([]);
    setcustomDates1([]);
    api
      .get(`/groupadmin/settings/order_time/holiday/delivery/${branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        setErrorListHo(null);
        setDeliveryHolidayList(tableData);
        // const options = res.data.data.map(item => ({
        //     start_date: item.start_date,

        // }))
        const options = res.data.data.map((item) =>
          moment(item.start_date).format("DD-MM-YYYY")
        );
        const options1 = res.data.data.map((item) =>
          moment(item.end_date).format("DD-MM-YYYY")
        );
        setcustomDates(options);
        setcustomDates1(options1);
      })
      .catch((err) => {
        if (err.response) {
          setErrorListHo("No Data Found.");
          setDeliveryHolidayList([]);
          setcustomDates([]);
          setcustomDates1([]);
        }
      });
  }

  ////////////////////////// Delivery Holiday Order List /////////////////////

  //////////////////////////////  Order //////////////////////////////////
  const [data, setForm] = useState([
    {
      Week: "",
      start_time: "",
      end_time: "",
      prep_time: "",
    },
  ]);



  const handleCheck = (index, e, selected) => {
    console.log(index, e.target.value, selected);
    let temp = [...data];
    temp[index][selected] = e.target.value;
    setForm(temp);
  };

  const handleremove = (index) => {
    // console.log(index)
    let list = [...data];
    list.splice(index, 1);
    setForm(list);
  };

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    if (branchID === null) {
      setError("Please Select Branch");
    } else if (data.length === 0) {
      setError("Please Select Time");
    } else {

      if (slot_management_type === "auto") {
        // $(".loader").show();
        let monday = [];
        let tuesday = [];
        let wednesday = [];
        let thursday = [];
        let friday = [];
        let saturday = [];
        let sunday = [];
        let weekend = [];
        let weekdays = [];

        for (let i = 0; i < data.length; i++) {
          $(".loader").show();
          var weekkeys = data[i].Week;
          var wkey = weekkeys.split("_");

          var key1 = wkey[0] + "_start_time";
          var key2 = wkey[0] + "_end_time";

          if (data[i].Week === "") {
            setError("Please enter mandatory values.");
            $(".loader").hide();
            return;
          } else if (data[i].start_time === "") {
            setError("Please enter mandatory values.");
            $(".loader").hide();
            return;
          } else if (data[i].end_time === "") {
            setError("Please enter mandatory values.");
            $(".loader").hide();
            return;
          } else if (data[i].start_time === data[i].end_time) {
            $(".loader").hide();
            setError(
              wkey[0] + " Starting time and closing time. it's not allow to same"
            );
            return;
          } else if (data[i].start_time > data[i].end_time) {
            $(".loader").hide();
            setError(wkey[0] + " Starting time must be lower than ending time.");
            return;
          } else if (data[i].end_time < data[i].start_time) {
            $(".loader").hide();
            setError(
              wkey[0] + " Ending time must be greater than Starting time."
            );
            return;
          } else if (data[i].prep_time === "") {
            $(".loader").hide();
            setError(" Prepration time does not allowed empty.");
            return;
          } else {
            if (wkey[0] === "sun") {
              sunday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "mon") {
              monday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "tue") {
              tuesday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "wed") {
              wednesday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "thu") {
              thursday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "fri") {
              friday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "sat") {
              saturday.push({
                [key1]: data[i].start_time,
                [key2]: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "weekend") {
              saturday.push({
                sat_start_time: data[i].start_time,
                sat_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
              sunday.push({
                sun_start_time: data[i].start_time,
                sun_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else if (wkey[0] === "weekdays") {
              monday.push({
                mon_start_time: data[i].start_time,
                mon_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
              tuesday.push({
                tue_start_time: data[i].start_time,
                tue_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
              wednesday.push({
                wed_start_time: data[i].start_time,
                wed_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
              thursday.push({
                thu_start_time: data[i].start_time,
                thu_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
              friday.push({
                fri_start_time: data[i].start_time,
                fri_end_time: data[i].end_time,
                prep_time: Number(data[i].prep_time),
              });
            } else {
            }
          }
        }

        var item = {
          branch_id: branchID,
          type: "delivery",
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          slot_management_type: slot_management_type
        };

        // console.log("item", item);
        // return

        api
          .post(`/groupadmin/settings/order_time`, item, { headers })
          .then((res) => {
            $(".loader").hide();
            // handleCheck(0, 'Select', 'Week')
            setForm([
              {
                Week: "",
                start_time: "",
                end_time: "",
                prep_time: "",
              },
            ])

            showSuccessNotification("Your work has been saved successfully.")
            groupAdminDelieveyList();
          })
          .catch((err) => {
            $(".loader").hide();
            if (err.response) {
              setError(err.response.data?.error?.message);
            } else if (err.request) {
              setError(err.request);
            } else {
              setError(err.message);
            }
          });
      } else {

        // $(".loader").show();

        if (data[0]?.start_time === "") {
          setError("Please enter mandatory values.");
          return;
        }
        else if (data[0]?.end_time === "") {
          setError("Please enter mandatory values.");
          return;
        }

        else if (data[0].start_time === data[0].end_time) {
          setError("Starting time and closing time. it's not allow to same");
          return;
        } else if (data[0].start_time > data[0].end_time) {
          setError("Starting time must be lower than ending time.");
          return;
        } else if (data[0].end_time < data[0].start_time) {
          setError("Ending time must be greater than Starting time."
          );
          return;
        }
        if (data[0]?.prep_time === "") {
          setError("Please enter mandatory values.");
          return;
        }

        else if(DeliveryList?.length > 0){
        if ([...DeliveryList[0]?.manual_dates].some(entry => {
          if (entry.date === manual_dates) {
            const newStart = moment(data[0].start_time, "HH:mm");
            const newEnd = moment(data[0].end_time, "HH:mm");
            const existingStart = moment(entry.start_time, "HH:mm");
            const existingEnd = moment(entry.end_time, "HH:mm");

            // Check if the new entry's time period overlaps with the existing one
            return (
              newStart.isBetween(existingStart, existingEnd, null, '[)') ||
              newEnd.isBetween(existingStart, existingEnd, null, '(]') ||
              existingStart.isBetween(newStart, newEnd, null, '[)') ||
              existingEnd.isBetween(newStart, newEnd, null, '(]')
            );
          }
          return false;
        })) {
          setError(`Date of ${moment(manual_dates).format("DD-MM-YYYY")} already added of given time.`);
          return;
        }}


        const result = [...DeliveryList]
        const defaultManualDate = {
          date: manual_dates, // Assuming `manual_dates` is an array and you want the first date
          start_time: data[0].start_time,
          end_time: data[0].end_time,
          prep_time: data[0].prep_time,
        };

        const FinalResul = DeliveryList?.length > 0
          ? result[0]?.manual_dates?.concat([defaultManualDate])
          : result.concat([{ manual_dates: [defaultManualDate] }]);
        
        const item = {
          branch_id: branchID,
          type: "delivery",
          slot_management_type: slot_management_type,
          manual_dates: FinalResul,
        };
      
        // console.log("data" ,data)
        // console.log("item", item);
        // return

        api
          .post(`/groupadmin/settings/order_time`, item, { headers })
          .then((res) => {
            $(".loader").hide();
            setmanual_dates('');
            setForm([
              {
                Week: "",
                start_time: "",
                end_time: "",
                prep_time: "",
              },
            ])
            showSuccessNotification("Your work has been saved successfully.")
            groupAdminDelieveyList();
            // console.log(res.data.message);
            // window.location.reload(true);
          })
          .catch((err) => {
            $(".loader").hide();
            if (err.response) {
              setError(err.response.data?.error?.message);
            } else if (err.request) {
              setError(err.request);
            } else {
              setError(err.message);
            }
          });
      }
    }
  };
  //////////////////////////////  Order //////////////////////////////////

  //////////////////////////////  Holiday //////////////////////////////////
  const [error1, setError1] = useState(null);
  const errorDiv1 = error1 ? (
    <div className="text-center alert alert-danger">{error1}</div>
  ) : (
    ""
  );

  const [DataHoliday, setDataHoliday] = useState([
    {
      start_date: "",
      end_date: "",
    },
  ]);


  const handleremoveHoliday = (index) => {
    // console.log(index)
    let list = [...DataHoliday];
    list.splice(index, 1);
    setDataHoliday(list);
  };

  const [value, setValue] = useState();
  const [value1, setValue1] = useState();

  const [customDates, setcustomDates] = useState([]);
  const [customDates1, setcustomDates1] = useState([]);

  const today = new Date()?.toISOString()?.split('T')[0];

  const handleSubmitHoliday = (event) => {
    event.preventDefault();
    setError1(null);

    if (branchID === null) {
      setError1("Please Select Branch");
      return;
    } else if (value === undefined) {
      setError1("please select start date.");
      $(".loader1").hide();
      return;
    } else if (value1 == undefined) {
      setError1("please select end date.");
      $(".loader1").hide();
      return;
    } else if (value > value1) {
      setError1(`Starting date is either equal to or lower than ending date.`);
      $(".loader1").hide();
      return;
    } else if (value1 < value) {
      setError1("End date is either equal to or greater then Start date");
      $(".loader1").hide();
      return;
    } else {
      $(".loader1").show();
      var item = {};

      item["branch_id"] = branchID;
      item["type"] = "delivery";
      item["start_date"] = moment(new Date(value)).format("YYYY-MM-DD");
      item["end_date"] = moment(new Date(value1)).format("YYYY-MM-DD");

      api
        .post(`/groupadmin/settings/order_time/holiday`, item, { headers })
        .then((res) => {
          $(".loader1").hide();
          showSuccessNotification("Holiday has been added successfully.")
          setValue("");
          setValue1("");
          groupAdminDeliveryHolidayList();
          // if (res.data.message === "Sucessfully created.") {
          //     window.location.reload(true);
          // }
        })
        .catch((err) => {
          $(".loader1").hide();
          if (err.response) {
            setError1(err.response?.data?.error?.message);
          } else if (err.request) {
            setError1(err.request);
          } else {
            setError1(err.message);
          }
        });
    }
  };

  //////////////////////////////  Holiday //////////////////////////////////

  function deleteHoliday(_id) {

    let item = {}

    deleteCommonFunction(`/groupadmin/settings/order_time/holiday/${_id}`, "Holiday has been deleted successfully.", () => {
      groupAdminDeliveryHolidayList();
      setValue("");
      setValue1("");
    }, item)

  }

  function deleteOrder(branch_id, day_id, days) {

    if (slot_management_type === "auto") {
      let items = {
        branch_id: branch_id,
        type: "delivery",
        day_id: day_id,
        day: days,
      };

      deleteCommonFunction(`/groupadmin/settings/order_time/${branchID}`,
        "Time has been deleted successfully.", () => {
          groupAdminDelieveyList();
        }, items)
    }
    else {

      // Create a copy of the manual_dates array
      let result = [...DeliveryList[0]?.manual_dates];

      // Check if the day_id is within bounds
      if (day_id >= 0 && day_id < result.length) {
        // Remove the item at the specified index
        result.splice(day_id, 1);
      }

      let item = {
        branch_id: branchID,
        type: "delivery",
        slot_management_type: slot_management_type,
        manual_dates: result
      };

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .post(`/groupadmin/settings/order_time`, item, { headers })
            .then((res) => {
              $(".loader").hide();
              setmanual_dates('');
              setForm([
                {
                  Week: "",
                  start_time: "",
                  end_time: "",
                  prep_time: "",
                },
              ])
              showSuccessNotification("Time has been deleted successfully.")
              groupAdminDelieveyList();
              // console.log(res.data.message);
              // window.location.reload(true);
            })
            .catch((err) => {
              $(".loader").hide();
              if (err.response) {
                setError(err.response.data?.error?.message);
              } else if (err.request) {
                setError(err.request);
              } else {
                setError(err.message);
              }
            });
        } else {
          groupAdminDelieveyList();
        }
      });


    }
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  const DeleteButton = (userPermissionType === "groupadmin" ||
    UserPermissionAllData?.role_details?.settings?.find(permission =>
      permission?.key === "delivery" &&
      (permission?.access?.includes("delete"))
    )) && (
      <i className="fa fa-trash fs-3" />)


  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];


  const ScheduleDetail = ({ day, dayLabel, startTime, endTime, prepTime, branchId, orderId, deleteOrder }) => (
    <div className="row col-md-12">
      <div className="col-md-3 my-2">
        <label className="form-label">
          <strong style={{ color: "black" }}>Day</strong>
        </label>
        <input readOnly type="text" className="form-control form-control-solid" value={dayLabel} />
      </div>
      <div className="col-md-2 my-2">
        <label className="form-label">
          <strong style={{ color: "black" }}>Opening Time</strong>
        </label>
        <input readOnly type="text" className="form-control form-control-solid" value={startTime} />
      </div>
      <div className="col-md-2 my-2">
        <label className="form-label">
          <strong style={{ color: "black" }}>Closing Time</strong>
        </label>
        <input readOnly type="text" className="form-control form-control-solid" value={endTime} />
      </div>
      <div className="col-md-3 my-2">
        <label className="form-label">
          <strong style={{ color: "black" }}>Avg. Making Time</strong>
        </label>
        <input readOnly type="text" className="form-control filterme form-control-solid" placeholder="Enter Preparation Time." value={prepTime} />
      </div>
      <div className="col-md-2 my-2">
        <a onClick={() => deleteOrder(branchId, orderId, day)} id="deletebtn" className="btn btn-danger1 remCF">
          <label className="form-label">
            <strong style={{ color: "black" }}><br /></strong>
          </label>
          <br />
          {DeleteButton}
        </a>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <LoadingBackdrop open={loader} />
              {/* <form onSubmit={handleSubmit}> */}
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-2"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-2 d-inline"
                          id="custom-form-control"
                        >
                          Delivery - Restaurant Time Management.
                        </h3>
                      </div>

                      <div
                        className="col-md-3 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <NoteState />
                      </div>
                    </div>
                    <div className="rounded border p-5">
                      <CommonDelivery />
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="kt_tab_pane_1"
                          role="tabpanel"
                        >
                          <div className="py-5">
                            <div className="card card-docs mb-2">
                              <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                <form onSubmit={handleSubmit}>
                                  <div className="rounded border p-10">
                                    <div className="d-inline flex-stack mb-5">
                                      <div
                                        className="d-inline justify-content-end"
                                        data-kt-docs-table-toolbar="base"
                                      >
                                      </div>
                                      <div className="mb-5">
                                        <h6>Delivery Timing Type</h6>

                                        <div className="mt-4" style={{ display: 'flex' }}>
                                          <input
                                            className="form-check-input me-5"
                                            type="radio"
                                            name="Auto"
                                            value="auto"
                                            style={{ height: "16px", width: "16px", marginTop: "0px" }}
                                            checked={slot_management_type === "auto"}
                                            onChange={(e) => {
                                              groupAdminSlotChange("auto")
                                              setError("")
                                              setslot_management_type(e.target.value)
                                            }
                                            }
                                          />
                                          <label style={{ color: "#344054", fontWeight: 400, fontSize: "14px" }} className="form-check-label">Auto</label>
                                          <input
                                            className="form-check-input mx-10 me-5"
                                            type="radio"
                                            name="Manual"
                                            value="manual"
                                            style={{ height: "16px", width: "16px", marginTop: "0px" }}
                                            checked={slot_management_type === "manual"}
                                            onChange={(e) => {
                                              groupAdminSlotChange("manual")
                                              setError("")
                                              setslot_management_type(e.target.value)
                                            }
                                            } />
                                          <label style={{ color: "#344054", fontWeight: 400, fontSize: "14px" }} className="form-check-label">Manual</label>
                                        
                                          {/* <input
                                            className="form-check-input mx-10 me-5"
                                            type="radio"
                                            name="Direct"
                                            value="direct"
                                            style={{ height: "16px", width: "16px", marginTop: "0px" }}
                                            checked={slot_management_type === "direct"}
                                            onChange={(e) => {
                                              groupAdminSlotChange("direct")
                                              setError("")
                                              setslot_management_type(e.target.value)
                                            }
                                            } />
                                          <label style={{ color: "#344054", fontWeight: 400, fontSize: "14px" }} className="form-check-label">Direct</label> */}
                                        </div>
                                      </div>
                                      <div className="mt-10">
                                        <h6>
                                          Select the times during which guest
                                          can order.
                                        </h6>
                                      </div>


                                      {data?.map((details, index) => (
                                        <>
                                          <div
                                            className="row "
                                            key={index}
                                          >
                                            <div className="col-md-3 my-2">
                                              <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  {slot_management_type === "auto" ? "Day" : "Date"}
                                                </strong>
                                              </label>
                                              {slot_management_type === "auto" ?
                                                <select
                                                  className="form-select"
                                                  // required
                                                  value={details.Week}
                                                  onChange={(e) =>
                                                    handleCheck(index, e, "Week")
                                                  }
                                                >
                                                  <option disabled selected value="">
                                                    Select
                                                  </option>
                                                  <option value="sun_Sunday">
                                                    Sunday
                                                  </option>
                                                  <option value="mon_Monday">
                                                    Monday
                                                  </option>
                                                  <option value="tue_Tuesday">
                                                    Tuesday
                                                  </option>
                                                  <option value="wed_Wednesday">
                                                    Wednesday
                                                  </option>
                                                  <option value="thu_Thursday">
                                                    Thursday
                                                  </option>
                                                  <option value="fri_Friday">
                                                    Friday
                                                  </option>
                                                  <option value="sat_Saturday">
                                                    Saturday
                                                  </option>
                                                  <option value="weekdays">
                                                    Week Days
                                                  </option>
                                                  <option value="weekend">
                                                    Week End
                                                  </option>
                                                </select> : <input id='txtDate' type="date"
                                                  className="form-control form-control-solid"
                                                  value={manual_dates}
                                                  min={today}
                                                  // required
                                                  onChange={(e) => setmanual_dates(e.target.value)}
                                                />
                                              }
                                            </div>
                                            <div className="col-md-3 my-2">
                                              <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  Opening Time.
                                                </strong>
                                              </label>
                                              <input
                                                type="time"
                                                className="form-control form-control-solid"
                                                value={details?.start_time}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    index,
                                                    e,
                                                    "start_time"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3 my-2">
                                              <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  Closing Time.
                                                </strong>
                                              </label>
                                              <input
                                                type="time"
                                                className="form-control form-control-solid"
                                                value={details?.end_time}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    index,
                                                    e,
                                                    "end_time"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3 my-2">
                                              <label
                                                for="exampleFormControlInput1"
                                                className="required form-label"
                                              >
                                                {" "}
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  {" "}
                                                  Avg Making Time.
                                                </strong>
                                              </label>
                                              <input
                                                type="text"
                                                className="filterme form-control form-control-solid"
                                                placeholder="Avg Making Time."
                                                value={details?.prep_time}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    index,
                                                    e,
                                                    "prep_time"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3 my-2">
                                              {data.length !== 1 && (
                                                <a
                                                  id="deletebtn"
                                                  className="btn btn-danger1 remCF"
                                                  onClick={() =>
                                                    handleremove(index)
                                                  }
                                                >
                                                  <br />
                                                  {DeleteButton}
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </div>

                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv}{" "}
                                    </div>

                                    <div className="col-md-12 text-center">
                                      <br />
                                      <div
                                        className="loader"
                                        style={{ display: "none" }}
                                      >
                                        <img src="/assets/loader.gif" />
                                      </div>
                                    </div>

                                    {/* <div className="col-md-12">
                                      {" "}
                                      {errorListDe && ErrorDisplay}{" "}
                                    </div> */}

                                    <div className="my-5">
                                      {slot_management_type === "auto" ?
                                        <>
                                          {DeliveryList?.length > 0 ?
                                            Object.values(DeliveryList).map(
                                              (details) => {
                                                return (
                                                  <>
                                                    {details.monday && details.monday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="monday"
                                                        dayLabel="Monday"
                                                        startTime={mo.mon_start_time}
                                                        endTime={mo.mon_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}
                                                    {details.tuesday && details.tuesday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="tuesday"
                                                        dayLabel="Tuesday"
                                                        startTime={mo.tue_start_time}
                                                        endTime={mo.tue_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}
                                                    {details.wednesday && details.wednesday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="wednesday"
                                                        dayLabel="Wednesday"
                                                        startTime={mo.wed_start_time}
                                                        endTime={mo.wed_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}
                                                    {details.thursday && details.thursday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="thursday"
                                                        dayLabel="Thursday"
                                                        startTime={mo.thu_start_time}
                                                        endTime={mo.thu_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}

                                                    {details.friday && details.friday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="friday"
                                                        dayLabel="Friday"
                                                        startTime={mo.fri_start_time}
                                                        endTime={mo.fri_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}

                                                    {details.saturday && details.saturday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="saturday"
                                                        dayLabel="Saturday"
                                                        startTime={mo.sat_start_time}
                                                        endTime={mo.sat_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}

                                                    {details.sunday && details.sunday.map((mo, index) => (
                                                      <ScheduleDetail
                                                        key={index}
                                                        day="sunday"
                                                        dayLabel="Sunday"
                                                        startTime={mo.sun_start_time}
                                                        endTime={mo.sun_end_time}
                                                        prepTime={mo.prep_time}
                                                        branchId={details.branch_id}
                                                        orderId={mo._id}
                                                        deleteOrder={deleteOrder}
                                                      />
                                                    ))}
                                                  </>
                                                );
                                              }
                                            ) :
                                            loader === false && ErrorDisplay}
                                        </> :
                                        <>
                                          {DeliveryList[0]?.manual_dates?.length > 0
                                            ?
                                            DeliveryList[0]?.manual_dates?.map((main, index) => (

                                              <div className="row col-md-12" key={index}>
                                                <div className="col-md-3 my-2">
                                                  <label className="form-label">
                                                    <strong style={{ color: "black" }}>Date</strong>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    readOnly=""
                                                    className="form-control form-control-solid"
                                                    value={moment(main?.date).format("DD-MM-YYYY")}
                                                  />
                                                </div>
                                                <div className="col-md-2 my-2">
                                                  <label className="form-label">
                                                    <strong style={{ color: "black" }}>Opening Time.</strong>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    readOnly=""
                                                    className="form-control form-control-solid"
                                                    value={main.start_time}
                                                  />
                                                </div>
                                                <div className="col-md-2 my-2">
                                                  <label className="form-label">
                                                    <strong style={{ color: "black" }}>Closing Time.</strong>
                                                  </label>
                                                  <input
                                                    readOnly=""
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={main.end_time}
                                                  />
                                                </div>
                                                <div className="col-md-3 my-2">
                                                  <label htmlFor="exampleFormControlInput1" className=" form-label">
                                                    <strong style={{ color: "black" }}>Avg Making Time. </strong>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control filterme form-control-solid"
                                                    readOnly=""
                                                    placeholder="Enter Avg Making Time."
                                                    value={main.prep_time}
                                                  />
                                                </div>
                                                <div className="col-md-2 my-2">
                                                  <a onClick={() =>
                                                    deleteOrder(
                                                      DeliveryList[0]?.manual_dates?.branch_id,
                                                      index,
                                                    )
                                                  } id="deletebtn" className="btn btn-danger1 remCF">
                                                    <label className="form-label">
                                                      <strong style={{ color: "black" }}>
                                                        <br />
                                                      </strong>
                                                    </label>
                                                    <br />
                                                    {DeleteButton}
                                                  </a>
                                                </div>
                                              </div>

                                            ))
                                            :
                                            loader === false && ErrorDisplay
                                          }
                                        </>}
                                    </div>
                                  </div>
                                  <div className="modal-footer flex-center">
                                    {(userPermissionType === "groupadmin" ||
                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                        permission?.key === "delivery" &&
                                        (permission?.access?.includes("create"))
                                      )) && (
                                        <button type="submit" className="btn btn-primary">
                                          <span className="indicator-label">Save</span>
                                        </button>)}
                                    <button
                                      type="button"
                                      className="btn btn-danger m-2"
                                      onClick={() => history.goBack()}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </form>
                                <br />

                                <form onSubmit={handleSubmitHoliday}>
                                  <div className="rounded border p-10">
                                    <div className="d-inline flex-stack mb-5">
                                      <div
                                        className="d-inline justify-content-end"
                                        data-kt-docs-table-toolbar="base"
                                      >
                                        {/* <button type="button" className="btn btn-primary addtimes" onClick={haldleNewRowHoliday} style={{ float: "right" }}>
                                                                                    Add More
                                                                                </button> */}
                                      </div>
                                      <div className="mb-10 d-inline">
                                        <h6>Closed Days: Ex. Holidays</h6>
                                      </div>
                                      <br />
                                      {DataHoliday.map((details, index) => (
                                        <>
                                          <div
                                            className="row col-md-12"
                                            key={index}
                                          >
                                            <div className="col-md-4 my-2">
                                              <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  Start Date
                                                </strong>
                                              </label>

                                              <input id='txtDate' type="date"
                                                className="form-control form-control-solid"
                                                value={value}
                                                min={today}
                                                onChange={(e) => setValue(e.target.value)}
                                              />
                                            </div>
                                            <div className="col-md-4 my-2">
                                              <label className="required form-label">
                                                <strong
                                                  style={{ color: "black" }}
                                                >
                                                  End Date
                                                </strong>
                                              </label>
                                              <input id='txtDate1'
                                                type="date"
                                                min={today}
                                                className="form-control form-control-solid"
                                                value={value1}
                                                onChange={(e) => setValue1(e.target.value)}
                                              />
                                            </div>
                                            <div className="col-md-4 my-2">
                                              {DataHoliday.length !== 1 && (

                                                <a
                                                  id="deletebtn"
                                                  className="btn btn-danger1 remCF"
                                                  onClick={() =>
                                                    handleremoveHoliday(index)
                                                  }
                                                >
                                                  {DeleteButton}
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                    <div className="col-md-12 text-center">
                                      <br />
                                      <div
                                        className="loader1"
                                        style={{ display: "none" }}
                                      >
                                        <img src="/assets/loader.gif" />
                                        {/* <img src="" alt="logo" /> */}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      {" "}
                                      {errorDiv1}{" "}
                                    </div>

                                    <div className="col-md-12">
                                      {" "}
                                      {errorListHo && ErrorDisplay}{" "}
                                    </div>

                                    {DeliveryHolidayList?.length > 0
                                      && DeliveryHolidayList.map(
                                        (details1, index) => {
                                          return (
                                            <>
                                              <div
                                                className="row col-md-12"
                                                key={index}
                                              >
                                                <div className="col-md-4 my-2">
                                                  <input
                                                    readOnly
                                                    id="txtDate"
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={moment(
                                                      details1.start_date
                                                    ).format("DD/MM/YYYY")}
                                                  />
                                                </div>
                                                <div className="col-md-4 my-2">
                                                  <input
                                                    readOnly
                                                    id="txtDate1"
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    value={moment(
                                                      details1.end_date
                                                    ).format("DD/MM/YYYY")}
                                                  />
                                                </div>
                                                {/* <div className='col-md-4 my-2'><input type="date" className="form-control form-control-solid" value={moment(details1.end_date).format('YYYY/MM/DD')} /></div> */}
                                                <div className="col-md-4 my-2">
                                                  <button
                                                    type="button"
                                                    onClick={(e) =>
                                                      deleteHoliday(
                                                        details1._id
                                                      )
                                                    }
                                                    id="deletebtn"
                                                    className="btn btn-danger1 remCF"
                                                  >
                                                    {DeleteButton}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                  <div className="modal-footer flex-center">


                                    {(userPermissionType === "groupadmin" ||
                                      UserPermissionAllData?.role_details?.settings?.find(permission =>
                                        permission?.key === "delivery" &&
                                        (permission?.access?.includes("create"))
                                      )) && (
                                        <button type="submit" className="btn btn-primary">
                                          <span className="indicator-label">Save</span>
                                        </button>)}
                                    <button
                                      type="button"
                                      className="btn btn-danger m-2"
                                      onClick={() => history.goBack()}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminDelivery);
