import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link } from "react-router-dom";
import $ from "jquery";
import { UserPermissionAllData, userPermissionType } from "../../../Comman/UserPermission";
import useDrawer from "../../../../context/DrawerContext";
window.jquery = window.$ = $;

export default function GroupAdminSettings() {

  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const SettingsPermissions = UserPermissionAllData?.role_details?.settings;


  const settingsData = [
    { title: "Delivery", link: "/group_admin/settings/delivery/restaurant_time_management", key: "delivery" },
    { title: "Pickup", link: "/group_admin/settings/pickup/restaurant_time_management", key: "pickup" },
    { title: "Dine In", link: "/group_admin/settings/dinein/restaurant_time_management", key: "dine_in" },
    { title: "Payment Provider", link: "/group_admin/settings/payment_provider", key: "payment_provider" },
    { title: "POS", link: "/group_admin/settings/pos", key: "pos" },
    { title: "Vat Charges", link: "/group_admin/settings/vatCharges", key: "vat_charges" },
    { title: "Properties Management", link: "/group_admin/settings/product_properties", key: "properties_management" },
    { title: "Group Setup", link: "/group_admin/settings/group_setup", key: "group_setup" },
    { title: "Printer", link: "/group_admin/settings/printer/printerList", key: "printer" },
    { title: "Tour Group Setup", link: "/group_admin/settings/tourSteup/CompanyList", key: "tour_setup" },
    { title: "CMS Settings", link: "/group_admin/settings/header_footer/header", key: "cms_settings" },
    { title: "Language", link: "/group_admin/settings/language", key: "language_settings" },
    { title: "Discount & Tips", link: "/group_admin/settings/discount_and_tips_management", key: "discount" },
    { title: "User Management", link: "/group_admin/settings/user_management", key: "user_management" },
    { title: "Options", link: "/group_admin/settings/options/options", key: "options" },
    { title: "Inventory", link: "/group_admin/settings/inventory_management/productlist", key: "inventory" }
  ];

  // useEffect(() => {
  //   let newFilteredMenuItems = [];
  //   if (userPermissionType === "TGA") {
  //     newFilteredMenuItems = settingsData.filter((item) =>
  //       item.title.includes("Tour Group Setup")
  //     );
  //   } else {
  //     newFilteredMenuItems = [...settingsData];
  //   }
  //   setFilteredMenuItems(newFilteredMenuItems);
  // }, [userPermissionType]);
  const userPermissionType_ = userPermissionType === "groupadmin"


  useEffect(() => {

    if (userPermissionType_) {
      setFilteredMenuItems(settingsData);
    } else {
      // Filter settings based on user permissions
      const newFilteredMenuItems = settingsData.filter((setting) =>
        SettingsPermissions.some((report) => report?.key === setting?.key && report?.access?.length > 0)
      );
      setFilteredMenuItems(newFilteredMenuItems);
    }
  }, []);

  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;
  // let shouldShowCouponVoucher;
  const shouldShowCouponVoucher = DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features

  const MenuItemsSub = shouldShowCouponVoucher ? filteredMenuItems?.length > 0 && filteredMenuItems.filter(item => {
    if (item.title === "Delivery") {
      return shouldShowCouponVoucher?.includes("delivery");
    }
    if (item.title === "Pickup") {
      return shouldShowCouponVoucher?.includes("pickup"); 
    }

    if (item.title === "Dine In") {
      return shouldShowCouponVoucher?.includes("dinein");
    }

    if (item.title === "Vat Charges") {
      return shouldShowCouponVoucher?.includes("delivery") || 
      shouldShowCouponVoucher?.includes("pickup")
      || shouldShowCouponVoucher?.includes("dinein");
    }

    if (item.title === "Tour Group Setup") {
      return shouldShowCouponVoucher?.includes("tour_group_setup");
    }
    return filteredMenuItems;
  }) :  filteredMenuItems.filter(item =>
    !["Delivery", "Pickup", "Dine In" , "Tour Group Setup" ,"Vat Charges"].includes(item.title)
  );


  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                {/* <div id="kt_content_container" className="mx-7"> */}
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="col-md-12 mb-7">
                      <h1>Settings</h1>
                    </div>
                    <div className="row g-5 g-xl-8">
                      {MenuItemsSub?.length > 0 && MenuItemsSub?.map((setting, index) => (
                        <div className="col-sm-3" key={index}>
                          <Link
                            to={setting.link}
                            className="card bg-white hoverable mb-xl-8"
                            style={{
                              // boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)"
                              border: "1px solid #D0D5DD",
                              borderRadius: "10px"
                            }}
                          >
                            <div className="card-body p-2">
                              <center>
                                <div className="my-3 mb-6 text-start mx-4">
                                  <img style={{ height : setting.title === "Inventory" && 48 }} src={`/assets/media/Setting Icon New/${setting.title}.png`} />
                                </div>
                                <div className="text-start mb-4 mx-5">
                                  <h3 style={{ fontWeight: 500 }}> {setting.title === "Vat Charges" ? "VAT Charges" : setting.title}</h3>
                                </div>
                              </center>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
