import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function BranchCommon() {
  const { id } = useParams();
  const pathname = window.location.pathname;
  const currentPath = pathname.split('/')[3];

  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;
  const shouldShowCouponVoucher = DataSub &&
    (DataSub.status === 'active' || DataSub.status === 'trial') &&
    DataSub.features?.includes('dinein');

  // Define navigation items in an array
  const navItems = [
    { label: 'Branch Details', path: 'branch_detail_view' },
    shouldShowCouponVoucher && { label: 'Table List', path: 'table_management' },
    { label: 'User List', path: 'user_list' },
  ].filter(Boolean); // Remove any false/null items (e.g., if shouldShowCouponVoucher is false)

  // Helper function to set active class
  const isActive = (tab) => currentPath === tab ? 'nav-link active' : 'nav-link';

  return (
    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
      {navItems.map((item, index) => (
        <li className="nav-item" key={index}>
          <Link
            className={isActive(item.path)}
            to={`/group_admin/branch_management/${item.path}/${id}`}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}
