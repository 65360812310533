import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { DASHBOARD, setDashboardCount, setLoading } from "../../actions";
import moment from "moment";

const generateUrl = (Type, branch_id) => {
  const baseUrl = `/groupAdmin/dashboard/getOrdersAndRevenueCount`;
  const queryParams = new URLSearchParams();

  // console.log("branch_id" ,branch_id)

  if (Type) {
    if (Type.startDate) {
      queryParams.append('type', 'FormTo');

      if (Type.startDate) {
      // if (Type.startDate === Type.endDate) {
        // If startDate is equal to endDate, add one day to endDate
        const adjustedEndDate = moment(Type.endDate).add(1, "days").format('YYYY-MM-DD');
        queryParams.append('start_date', Type.startDate);
        queryParams.append('end_date', adjustedEndDate);
      } else {
        queryParams.append('start_date', Type.startDate);
        queryParams.append('end_date', Type.endDate);
      }
    } else {
      queryParams.append('type', Type);
    }
  }


  if (branch_id && branch_id !== "All") {
    queryParams.append('branch_id', branch_id);
  }

  return `${baseUrl}?${queryParams.toString()}`;
};


function* calldashboardCount({ Type, branch_id }) {
  // console.log("Type", Type)
  if (Type) {
    try {
      yield put(setLoading(true)); // Set loading state
      // console.log('type1' ,Type?.startDate !== "")
      // let url = `/groupAdmin/dashboard/getOrdersAndRevenueCount?type=${Type?.startDate !== "" ? "FormTo" : Type}`;
      // if (branch_id === "All" || branch_id === "" || branch_id === null || branch_id === undefined) {
      //   url += ``;
      // }else if(Type?.startDate !== ""){
      //   url += `&start_date=${Type?.startDate}&end_date=${Type?.endDate}`;
      // }else if(branch_id){
      //   url += `&branch_id=${branch_id}`;
      // }
      const response = yield call(api.get, generateUrl(Type, branch_id), { headers });
      const tableData = response?.data;
      yield put(setDashboardCount(tableData));
      // console.log("ss" ,tableData)
      yield put(setLoading(false)); // Clear loading state
    } catch (error) {
      yield put(setLoading(false)); // Clear loading state on error
      if (error.response) {
        yield put({ type: DASHBOARD.CLEAR_DASHBOARD_COUNT });
      }
    }
  } else {
    yield put({ type: DASHBOARD.CLEAR_DASHBOARD_COUNT });
    yield put(setLoading(false));
  }
}

export function* watchDashboardCount() {
  yield takeEvery(DASHBOARD.GET_DASHBOARD_COUNT, calldashboardCount);
}
