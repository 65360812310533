import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../App';
import { UserPermissionAllData } from '../GroupAdmin/Comman/UserPermission';

const currentUserSubject = UserPermissionAllData?.customToken;
const abca = window?.location?.pathname?.split("/");
const posAtuhName = abca?.[1];

const RedirectUrl = localStorage.getItem("RedirectUrl");

const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
            {...rest}
            render={(props) =>
                  currentUserSubject ? (
                        <Component {...props} />
                  ) : (
                        <Redirect
                              from=""
                              to={{
                                    pathname: "/",
                              }}
                              noThrow
                        />
                  )
            }
      />
);

const PublicRoute = ({ component: Component, ...rest }) => (
      <Route
            {...rest}
            render={(props) =>
                  currentUserSubject === undefined ||
                        currentUserSubject === null ||
                        posAtuhName === "posauth" ||
                        posAtuhName === "reset_password" ? (
                        <Component {...props} />
                  ) : (
                        <Redirect
                              from=""
                              to={{
                                    pathname: RedirectUrl !== null ?  RedirectUrl : "/",
                              }}
                              noThrow
                        />
                  )
            }
      />
);

const Routes = () => (
      <>
            {routes?.map((route, index) => {
                  if (route.type === 'PrivateRoute') {
                        return <PrivateRoute key={index} path={route.path} component={route.component} exact={route.exact} />;
                  }
                  return <PublicRoute key={index} path={route.path} component={route.component} exact={route.exact} />;
            })}
      </>
);

export default Routes;
