import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import api from "../../../../CommonApi/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import headers from "../../../../CommonApi/headers";
import { getBranchList } from "../../../../Store";
import { connect } from "react-redux";
import { branchfilterAndMapData, getSelectOptions } from "../../../Comman/BranchList";
import useDrawer from "../../../../context/DrawerContext";
import { applyFilterMeHandlers } from "../../../Comman/Error/jqueryUtils";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import { SubmitformButton } from "../../../Comman/Error/SubmitformButton";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";

window.jquery = window.$ = $;

function GroupAdminAddCoupon({ getBranchList, BranchList_ }) {

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  let history = useHistory();

  const filteredOptions = branchfilterAndMapData(BranchList_);
  useEffect(() => {
    getBranchList();
  }, []);

  // console.log('BranchList_' ,BranchList_)

  const [info, setinfo] = useState("");
  const [branch_id, setbranch_id] = useState([]);
  const [discount_type, setdiscount_type] = useState("");
  const [discount_value, setdiscount_value] = useState("");
  const [start_date, setstart_date] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [end_date, setend_date] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [min_order_value, setmin_order_value] = useState("");
  const [max_discount_value, setmax_discount_value] = useState("");
  const [apply_coupon_type, setapply_coupon_type] = useState("");
  const [max_usage, setmax_usage] = useState("");
  const [product_ids, setproduct_ids] = useState([]);
  const [code, setcode] = useState("");
  const [order_type, setorder_type] = useState("");
  const [codeType, setcodeType] = useState("");
  const [end_time, setend_time] = useState("")

  const [error, setError] = useState(null);
  const [productList, setProductList] = useState([]);

  console.log("product_ids", product_ids);

  useEffect(() => {
    groupAdminProductList();
  }, [apply_coupon_type === "product", branch_id?.length]);

  function groupAdminProductList() {
    setProductList([]);
    api
      .get(`/groupadmin/product/List/${branch_id[0]?.value}`, { headers })
      .then((res) => {
        const options = res.data.data.map((item) => ({
          label: item.name_EN,
          value: item._id,
        }));
        setProductList(options);
        // console.log("res.data.data", res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.data?.error?.message === "No Data found.") {
            setProductList([]);
          }
        }
      });
  }

  useEffect(() => {
    if (codeType === "auto_generate") {
      const list = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
      var res = "";
      for (var i = 0; i < 4; i++) {
        var rnd = Math.floor(Math.random() * list.length);
        res = res + list.charAt(rnd);
      }
      setcode(res);
    } else {
      setcode("");
    }
  }, [codeType]);

  // console.log("branch_id" ,branch_id)

  // const concatenatedArray = branch_id?.reduce((acc, item) => {
  //   if (Array.isArray(item?.value)) {
  //     acc = acc.concat(item?.value?.map(subItem => subItem.value));
  //   } else {
  //     acc.push(item?.value);
  //   }
  //   return acc;
  // }, [branch_id?.length > 0]);

  // const filteredArray = concatenatedArray?.filter(item => typeof item !== 'boolean');
  // const filteredArray1 = [...new Set(filteredArray)]

  // console.log("discount_type" ,discount_type)

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);


    if (branch_id?.length === 0) {
      setError("Please Select Branch");
      return;
    } else if (order_type === "") {
      setError("please select order type.");
      $(".loader").hide();
      return;
    } else if (codeType === "") {
      setError("please select code type.");
      $(".loader").hide();
      return;
    } else if (discount_type === "") {
      setError("please select discount type.");
      $(".loader").hide();
      return;
    } else if (discount_value === "") {
      setError("Please enter discount value.");
      $(".loader").hide();
      return;
    } else if (start_date === undefined) {
      setError("please select start date.");
      $(".loader").hide();
      return;
    } else if (end_date === undefined) {
      setError("please select end date.");
      $(".loader").hide();
      return;
    } else if (start_date > end_date) {
      setError(`Starting date is either equal to or lower than ending date.`);
      $(".loader").hide();
      return;
    } else if (end_date < start_date) {
      setError("End date is either equal to or greater then Start date");
      $(".loader").hide();
      return;
    } else if (min_order_value === "") {
      setError("Please enter min order value");
      $(".loader").hide();
      return;
    }
    else if (max_usage === "") {
      setError("Please enter max usages");
      $(".loader").hide();
      return;
    }
    else if (max_usage === 0) {
      setError("Max usages should be greater than zero");
      $(".loader").hide();
      return;
    }
    else if (apply_coupon_type === "") {
      setError("Select apply coupon type");
      $(".loader").hide();
      return;
    } else if (apply_coupon_type === "product") {
      if (product_ids.length <= 0) {
        $(".loader").hide();
        setError("Select Product ");
        return;
      }
    }
    $(".loader").show();

    // const formData = new FormData();


    // formData.append("discount_type", discount_type);
    // formData.append("discount_value", discount_value);
    // formData.append("order_type", order_type);
    // formData.append("code", code);
    // formData.append(
    //   "start_date",
    //   moment(new Date(start_date)).format("YYYY-MM-DD")
    // );
    // formData.append(
    //   "end_date",
    //   moment(new Date(end_date)).format("YYYY-MM-DD")
    // );
    // formData.append("min_order_value", min_order_value);
    // formData.append("max_usage", max_usage);
    // formData.append("apply_coupon_type", apply_coupon_type);

    // if (info) {
    //   formData.append("info", info);
    // }
    // if (max_discount_value) {
    //   formData.append("max_discount_value", max_discount_value);
    // }
    // if (product_ids.length > 0) {
    //   formData.append("product_ids", JSON.stringify(product_ids));
    // }
    // if (branch_id.length > 0) {
    //   formData.append("branch_id", JSON.stringify([...new Set(filteredArray)]));
    // }

    const time = moment(end_time, 'HH:mm').isValid() ? moment(end_time, 'HH:mm').format('HH:mm:00') : undefined;
    const payload = {
      discount_type: discount_type,
      discount_value: discount_value,
      order_type: order_type,
      code: code,
      start_date: moment(new Date(start_date)).format("YYYY-MM-DD"),
      end_date: moment(new Date(end_date)).format("YYYY-MM-DD"),
      min_order_value: min_order_value,
      max_usage: max_usage,
      apply_coupon_type: apply_coupon_type?.value,
      info: info || undefined,
      max_discount_value: max_discount_value || undefined,
      product_ids: product_ids.length > 0 ? product_ids?.map(main => main?.value) : undefined,
      branch_id: branch_id?.map(main => main?.value),
      end_time: time,
    };

    // Remove undefined properties
    const cleanedPayload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key] !== undefined && payload[key] !== null && payload[key] !== '') {
        acc[key] = payload[key];
      }
      return acc;
    }, {});

    // console.log("cleanedPayload", cleanedPayload)
    // console.log("sdss", Object.fromEntries(formData.entries()));
    // return
    api
      .post(`/groupAdmin/coupon`, cleanedPayload, { headers })
      .then((res) => {
        $(".loader").hide();
        showSuccessNotification("Coupon has been added succesfully.");
        history.push("/group_admin/coupon_voucher_management/coupon_management");
      })
      .catch((err) => {
        $(".loader").hide();
        if (err.response) {
          setError(err.response?.data?.error?.message);
        }
      });
  }

  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <div className="d-flex flex-stack mb-3">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Add Coupon
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="mb-10">
                                <h4>Coupon Details</h4>
                              </div>
                              <div className="mb-10">
                                <label className="form-label">Info</label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Enter your info"
                                  value={info}
                                  onChange={(e) => setinfo(e.target.value)}
                                />
                              </div>
                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Select Branch
                                  </label>
                                  <Select
                                    placeholder="Search..."
                                    isMulti={true}
                                    // placeholder={getPlaceholder(filteredOptions)}
                                    options={getSelectOptions(filteredOptions)}
                                    // onChange={(opt) => {
                                    //   setapply_coupon_type("");
                                    //   setbranch_id(opt);
                                    //   setProductList([]);
                                    // }}
                                    onChange={(selectedOptions) => {
                                      if (selectedOptions?.some((opt) => opt.value === "All")) {
                                        setbranch_id(filteredOptions);
                                        setapply_coupon_type("");
                                        setProductList([]); // Set all filtered options when "All" is selected
                                      } else {
                                        setapply_coupon_type("");
                                        setProductList([]);
                                        setbranch_id(selectedOptions); // Set the selected options (Array of selected objects)
                                      }
                                    }}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Select order type
                                  </label>
                                  <Select
                                    required
                                    className="search-line"
                                    placeholder="Select order type..."
                                    options={[
                                      {
                                        label: "All",
                                        value: "all",
                                      },
                                      {
                                        label: "Delivery",
                                        value: "delivery",
                                      },
                                      {
                                        label: "Pickup",
                                        value: "pickup",
                                      },
                                      {
                                        label: "Dine In",
                                        value: "dineIn",
                                      },
                                    ]}
                                    onChange={(opt) => {
                                      setorder_type(opt.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Coupon code
                                  </label>
                                  <Select
                                    id="select2"
                                    required
                                    className="search-line"
                                    placeholder="Select coupon code..."
                                    options={[
                                      {
                                        label: "Manual code",
                                        value: "manual",
                                      },
                                      {
                                        label: "Auto generate code",
                                        value: "auto_generate",
                                      },
                                    ]}
                                    onChange={(opt) => {
                                      setcodeType(opt.value);
                                    }}
                                  />
                                </div>

                                {codeType === "" ? (
                                  ""
                                ) : codeType === "manual" ? (
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Manual code
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Enter Manual code"
                                      required
                                      // maxLength="8"
                                      minLength="4"
                                      value={code}
                                      onChange={(e) => setcode(e.target.value)}
                                    />
                                  </div>
                                ) : (
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Auto generate code
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Enter Auto generate code"
                                      readOnly
                                      value={code}
                                      onChange={(e) => setcode(e.target.value)}
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Discount type
                                  </label>
                                  <Select
                                    id="select2"
                                    required
                                    className="search-line"
                                    placeholder="Search..."
                                    options={[
                                      {
                                        value: "fix",
                                        label: "Fix",
                                      },
                                      {
                                        value: "percentage",
                                        label: "Percentage",
                                      },
                                    ]}
                                    onChange={(opt) =>
                                      setdiscount_type(opt.value)
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    {discount_type === "percentage"
                                      ? "Discount value (%)"
                                      : "Discount value"}
                                  </label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    placeholder={
                                      discount_type === "percentage"
                                        ? "Enter discount value (%)"
                                        : "Enter discount value"
                                    }
                                    value={discount_value}
                                    // required
                                    onChange={(e) =>
                                      setdiscount_value(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="mb-5 col-md-4">
                                  <label className="required form-label">
                                    Start date
                                  </label>
                                  <input type="date" id="endDate"
                                    className="form-control form-control-solid"
                                    min={new Date().toISOString().split('T')[0]}
                                    value={start_date}
                                    // required
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(e) => {
                                      setstart_date(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mb-5 col-md-4">
                                  <label className="required form-label">
                                    End date
                                  </label>

                                  <input type="date" id="endDate"
                                    className="form-control form-control-solid"
                                    min={new Date().toISOString().split('T')[0]}
                                    value={end_date}
                                    // required
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(e) => {
                                      setend_date(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mb-5 col-md-4">
                                  <label className="form-label">
                                    End time
                                  </label>

                                  <input type="time" id="endtime"
                                    className="form-control form-control-solid"
                                    value={end_time}
                                    onChange={(e) => {
                                      setend_time(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>

                              <br />
                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Min Order value
                                  </label>
                                  <input
                                    type="text"
                                    className="filterme form-control form-control-solid"
                                    placeholder="Enter min order value"
                                    value={min_order_value}
                                    // required
                                    onChange={(e) =>
                                      setmin_order_value(e.target.value)
                                    }
                                  />
                                </div>
                                {discount_type === "fix" ? (
                                  ""
                                ) : (
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Max Discount value
                                    </label>
                                    <input
                                      type="text"
                                      className="filterme form-control form-control-solid"
                                      placeholder="Enter max order value"
                                      required
                                      value={max_discount_value}
                                      onChange={(e) =>
                                        setmax_discount_value(e.target.value)
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="mb-10">
                                <label className="required form-label">
                                  Max usages
                                </label>
                                <input
                                  type="text"
                                  className="required filterme form-control form-control-solid"
                                  placeholder="Enter max usages"
                                  value={max_usage}
                                  // required
                                  onChange={(e) => setmax_usage(e.target.value)}
                                />
                              </div>

                              {branch_id?.length > 0 && (
                                <div className="row col-md-12">
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Apply coupon type
                                    </label>
                                    <Select
                                      id="select2"
                                      required
                                      value={apply_coupon_type}
                                      className="search-line"
                                      placeholder="Search..."
                                      options={(order_type === "all" || branch_id?.length > 1) ? [
                                        {
                                          value: "order",
                                          label: "Total order",
                                        },
                                      ] : [
                                        {
                                          value: "order",
                                          label: "Total order",
                                        },
                                        {
                                          value: "product",
                                          label: "Product",
                                        },
                                      ]}
                                      onChange={(opt) =>
                                        setapply_coupon_type(opt)
                                      }
                                    />
                                  </div>
                                  {apply_coupon_type?.value === "product" && (
                                    <div className="col-md-6 mb-10">
                                      <label className="form-label">
                                        Product
                                      </label>
                                      {productList?.length > 0 ? (
                                        <Select
                                          id="select2"
                                          className="search-line"
                                          placeholder="Search..."
                                          isMulti={true}
                                          options={[
                                            {
                                              label: "All",
                                              value: productList,
                                            },
                                            ...productList,
                                          ]}
                                          // options={}
                                          // onChange={(opt) =>
                                          //   setproduct_ids(opt)
                                          // }


                                          onChange={(selectedOptions) => {
                                            if (selectedOptions?.some((opt) => opt.label === "All")) {
                                              setproduct_ids(productList);
                                            } else {
                                              setproduct_ids(selectedOptions); // Set the selected options (Array of selected objects)
                                            }
                                          }}
                                        />
                                      ) : (
                                        <div className="mt-3 text-black">
                                          No any product available.
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="col-md-12 text-center">
                                <br />
                                <div
                                  className="loader"
                                  style={{ display: "none" }}
                                >
                                  <img src="/assets/loader.gif" />
                                </div>
                              </div>
                              <ErrorMessage error={error} />
                              <div className="modal-footer flex-center">
                                <button type="submit" id="kt_modal_new_address_submit" className="btn btn-primary"
                                >
                                  Save
                                </button>
                                <button type="button" className="btn btn-danger m-2" onClick={() => history.goBack()}>
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminAddCoupon);

