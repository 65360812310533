import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Box } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import RevenuBaseChart from "./Chart/RevenuBaseChart";
import Backdrop from '@mui/material/Backdrop';
import useDrawer from "../../../../../context/DrawerContext";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import useError from "../../../../Comman/Error/useError";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";
import { connect } from "react-redux";
import { setMaxDate } from "../../../../Comman/DateUtils/dateUtils";
import * as XLSX from 'xlsx';
import capitalizeFirstLetter from "../../../../Comman/UpperCase";


function GroupAdminRevenueBasedReports({ getBranchList, BranchList_ }) {
  const { error, showError, clearError } = useError();

  const filteredOptions = branchfilterAndMapData(BranchList_);

  let history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState(new Date());

  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState("");
  const [Order_type, setOrder_type] = useState("");
  const [Order_type2, setOrder_type2] = useState([]);
  const [REvenuBaseReport_, setREvenuBaseReport_] = useState("")
  const [Dowloader, setDowloader] = useState(false);
  const [DataReport, setDataReport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [is_present, setis_present] = useState(false);

  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);
  // console.log("branch_id" ,branch_id)

  useEffect(() => {
    getBranchList();

    // setMaxDate(["date", "date1"]); //// Max date validation ////
  }, []);

  const productNamesEN = REvenuBaseReport_?.length > 0 && REvenuBaseReport_?.map(item => item.branch_name_EN);
  const basePrices = REvenuBaseReport_?.length > 0 && REvenuBaseReport_?.map(item => item.total_amount);

  const deliveryTotalAmounts = REvenuBaseReport_?.length > 0 && REvenuBaseReport_.map(item => {
    const deliveryOrder = item.order_type.find(order => order.order_type === "delivery");
    return deliveryOrder ? deliveryOrder.total_amount.total_amount : 0;
  });

  const pickupTotalAmounts = REvenuBaseReport_?.length > 0 && REvenuBaseReport_.map(item => {
    const deliveryOrder = item.order_type.find(order => order.order_type === "pickup");
    return deliveryOrder ? deliveryOrder.total_amount.total_amount : 0;
  });

  const dineInTotalAmounts = REvenuBaseReport_?.length > 0 && REvenuBaseReport_.map(item => {
    const deliveryOrder = item.order_type.find(order => order.order_type === "dineIn");
    return deliveryOrder ? deliveryOrder.total_amount.total_amount : 0;
  });



  const [chartData3, setChartData3] = useState("");



  useEffect(() => {

    const chartData3 = {
      labels: productNamesEN,

      datasets: [
        {
          label: 'Delivery',
          data: deliveryTotalAmounts,
          backgroundColor: 'rgb(127, 86, 217)',
          barThickness: 30,
        },
        {
          label: 'Pickup',
          data: pickupTotalAmounts,
          backgroundColor: 'rgb(247, 144, 9)',
          barThickness: 30,
        },
        {
          label: 'DineIn',
          data: dineInTotalAmounts,
          backgroundColor: 'rgb(20, 201, 201)',
          barThickness: 30,
        },
      ],
    }
    setChartData3(chartData3);
  }, [REvenuBaseReport_?.length > 0])

  const handleConvertToPdf1 = async () => {
    setDowloader(true);

    const doc = new jsPDF();

    // Set PDF properties
    doc.setProperties({
      title: 'My PDF Document',
      subject: 'Branch-wise Report',
      author: 'Your Name',
      keywords: 'PDF, report, branch, table',
    });

    let yPosition = 20; // Initial vertical position for the content


    if (Order_type === "Revenue by payment method") {
      for (const branch of DataReport) {
        // Skip branches with no order items
        if (!branch?.payment_list || branch?.payment_list?.length === 0) {
          continue; // Skip to the next branch if no data is available
        }

        const tableData = [];

        // Add the table header row
        tableData.push(["Payment method", "Amount"]);

        branch?.payment_list.forEach(order => {
          Object.entries(order).forEach(([paymentMethod, amount]) => {
            if (paymentMethod !== "total") { // Skip the "total" key if not needed in this context
              tableData.push([paymentMethod, Number(amount)]);
            }
          });

          // Optionally, add a row for the total amount
          tableData.push(["Total", order.total]);
        });

        // Display branch name and other info
        doc.setFont("Inter");
        doc.setFontSize(12);
        doc.text(`Branch: ${branch?.branch_name}`, 15, yPosition);
        yPosition += 6;
        doc.text(`Address : ${branch?.branch_address}`, 15, yPosition);
        yPosition += 6;
        doc.text(`Currency : ${branch?.currency}`, 15, yPosition);
        yPosition += 6;
        doc.text(Order_type, 15, yPosition);
        yPosition += 6;
        doc.text(
          `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`,
          15, yPosition
        );
        yPosition += 12;

        // Draw border on every page
        doc.setDrawColor(0); // Set border color (black)
        doc.setLineWidth(0.3); // Set border line width
        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

        // Define table styling and add table for the branch
        doc.autoTable({
          head: [tableData[0]], // Table header
          body: tableData.slice(1), // Table rows
          startY: yPosition,
          theme: 'grid',
          headStyles: {
            fillColor: [192, 192, 192], // Header background color
            textColor: [0, 0, 0], // Header text color
          },
          margin: { left: 15 },
          styles: { fontSize: 10 },
        });

        // Update yPosition based on the table's finalY
        yPosition = doc.autoTable.previous.finalY + 10;


        // Add a page break if content reaches near the page's end
        if (yPosition > doc.internal.pageSize.getHeight() - 30) {
          doc.addPage();
          yPosition = 20; // Reset yPosition for new page
        }
      }
    } else if (Order_type === "Revenue by date") {
      for (const branch of DataReport) {
        // Skip branches with no order items
        if (!branch?.payment_list || branch?.payment_list?.length === 0) {
          continue; // Skip to the next branch if no data is available
        }

        const tableData = [];

        // Add the table header row
        tableData.push(["Date", "Delivery amount", "Pickup amount", "Dinein amount", "Total"]);

        branch?.payment_list?.forEach(order => {
          const { Date, delivery, dineIn, pickup, total } = order;
          const orderRow = [Date, Number(delivery), Number(pickup), Number(dineIn), Number(total)];
          tableData.push(orderRow);
        });

        // Display branch name and other info
        doc.setFont("Inter");
        doc.setFontSize(12);
        doc.text(`Branch: ${branch?.branch_name}`, 15, yPosition);
        yPosition += 6;
        doc.text(`Address : ${branch?.branch_address}`, 15, yPosition);
        yPosition += 6;
        doc.text(`Currency : ${branch?.currency}`, 15, yPosition);
        yPosition += 6;
        doc.text(Order_type, 15, yPosition);
        yPosition += 6;
        doc.text(
          `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`,
          15, yPosition
        );
        yPosition += 12;

        // Draw border on every page
        doc.setDrawColor(0); // Set border color (black)
        doc.setLineWidth(0.3); // Set border line width
        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

        // Define table styling and add table for the branch
        doc.autoTable({
          head: [tableData[0]], // Table header
          body: tableData.slice(1), // Table rows
          startY: yPosition,
          theme: 'grid',
          headStyles: {
            fillColor: [192, 192, 192], // Header background color
            textColor: [0, 0, 0], // Header text color
          },
          margin: { left: 15 },
          styles: { fontSize: 10 },
        });

        // Update yPosition based on the table's finalY
        yPosition = doc.autoTable.previous.finalY + 10;


        // Add a page break if content reaches near the page's end
        if (yPosition > doc.internal.pageSize.getHeight() - 30) {
          doc.addPage();
          yPosition = 20; // Reset yPosition for new page
        }
      }
    } else if (Order_type === "Revenue by employee tip") {

      for (const branch of DataReport) {
        // Skip branches with no order items
        if (!branch?.payment_list || branch?.payment_list?.length === 0) {
          continue; // Skip to the next branch if no data is available
        }

        const tableData = [];

        // Add the table header row
        tableData.push(["Employee name", "Employee ID", "Tip Amount"]);

        branch?.payment_list?.forEach(order => {
          const { closing_waiter_name, closing_waiter_staff_id, totalTipAmount} = order;
          const orderRow = [closing_waiter_name,closing_waiter_staff_id, Number(totalTipAmount),];
          tableData.push(orderRow);
        });

        // Display branch name and other info
        doc.setFont("Inter");
        doc.setFontSize(12);
        doc.text(`Branch: ${branch?.branch_name}`, 15, yPosition);
        yPosition += 6;
        doc.text(`Address : ${branch?.branch_address}`, 15, yPosition);
        yPosition += 6;
        doc.text(`Currency : ${branch?.currency}`, 15, yPosition);
        yPosition += 6;
        doc.text(Order_type, 15, yPosition);
        yPosition += 6;
        doc.text(
          `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`,
          15, yPosition
        );
        yPosition += 12;

        // Draw border on every page
        doc.setDrawColor(0); // Set border color (black)
        doc.setLineWidth(0.3); // Set border line width
        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

        // Define table styling and add table for the branch
        doc.autoTable({
          head: [tableData[0]], // Table header
          body: tableData.slice(1), // Table rows
          startY: yPosition,
          theme: 'grid',
          headStyles: {
            fillColor: [192, 192, 192], // Header background color
            textColor: [0, 0, 0], // Header text color
          },
          margin: { left: 15 },
          styles: { fontSize: 10 },
        });

        // Update yPosition based on the table's finalY
        yPosition = doc.autoTable.previous.finalY + 10;


        // Add a page break if content reaches near the page's end
        if (yPosition > doc.internal.pageSize.getHeight() - 30) {
          doc.addPage();
          yPosition = 20; // Reset yPosition for new page
        }
      }
    }
    // Save the PDF after processing all branches
    const fileName = `${Order_type} Report ${moment(new Date()).format("DD-MM-YYYY")}.pdf`;
    doc.save(fileName);
    setDowloader(false);
  };

  const handleConvertToPdf = async () => {
    setDowloader(true);
    const doc = new jsPDF();
  
    // Set PDF properties
    doc.setProperties({
      title: 'My PDF Document',
      subject: 'Branch-wise Report',
      author: 'Your Name',
      keywords: 'PDF, report, branch, table',
    });
  
    let yPosition = 20; // Initial vertical position for the content
  
    const formatDateRange = () => 
      `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`;
  
    const addBranchInfo = (branch) => {
      // doc.addImage("/assets/media/logos/Logo_Dark.png", 'PNG',  165, 15, 30, 15, 'FAST');

      doc.setFont("Inter");
      doc.setFontSize(12);
      doc.text(`Branch: ${branch?.branch_name}`, 15, yPosition);
      yPosition += 6;
      doc.text(`Address: ${branch?.branch_address}`, 15, yPosition);
      yPosition += 6;
      doc.text(`Currency: ${branch?.currency}`, 15, yPosition);
      yPosition += 6;
      doc.text(Order_type, 15, yPosition);
      yPosition += 6;
      doc.text(formatDateRange(), 15, yPosition);
      yPosition += 12;
    };
  
    const addBorder = () => {
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);
    };
  
    const addTableToPdf = (tableData) => {
      doc.autoTable({
        head: [tableData[0]],
        body: tableData.slice(1),
        startY: yPosition,
        theme: 'grid',
        headStyles: {
          fillColor: [192, 192, 192],
          textColor: [0, 0, 0],
        },
        margin: { left: 15 },
        styles: { fontSize: 10 },
      });
      yPosition = doc.autoTable.previous.finalY + 10;
    };
  
    const generateTableData = (branch) => {
      const tableData = [];
      switch (Order_type) {
        case "Revenue by payment method":
          tableData.push(["Payment method", "Amount"]);
          branch?.payment_list.forEach(order => {
            Object.entries(order).forEach(([paymentMethod, amount]) => {
              let method;
              if (paymentMethod === "COD") {
                method = "Cash";
              }else if (paymentMethod === "total") {
                method = "Total"
              }else {
                method = paymentMethod;
              }
              tableData.push([method, Number(amount).toFixed(2)]);
            });            
            // tableData.push(["Total", Number(order.total).toFixed(2)]);
          });
          break;
        case "Revenue by date":
          tableData.push(["Date", "Delivery amount", "Pickup amount", "Dinein amount", "Total"]);
          branch?.payment_list?.forEach(order => {
            const { Date, delivery, pickup, dineIn, total } = order;
            tableData.push([Date, Number(delivery), Number(pickup), Number(dineIn), Number(total)]);
          });
          break;
        case "Revenue by employee tip":
          tableData.push(["Employee name", "Employee ID", "Tip Amount"]);
          branch?.payment_list?.forEach(order => {
            const { closing_waiter_name, closing_waiter_staff_id, totalTipAmount } = order;
            tableData.push([closing_waiter_name, closing_waiter_staff_id, Number(totalTipAmount)]);
          });
          break;
      }
      return tableData;
    };
  
    for (const branch of DataReport) {
      if (!branch?.payment_list || branch?.payment_list?.length === 0) continue;
  
      addBranchInfo(branch);
      addBorder();
  
      const tableData = generateTableData(branch);
      addTableToPdf(tableData);
  
      if (yPosition > doc.internal.pageSize.getHeight() - 30) {
        doc.addPage();
        yPosition = 20;
      }
    }
  
    const fileName = `${Order_type} Report ${moment(new Date()).format("DD-MM-YYYY")}.pdf`;
    doc.save(fileName);
    setDowloader(false);
  };

  
  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);
    setDataReport([]);

    // Validate required fields
    if (!branch_id) {
      showError("Please select branch.");
      return;
    }
    if (!Order_type) {
      showError("Please select revenue type.");
      return;
    }
    if (!start_date) {
      showError("Please select start date.");
      return;
    }

    // Check date logic
    const endDateFormatted = moment(end_date).add(1, "days").format("YYYY-MM-DD");
    const startDateFormatted = moment(start_date).format("YYYY-MM-DD");

    if (Date.parse(end_date) && Date.parse(endDateFormatted) <= Date.parse(start_date)) {
      showError("End date should be greater than Start date");
      return;
    }

    setLoader(true);
    // Define the basePath dynamically based on Order_type
    let basePath;
    switch (Order_type) {
      case "Revenue by payment method":
        basePath = "/groupadmin/report/revenueByPaymentMethod";
        fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted);
        break;

      case "Revenue by date":
        basePath = "/groupadmin/report/revenuebyDate";
        fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted);
        break;
        
      case "Revenue by employee tip":
        basePath = "/groupadmin/report/revenuebyEmployeeTip";
        fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted);
        break;
      default:
        setLoader(false);
        break;
    }
  }


  function fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted) {
    let url = `${basePath}?start_date=${startDateFormatted}`;

    if (branch_id !== "All") {
      url += `&branch_id=${branch_id}`;
    }
    if (endDateFormatted) {
      url += `&end_date=${endDateFormatted}`;
    }

    api.get(url, { headers })
      .then((res) => {
        setLoader(false);
        const res_ = res?.data?.data?.filter(branch => branch?.payment_list?.length > 0);
        setDataReport(res_ || []);
        setis_present(!(res_?.length > 0));
      })
      .catch((err) => {
        setLoader(false);
        if (err.response) {
          setis_present(true);
          // showError(err.response?.data?.error?.message);
        }
      });
  }


  const handleClickCSVDownload = () => {
    if (!Array.isArray(DataReport)) {
      console.error("DataReport is not an array:", DataReport);
      return;
    }

    if (Order_type === "Revenue by payment method") {
      const wb = XLSX.utils.book_new();

      DataReport.forEach(branch => {

        if (!branch?.payment_list || branch?.payment_list.length === 0) return; // Skip branches without orders

        const Header1 = [{
          v: `Generated by Gurjinder Singh ${(moment(new Date()).format('DD-MM-YYYY h:mm:ss a'))}. All Figures in ${branch?.currency} except where noted`,
        }];
        const Header4 = [];
        const Header8 = [];
        const secondRowHeader = ["Payment method", "Amount"];

        const Header2 = [`${branch?.branch_address} - ${branch?.branch_name}`];
        const Header6 = [`Start date : ${moment(start_date).format("DD/MM/YYYY")}`];
        const Header7 = [`End date : ${moment(end_date).format("DD/MM/YYYY")}`];

        const wsData = [
          Header1, Header2, Header4, Header6, Header7, Header8, secondRowHeader
        ];

        branch?.payment_list.forEach(order => {
          Object.entries(order).forEach(([paymentMethod, amount]) => {
            let method;
              if (paymentMethod === "COD") {
                method = "Cash";
              }else if (paymentMethod === "total") {
                method = "Total"
              }else {
                method = paymentMethod;
              }
              wsData.push([method, Number(amount).toFixed(2)]);
          });

          // Optionally, add a row for the total amount
          // wsData.push(["Total", order.total]);
        });

        // Create a worksheet for the current branch
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Add the worksheet to the workbook with branch name as sheet name
        XLSX.utils.book_append_sheet(wb, ws, branch.branch_name || "Branch");
      });
      // Save the workbook
      XLSX.writeFile(wb, 'Revenue by payment method.xlsx');
    } else if (Order_type === "Revenue by date") {
      const wb = XLSX.utils.book_new();

      DataReport.forEach(branch => {

        if (!branch?.payment_list || branch?.payment_list.length === 0) return; // Skip branches without orders

        const Header1 = [{
          v: `Generated by Gurjinder Singh ${(moment(new Date()).format('DD-MM-YYYY h:mm:ss a'))}. All Figures in ${branch?.currency} except where noted`,
        }];
        const Header4 = [];
        const Header8 = [];
        const secondRowHeader = ["Date", "Delivery amount", "Pickup amount", "Dinein amount", "Total"];

        const Header2 = [`${branch?.branch_address} - ${branch?.branch_name}`];

        const wsData = [
          Header1, Header2, Header4, Header8, secondRowHeader
        ];

        branch?.payment_list?.forEach(order => {
          const { Date, delivery, dineIn, pickup, total } = order;
          const orderRow = [Date, Number(delivery), Number(pickup), Number(dineIn), Number(total)];
          wsData.push(orderRow);
        });

        // Create a worksheet for the current branch
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Add the worksheet to the workbook with branch name as sheet name
        XLSX.utils.book_append_sheet(wb, ws, branch.branch_name || "Branch");
      });
      // Save the workbook
      XLSX.writeFile(wb, 'Revenue by date.xlsx');
    } else if (Order_type === "Revenue by employee tip") {
      const wb = XLSX.utils.book_new();

      DataReport.forEach(branch => {
        if (!branch?.payment_list || branch?.payment_list.length === 0) return; // Skip branches without orders

        const Header1 = [{
          v: `Generated by Gurjinder Singh ${moment(new Date()).format('DD-MM-YYYY h:mm:ss a')}. All Figures in ${branch?.currency} except where noted`,
        }];
        const Header2 = [`${branch?.branch_address} - ${branch?.branch_name}`];
        const Header6 = [`Start date : ${moment(start_date).format("DD/MM/YYYY")}`];
        const Header7 = [`End date : ${moment(end_date).format("DD/MM/YYYY")}`];
        const secondRowHeader = ["Employee name", "Employee ID", "Tip Amount"];

        const wsData = [Header1, Header2, [], Header6, Header7, [], secondRowHeader];

        branch?.payment_list?.forEach(order => {
          const { closing_waiter_name, closing_waiter_staff_id, totalTipAmount} = order;
          const orderRow = [closing_waiter_name,closing_waiter_staff_id, Number(totalTipAmount),];
          wsData.push(orderRow);
        });

        // Create a worksheet for the current branch
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Add the worksheet to the workbook with branch name as sheet name
        XLSX.utils.book_append_sheet(wb, ws, branch?.branch_name || "Branch");
      });

      // Save the workbook
      XLSX.writeFile(wb, 'Revenue by employee tip.xlsx');
    }

  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();



  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className={isMobile ? "mx-5 p-0" : "mx-7 p-0"}>
                  <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>
                    <div className="d-flex flex-stack mb-2 p-0">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h2
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Revenue Reports
                        </h2>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.push("/group_admin/report")}
                            className="btn btn-primary ps-7"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="py-2">
                      <Card style={{ border: "1px solid #bdbcbc" }}>
                        <Card.Body>
                          <Row >
                            <Col lg={6} md={6} sx={6}>
                              <label className="required form-label">
                                Select Branch
                              </label>
                              <Select
                                // styles={{ backgroundColor: "#f5f8fa" }}
                                className="search-line"
                                placeholder="Search..."
                                // options={[
                                //   {
                                //     label: "All",
                                //     value: "All",
                                //   },
                                //   ...filteredOptions,
                                // ]}

                                // placeholder={getPlaceholder(filteredOptions)}
                                options={getSelectOptions(filteredOptions)}
                                value={branch_id2}
                                onChange={(opt) => {
                                  if (opt.value === "All") {
                                    setbranch_id(opt.value);
                                  } else {
                                    setbranch_id(opt.value?.value);
                                  }
                                  setbranch_id2(opt);
                                  setDataReport([]);
                                }}
                                styles={selectStylesReport}
                              />
                            </Col>

                            <Col lg={6} md={6} sx={6}>
                              <label className="required form-label">
                                Select Revenue Type
                              </label>
                              <Select
                                styles={selectStylesReport}
                                className="search-line"
                                placeholder="Search..."
                                options={[
                                  {
                                    label: "Revenue by payment method",
                                    value: "Revenue by payment method",
                                  },
                                  {
                                    label: "Revenue by date",
                                    value: "Revenue by date",
                                  },
                                  // {
                                  //   label: "Revenue by discount name",
                                  //   value: "Revenue by discount name",
                                  // },
                                  {
                                    label: "Revenue by employee tip",
                                    value: "Revenue by employee tip",
                                  },
                                ]}
                                value={Order_type2}
                                onChange={(opt) => {
                                  setOrder_type(opt.value);
                                  setOrder_type2(opt);
                                  setDataReport([]);
                                }}
                              /></Col>

                          </Row>

                          <div className="row mt-7" >
                            <div className={isTabletOrMobile ? "col-md-4" : "col-md-2"} >
                              <label className="required form-label">
                                Start Date
                              </label>
                              <input
                                type="date"
                                name="date"
                                id="date"
                                className="form-control"
                                value={start_date}
                                onChange={(e) => {
                                  setstart_date(e.target.value);
                                  setDataReport([]);
                                }}
                              />
                            </div>
                            <div className={isTabletOrMobile ? "col-md-4" : "col-md-2"}>
                              <label className="form-label">End Date</label>
                              <input
                                type="date"
                                name="date1"
                                id="date1"
                                className="form-control"
                                value={end_date}
                                onChange={(e) => {
                                  setend_date(e.target.value);
                                  setDataReport([]);
                                }}
                              />
                            </div>
                            <div className={isTabletOrMobile ? "col-md-4 mt-8 text-end" : "col-md-3 mt-8"} >
                              {loader ? (
                                <Box>
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  type="button"
                                  className="btn btn-secondary"
                                >
                                  <span className="indicator-label">
                                    <i className="fa fa-eye fs-2"></i>&nbsp;
                                    Preview</span>
                                </button>
                              )}

                            </div>
                            <div className={isTabletOrMobile ? "col-md-12 mt-8 text-end" : "col-md-5 mt-9 text-end"}>
                              {DataReport?.length > 0 &&
                                <React.Fragment>
                                  {Dowloader ?
                                    <Backdrop
                                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                      open={true}
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop> :
                                    <button
                                      onClick={handleConvertToPdf}
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      <span className="indicator-label">
                                        <i className="fa fa-file-pdf-o fs-3"></i>&nbsp;Download PDF</span>
                                    </button>}
                                  &nbsp;&nbsp;
                                  <button
                                    onClick={handleClickCSVDownload}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    <span className="indicator-label">

                                      <i className="fa fa-file-excel-o fs-3"></i>&nbsp;Download CSV</span>
                                  </button>
                                </React.Fragment>}
                            </div>
                          </div>
                          <br />
                          <ErrorMessage error={error} />
                        </Card.Body>
                      </Card>


                      {is_present &&
                        <Card style={{ border: "1px solid rgb(189, 188, 188)" }} className="mt-10 my-4 text-center">
                          <Row className="my-5 text-center">
                            <Col className="my-4">
                              <h5>
                                No Data for the selected Date or Filter. Please select a different Date or Filter.
                              </h5>
                            </Col>
                          </Row>
                        </Card>}

                      {/* {REvenuBaseReport_?.length > 0 &&
                        <div>

                          <div id="contentToConvert" >
                            <RevenuBaseChart chartData={chartData3} daynamicName={Order_type} />
                          </div>

                        </div>} */}

                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminRevenueBasedReports);


