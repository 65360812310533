import React from 'react';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const renderStatusChip = (status) => {
  let color = '';
  let label = '';
  let background = '';

  switch (status) {
    case true:
      color = '#027A48'; // You can use any color from MUI's palette
      label = 'Active';
      background = '#ECFDF3';
      break;
    case false:
      background = '#FFFAEB'; // You can use any color from MUI's palette
      label = 'Inactive';
      color = "#B54708";
      break;
    // case "active":
    //   color = '#027A48';
    //   label = 'Active';
    //   background = '#ECFDF3';
    //   break;
    // case "trial":
    //   background = '#EFF8FF';
    //   label = 'Trial';
    //   color = "#175CD3";
    //   break;
    // case "pending":
    //   background = '#FFFAEB';
    //   label = 'Pending';
    //   color = "#B54708";
    //   break;
    // case "expired":
    //   background = '#FEF3F2';
    //   label = 'Expired';
    //   color = "#B42318";
    //   break;
    default:
      color = 'default'; // You can use any color from MUI's palette
      label = 'Unknown';
      break;
  }

  return (
    <Chip
      icon={<FiberManualRecordIcon style={{ color: color, fontSize: 13 }} />}
      label={label}
      style={{ color: color, background: background, fontSize: 13 }}
    />
  );
};

export default renderStatusChip;
