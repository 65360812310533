import React, { useEffect, useMemo, useRef, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { tableOptionEmailMarketing } from "../../../NewTableCommon/tableOption";
import moment from "moment/moment";
import { HeaderStyle } from ".././OrderManagement/TableCommonModule";
import { getMenuList } from "../../../../Store/actions/menu/menu-action";
import { connect } from "react-redux";
import { branchfilterAndMapData } from "../../../Comman/BranchList";
import { getBranchList, getEmailMarketingList } from "../../../../Store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import TableFilter from "../../../NewTableCommon/tableFilter";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import renderchipsEmailMarketing from "./renderchips";
import useDrawer from "../../../../context/DrawerContext";
import { ArrowDownloadFilled } from '@fluentui/react-icons';

// import "./menuManagmnet.css"


function GroupAdminEmailMarketing({ getMenuList, menuList, BranchList_, }) {
      var pathname = window.location.pathname;
      var pathnamesplits = pathname.split("/");
      var url23 = pathnamesplits[2];
      const [data, setData] = useState([]);

      const windowHeight = useWindowHeight();
      const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));

      const filterButtonColor = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));

      // console.log("filterData" ,filterData)

      const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);

      const filteredOptions = branchfilterAndMapData(BranchList_);
      const history = useHistory();
      const [BranchId, setBranchID] = useState("");
      const [CopyBranchId, setCopyBranchID] = useState("");
      const [menu_name, setmenu_name] = useState("");
      const [menuId, setmenuId] = useState("");
      const [error, setError] = useState(null);
      const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover
      const [columnFilters, setColumnFilters] = useState([]);
      const [globalFilter, setGlobalFilter] = useState('');
      const [sorting, setSorting] = useState([]);
      const [rowCount, setRowCount] = useState(0);
      const [pagination, setPagination] = useState("");
      const [isError, setIsError] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [FilterData, SetFilterData] = useState(filterData ? filterData : "");
      const [NewFilterData, SetNewFilterData] = useState("");
      const [filterAnchorEl, setFilterAnchorEl] = useState(null);
      const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
      const [CSVData, setCSVData] = useState([])
      const prevSorting = useRef([]);
      const [CSVLoader, setCSVLoader] = useState(false);


      const storedState = sessionStorage.getItem(`${url23}_paginationStateEmailMarketing`);

      useEffect(() => {
            if (storedState !== null) {
                  setPagination(JSON.parse(storedState));
            } else {
                  setPagination({ pageIndex: 0, pageSize: 10 });
            }
      }, [storedState]);

      const handleFilterClick = (event) => {
            setFilterAnchorEl(event.currentTarget);
            setIsFilterPopoverOpen(true);
      };

      const handleFilterClose = () => {
            setIsFilterPopoverOpen(false);
      };

      const handleApplyFilters = (filterData) => {
            setIsFilterPopoverOpen(false);
            SetFilterData(filterData)
      };


      const getEmailMarketingList1 = async () => {
            setData([]);
            setIsLoading(true);
            setIsError(false)
            let url = `/groupadmin/offers/listUserswithOffers/?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;

            let formData = new FormData();

            // Handle sorting parameters
            if (sorting?.length > 0 && sorting[0]?.id) {
                  let sortField;
                  switch (sorting[0]?.id) {
                        case "branchname":
                              sortField = 'branch.branch_name_EN';
                              break;
                        case "datetime":
                              sortField = 'order_date';
                              break;
                        default:
                              sortField = sorting[0]?.id;
                              break;
                  }
                  formData.append('sort_field', sortField);
                  formData.append('sort_type', sorting[0]?.desc ? '-1' : '1');
            }

            // Add global filter to FormData if it exists
            if (globalFilter) {
                  formData.append('search', globalFilter ?? '');
            }

            formData.append('want_to_recieve_offers', filterData ? filterData?.Status?.value === "Subscribed" ? true : false : true);
            // if (filterData?.Status?.value) {
            // }
            try {
                  const res = await api.post(url, formData, { headers });
                  const tableData = res?.data?.users;
                  setData(tableData);
                  setRowCount(res?.data?.count)
            } catch (err) {
                  setIsError(true);
                  setData([]);
            } finally {
                  setIsLoading(false);
            }
      };

      const getCSVDate = async () => {
            setCSVLoader(true);
            let url = `/groupadmin/offers/listUserswithOffers/?page=${pagination.pageIndex}&per_page=${rowCount + 1}`;

            let formData = new FormData();

            // Handle sorting parameters
            // if (sorting?.length > 0 && sorting[0]?.id) {
            //       let sortField;
            //       switch (sorting[0]?.id) {
            //             case "branchname":
            //                   sortField = 'branch.branch_name_EN';
            //                   break;
            //             case "datetime":
            //                   sortField = 'order_date';
            //                   break;
            //             default:
            //                   sortField = sorting[0]?.id;
            //                   break;
            //       }
            //       formData.append('sort_field', sortField);
            //       formData.append('sort_type', sorting[0]?.desc ? '-1' : '1');
            // }

            // Add global filter to FormData if it exists
            // if (globalFilter) {
            //       formData.append('search', globalFilter ?? '');
            // }

            formData.append('want_to_recieve_offers', filterData ? filterData?.Status?.value === "Subscribed" ? true : false : true);

            try {
                  const res = await api.post(url, formData, { headers });
                  const tableData = res?.data?.users;
                  setCSVData(tableData);
                  setCSVLoader(false);
            } catch (err) {
                  setCSVLoader(false);
                  setCSVData([]);
            } finally {
                  setCSVLoader(false);
            }
      };

      function arraysAreEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                  if (arr1[i] !== arr2[i]) return false;
            }
            return true;
      }

      const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
      prevSorting.current = sorting;


      useEffect(() => {
            if (pagination.pageIndex, pagination.pageSize) {
                  getEmailMarketingList1();
            }
      }, [pagination.pageIndex, pagination.pageSize, sortingChanged, filterData?.Status?.value, globalFilter ?? '']);


      useEffect(() => {
            if (rowCount > 0) {
                getCSVDate();
            }
        }, [rowCount, filterData?.Status?.value]);

      const prevPaginationRef = useRef();

      useEffect(() => {
            if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
                  sessionStorage.setItem(`${url23}_paginationStateEmailMarketing`, JSON.stringify(pagination));
            }
            prevPaginationRef.current = pagination;
      }, [pagination, rowCount]);


      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const onColumnDataChange = () => {
            setUpdateColumns(prevState => !prevState);
      };

      const handleClickColum = (event) => {
            setAnchorEl(event.currentTarget);
      };

      const handleCloseColum = () => {
            setAnchorEl(null);
      };

      function handleSubmitActive(item) {

            api
                  .post(`/groupadmin/offers/switchUserSubscription`, item, { headers })
                  .then((res) => {
                        Swal.fire({
                              position: "top-center",
                              icon: "success",
                              title: `User status has been ${item?.want_to_recieve_offers ? "Subscribed" : "Unsubscribed"} succesfully.`,
                              showConfirmButton: false,
                              timer: 1500,
                        });
                        getEmailMarketingList1()
                  })
                  .catch((err) => {
                        if (err.response) {
                              toast.error(err?.response?.data?.error?.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                              });
                        }
                  });
      }

      const Columns = useMemo(() => {
            const columns = [
                  {
                        accessorKey: 'name',
                        header: 'Customer Name',
                        size: 300,
                        Cell: ({ row }) => row?.original?.name || '---',
                  },
                  {
                        accessorKey: 'email',
                        header: 'Customer Email',
                        size: 270,
                        Cell: ({ row }) => row?.original?.email || '---',
                  },
                  {
                        accessorKey: 'number',
                        header: 'Customer Number',
                        size: 270,
                        Cell: ({ row }) => row?.original?.number || '---',
                  },
                  {
                        accessorKey: 'want_to_recieve_offers',
                        header: 'Status',
                        size: 270,
                        Cell: ({ row }) => renderchipsEmailMarketing(row?.original?.want_to_recieve_offers),
                  }
            ];

            return dineInColumnsData === null ? columns : columns.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);

      const commonOptions = {
            columnFilters,
            globalFilter,
            sorting,
            setColumnFilters,
            setGlobalFilter,
            setPagination,
            setSorting,
            rowCount,
            pagination,
            handleFilterClick,
            handleClickColum,
            windowHeight,
            getMenuList,
            url23,
            handleSubmitActive,
            filterData,
            DisplayCol,
            getEmailMarketingList1,
            downloadCSV,
            CSVLoader,
            CSVData

      };

      const table = useMaterialReactTable({
            columns: Columns,
            data: data,
            ...tableOptionEmailMarketing(commonOptions),
      });


      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();


      function convertToCSV(data) {
            const headers = ["Customer Name", "Customer Email", "Customer Number", "Status"];
            const csvData = [headers.join(",")];

            data.forEach((item) => {
                  const { name, email, number, want_to_recieve_offers } = item;
                  const formattedRow = [
                        name,
                        email,
                        number,
                        want_to_recieve_offers === true ? "Subscribed" : "Unsubscribed"
                  ].join(",");
                  csvData.push(formattedRow);
            });

            return csvData.join("\n");
      }

      function downloadCSV(data, file) {
            const csvContent = convertToCSV(data);

            const blob = new Blob([csvContent], { type: "text/csv" });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = file

            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
      }


      return (
            <React.Fragment>
                  <ToastContainer
                        toastStyle={{
                              border: "1px solid black",
                              fontSize: "17px",
                        }}
                  />
                  <div
                        id="kt_body"
                        style={{ marginTop: '-30px' }}
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid p-0"
                                                id="kt_content"
                                          >
                                                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <div className="row ">
                                                                  <div className="col-md-6">
                                                                        <div
                                                                              className="d-flex justify-content-start"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                                                                                    Email Marketing
                                                                              </h1>
                                                                              {/* <div style={{width: '2px', height: '33px', backgroundColor: '#D0D5DD'   }} className="mx-4 mt-2" />

                                                                        <Select
                                                                              className="search-line mx-3"
                                                                              placeholder="All Locations"
                                                                              value={BranchId}
                                                                              options={[
                                                                                    {
                                                                                          label: "All Locations",
                                                                                          value: "all",
                                                                                    },
                                                                                    ...filteredOptions,
                                                                              ]}
                                                                              onChange={(opt) => {
                                                                                    setBranchID(opt);
                                                                              }}
                                                                              styles={{
                                                                                    control: (provided) => ({
                                                                                      ...provided,
                                                                                      borderRadius: "8px",
                                                                                      height  :"30px",
                                                                                      marginTop  :"5px"
                                                                                    }),
                                                                                    menu: (provided) => ({
                                                                                      ...provided,
                                                                                      zIndex: 9999
                                                                                    })
                                                                                  }}
                                                                        /> */}



                                                                        </div>
                                                                  </div>
                                                                  <div className="col-md-6 text-end">
                                                                        <div
                                                                              className="d-flex justify-content-end"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              {/* {CSVData?.length > 0 &&
                                                                                    <button
                                                                                          type="button"
                                                                                          className="btn btn-primary"
                                                                                          onClick={() => downloadCSV(CSVData, `${"Email Marketing Data"} ${moment(new Date()).format(
                                                                                                "DD-MM-YYYY"
                                                                                          )}.csv`)}
                                                                                    >
                                                                                          Download CSV
                                                                                    </button>} */}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <hr style={{ opacity: 0.07, marginTop: 7 }} />
                                                            <div className="mt-5">
                                                                  <MaterialReactTable
                                                                        table={table}
                                                                  />
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>

                  <ColumPopoverCommon
                        anchorEl_={anchorEl}
                        handleCloseColum_={handleCloseColum}
                        onColumnDataChange={onColumnDataChange}
                        url23={url23}
                  />


                  <TableFilter
                        open={isFilterPopoverOpen}
                        anchorEl={filterAnchorEl}
                        handleClose={handleFilterClose}
                        handleFilter={handleApplyFilters}
                        url23={url23}
                  />


            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      menuList: state.menuList,
      BranchList_: state.branchList,
      emailMarketingList: state.emailMarketingList
});

const mapDispatchToProps = (dispatch) => {
      return {
            getMenuList: () => dispatch(getMenuList()),
            getBranchList: () => dispatch(getBranchList()),


            getEmailMarketingList: () => dispatch(getEmailMarketingList())
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminEmailMarketing);

