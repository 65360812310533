import Swal from "sweetalert2";
import api from "../../../../../CommonApi/axios";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { showToastCommon } from "../../../../Comman/Toastify/Toastify";
import headers from "../../../../../CommonApi/headers";


export function deleteTourgroupSetting(url, successMessage, callback  ,item) {

  console.log("url" ,url , item)
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      api
        .post(url,  item, { headers })
        .then(() => {
            showSuccessNotification(successMessage)
            if (callback) {
                  callback();
            }
        })
        .catch((err) => {
          if (err.response) {
            showToastCommon(err?.response?.data?.error?.message);
          }
        });
    }
  });
}

