import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import Select from "react-select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDrawer from "../../../../context/DrawerContext";
import useError from "../../../Comman/Error/useError";
import headers from "../../../../CommonApi/headers";
import { submitForm } from "../../../Comman/AddCommonFun/AddCommonFunction";
import { ErrorMessage } from "../../../Comman/Error/ErrorMessage";
import { SubmitformButton1 } from "../../../Comman/Error/SubmitformButton";
import { getInventoryCatList } from "../../../../Store";
import { connect } from "react-redux";
import convert from 'convert-units';
import { unitFullForms } from "../StockManagement/unit";



function AddStockProduct({ getInventoryCatList, inventoryCategoryList_ }) {
      const history = useHistory();
      const { error, showError, clearError } = useError();
      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
      const { productid, catagoryid, menuId, branchID } = useParams();

      // console.log("productid" ,productid)
      // console.log("catagoryid" ,catagoryid)
      // console.log("menuId" ,menuId)
      // console.log("id" ,id)
      // console.log("branchID" ,branchID)


      const commonSelectStyles = {
            control: (provided) => ({
                  ...provided,
                  borderRadius: "8px",
                  height: '41px',  // Adjust this value to your desired height
                  minHeight: '41px',
                  border: "1px solid #e4e6ef"
            }),
            menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
            })
      };

      useEffect(() => {
            getInventoryCatList();
      }, []);

      const [category, setCategory] = useState("");

      const [ProductList, setProductList] = useState([]);
      const [errornew, seterrornew] = useState("")
      // console.log("category", category) ;
      // console.log("ProductList", ProductList)




      const handleCategoryChange = (selectedOption) => {
            setCategory(selectedOption);
            clearError(null);
            // console.log("selectedOption", selectedOption)

            setProductList([]);
            getStockList(selectedOption)
      };


      const getStockList = async (selectedOption) => {
            setProductList([]);
            seterrornew(null)
            let url = `/groupadmin/branch_stock/listProduct?branch_id=${branchID}&category_id=${selectedOption?.value}`;
            try {
                  const res = await api.get(url, { headers });
                  // setRowCount(res.data.data?.count || 0);
                  // console.log("res", res.data.data)

                  // const result = res.data.data?.stockData?.filter(main => main?.total_quantity > 0).map(main => ({
                  //       _id: main?._id,
                  //       setunit: convert()?.from(main?.unit)?.possibilities()?.map(unit => ({
                  //             label: `${unit} (${unit})`,
                  //             value: unit
                  //       })),
                  //       quantity: "",
                  //       is_active: false,
                  //       name: main?.inv_product_name,
                  //       unit: ""
                  // }));

                  // const result = res.data.data?.stockData
                  //       ?.filter(main => main?.total_quantity > 0)
                  //       .map(main => ({
                  //             _id: main?._id,
                  //             setunit: main?.unit === "number"
                  //                   ? [{ label: 'No (numbers)', value: "number" }]
                  //                   : (main?.unit === "ml" || main.unit === "l") ? [
                  //                         ...convert()
                  //                               ?.from(main?.unit)
                  //                               ?.possibilities()
                  //                               ?.filter(unit => unit === 'ml' || unit === 'l') // Include only 'ml' and 'l'
                  //                               .map(unit => ({
                  //                                     label: `${unit}`,
                  //                                     value: unit
                  //                               }))
                  //                   ] : [
                  //                         ...convert()?.from(main?.unit)?.possibilities()?.map(unit => ({
                  //                               label: `${unit}`,
                  //                               value: unit
                  //                         }))
                  //                   ],
                  //             quantity: "",
                  //             is_active: false,
                  //             name: main?.inv_product_name,
                  //             unit: ""
                  //       }));


                  const result = res.data.data?.stockData
                        ?.filter(main => main?.total_quantity > 0)
                        .map(main => ({
                              _id: main?._id,
                              setunit: main?.unit === "number"
                                    ? [{ label:`no (${unitFullForms["no"]})`, value: "number" }]
                                    : convert()
                                          ?.from(main?.unit)
                                          ?.possibilities()
                                          ?.filter(unit => main.unit === "ml" || main.unit === "l" ? (unit === 'ml' || unit === 'l') : true)
                                          .map(unit => ({
                                                label: `${unit} (${unitFullForms[unit] || unit})`,
                                                value: unit
                                          })),
                              quantity: "",
                              is_active: false,
                              name: main?.inv_product_name,
                              unit: ""
                        }));


                  // console.log("result", result)


                  // const relatedUnits = convert().from('kg').possibilities();


                  setProductList(result);
                  // console.log("ddd" ,res.data.data?.stockData)
                  if (result?.length === 0) {
                        seterrornew("No product found in this category.")
                  }
            } catch (err) {
                  if (err) {
                        // setRowCount(0);
                        // setIsError(true);
                  }
            }
      };


      const handleInputChange = (index, key, value) => {
            setProductList(prevState =>
                  prevState.map((item, i) =>
                        i === index ? { ...item, [key]: value } : item
                  )
            );
      };

      const handleSubmit = async (e) => {
            e.preventDefault();
            clearError();

            if (category === "") {
                  showError("Please select a category");
                  return;
            }


            const activeProducts = ProductList.filter((item) => item.is_active);

            // Check if there are any active products
            if (activeProducts.length === 0) {
                  showError("Please select product");
                  return;
            }

            // Iterate over active products to validate them
            for (let i = 0; i < activeProducts.length; i++) {
                  const item = activeProducts[i];

                  if (!item.unit) {
                        showError(`Please select a unit for item "${item.name}"`);
                        return false;
                  }

                  if (!item.quantity || item.quantity.trim() === "") {
                        showError(`Please enter a quantity for item "${item.name}"`);
                        return false;
                  }
            }

            // Create the result array
            const result = ProductList.map((main) => ({
                  _id: main._id,
                  quantity: main.quantity,
                  is_active: main.is_active,
                  name: main.name,
                  unit: main.unit,
            }));

            // If valid, create the payload and submit the form
            const payload = {
                  category_name: category?.label,
                  items: result,
                  _id: category?.value,
            };

            // console.log("payload" ,payload)
            // return
            // Submit the form
            submitForm(
                  `/groupadmin/product/stock/${productid}`,
                  payload,
                  history,
                  showError,
                  clearError,
                  "Stock has been added successfully."
            );
      };


      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                          <GroupAdminHeader />
                                          <div className="d-flex flex-column flex-column-fluid" id="kt_content">
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <h1 className="anchor fw-bolder mb-5" id="vertical-horizontal-scroll">
                                                                  Menu Management
                                                            </h1>
                                                            <hr />
                                                            <div className="d-flex flex-stack mb-5">
                                                                  <div className="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                        <h3 className="anchor fw-bolder mb-5" id="custom-form-control">
                                                                              Product - Add Stock
                                                                        </h3>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div className="d-flex justify-content-end mx-2" data-kt-docs-table-toolbar="base">
                                                                              <button onClick={() => history.goBack()} className="btn btn-primary ps-7">
                                                                                    Go Back
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="rounded border p-5">
                                                                  <div className="tab-content" id="myTabContent">
                                                                        <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                                                                              <div className="card card-docs mb-2">
                                                                                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                                                          <form onSubmit={handleSubmit}>
                                                                                                <div className="rounded border p-10">
                                                                                                      <div className="row mb-8">
                                                                                                            <div className="col-md-9">
                                                                                                                  <h3>Add Stock</h3>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <hr />

                                                                                                      <div className="row mt-8">
                                                                                                            <div className="col-md-3">
                                                                                                                  <p>Stock Details</p>
                                                                                                            </div>
                                                                                                            <div className="col-md-4">
                                                                                                                  {/* <label className="form-label required">Category</label> */}
                                                                                                                  <Select
                                                                                                                        className="search-line"
                                                                                                                        placeholder="Select Category"
                                                                                                                        // value={item.category}
                                                                                                                        menuPosition="fixed"   // Fixes the position of the menu to the viewport
                                                                                                                        menuPlacement="auto"
                                                                                                                        options={inventoryCategoryList_?.inventoryCatagotyList?.filter((main) =>
                                                                                                                              main?.is_active)?.map((main1) => ({
                                                                                                                                    label: main1?.category_name,
                                                                                                                                    value: main1?._id,
                                                                                                                              }))}
                                                                                                                        styles={commonSelectStyles}
                                                                                                                        onChange={(opt) => {
                                                                                                                              handleCategoryChange(opt)
                                                                                                                        }
                                                                                                                        }
                                                                                                                  />
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      {ProductList?.length > 0 ?
                                                                                                            ProductList?.map((item, index) => (
                                                                                                                  <div className="row mt-8" key={index}>
                                                                                                                        <div className="col-md-3"></div>
                                                                                                                        <div className="col-md-2 d-flex align-items-center">
                                                                                                                              <input
                                                                                                                                    className="form-check-input me-4"
                                                                                                                                    type="checkbox"
                                                                                                                                    checked={item.is_active}
                                                                                                                                    isSearchable={false}
                                                                                                                                    onChange={(e) =>
                                                                                                                                          handleInputChange(index, "is_active", e.target.checked)
                                                                                                                                    }
                                                                                                                              />
                                                                                                                              <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="Enter Name"
                                                                                                                                    value={item.name}
                                                                                                                                    disabled

                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-2">
                                                                                                                              <Select
                                                                                                                                    className="search-line"
                                                                                                                                    placeholder="Select Unit"
                                                                                                                                    value={item.setunit.find(option => option.value === item.unitSelected)} // Ensure value matches one of the options
                                                                                                                                    options={item.setunit}
                                                                                                                                    menuPosition="fixed"   // Fixes the position of the menu to the viewport
                                                                                                                                    menuPlacement="auto"
                                                                                                                                    styles={commonSelectStyles}
                                                                                                                                    onChange={(opt) => {
                                                                                                                                          handleInputChange(index, "unit", opt?.value)
                                                                                                                                    }} // Update unitSelected
                                                                                                                              />
                                                                                                                        </div>
                                                                                                                        <div className="col-md-2">


                                                                                                                              <div className="d-flex align-items-center">
                                                                                                                                    <input
                                                                                                                                          type="number"
                                                                                                                                          className="form-control form-control"
                                                                                                                                          placeholder="Enter Quantity"
                                                                                                                                          value={item.quantity}

                                                                                                                                          onChange={(e) => {
                                                                                                                                                const value = e.target.value;
                                                                                                                                                // Prevent zero and allow only positive numbers
                                                                                                                                                if (value !== "0") {
                                                                                                                                                      handleInputChange(index, "quantity", value);
                                                                                                                                                }
                                                                                                                                          }}

                                                                                                                                    // onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                                                                                                                                    />
                                                                                                                                    <span style={{ marginLeft: "-2.9rem", color: "#667085", fontSize: '14px', fontWeight: 400 }} className="">
                                                                                                                                          {item.unit === "number" ? "no" : item.unit}
                                                                                                                                    </span>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                            )) :

                                                                                                            errornew &&
                                                                                                            <div className="col-md-12 mt-6">
                                                                                                                  <div className="card shadow-sm">
                                                                                                                        <div className="card-body" style={{ textAlign: "center" }}>
                                                                                                                              <div className="col-md-12">
                                                                                                                                    <a>
                                                                                                                                          <b style={{ color: "rgb(0, 0, 0)", fontSize: 20 }}>{errornew && errornew}</b>
                                                                                                                                          <span />
                                                                                                                                    </a>
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  </div>
                                                                                                            </div>

                                                                                                      }
                                                                                                      <br />
                                                                                                      <hr />
                                                                                                      <ErrorMessage error={error} />
                                                                                                      {ProductList?.length > 0 &&
                                                                                                            <SubmitformButton1 handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                                                                                                      }

                                                                                                </div>
                                                                                          </form>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                          <GroupAdminUperSide />
                                    </div>
                              </div>
                        </div>
                  </div>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      inventoryCategoryList_: state,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getInventoryCatList: () => dispatch(getInventoryCatList())
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStockProduct);
